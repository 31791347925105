import { Link } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import useTable from "../../../MainComponents/Hooks/useTable";
import { useState } from "react";
import "../../../SASS/Assignments.scss";
import { Loader } from "../../../MainComponents";
export default function StudentSkills() {
  const [submitRequest, setSubmitRequest] = useState({
    flag: "",
    dependancy: false,
    data: {},
  });

  const [getAssignment, errorAssigment, loadingAssignment] = useAxios(
    process.env.REACT_APP_STUDENT_SKILLS_GET_API,
    "GET",
    "GET",
    submitRequest.dependancy
  );
  const [sumbitRequest, errorRequest, loadingRequest] = useAxios(
    process.env.REACT_APP_STUDENT_SKILLS_REQUEST_API,
    "POST",
    submitRequest.flag,
    submitRequest.dependancy,
    submitRequest.data,
    true
  );
  const onSubmit = (data) => {
    const finalData = { id: data.skill };
    setSubmitRequest({
      flag: "student_skills",
      dependancy: !submitRequest.dependancy,
      data: finalData,
    });
  };
  if (loadingAssignment && submitRequest.dependancy === false)
    return <Loader />;
  return (
    <section className="px-10 py-10 md:px-0 flex flex-col min-h-screen w-full ">
      <table className="tableassignments">
        <tbody>
          {getAssignment?.data?.map((item) => (
            <tr className="py-5 text-blue-500" key={item.key}>
              <td>
                {item.results &&
                item.results[0] &&
                item.results[0].have_taken !== null
                  ? `${item.results[0].dgree} Out OF ${item.results[0].out_of} `
                  : null}
              </td>
              <td>
                {item.results &&
                item.results[0] &&
                item.results[0].have_taken !== null ? (
                  <Link to={`/skills/modelanswers/${item.results[0]?.id}`}>
                    Try {item.results[0].have_taken}
                  </Link>
                ) : null}
              </td>
              <td>{item.name}</td>
              <td>
                <Link
                  to={`exam/start/${item.skill}`}
                  className="bg-blue-500 px-4 py-3 rounded-3xl hover:bg-secondary text-white duration-150 "
                >
                  {item.n_result === 0 ? "Start" : "Retake"}
                </Link>
              </td>
              <td>
                {item.is_video === 1 && item.results?.[0]?.skill_id ? (
                  <Link
                    to={`/skills/videos/${item.results[0].skill_id}`}
                    className="bg-blue-500 px-4 py-3 rounded-3xl hover:bg-secondary hover:text-white text-white border duration-150"
                  >
                    Videos
                  </Link>
                ) : null}
                {item.n_YoutubeEmbed > 0 &&
                item.is_req === 1 &&
                item.is_video === 0 ? (
                  <button
                    disabled={
                      item.n_YoutubeEmbed > 0 &&
                      item.n_req === 1 &&
                      item.is_req === 1
                    }
                    onClick={() => onSubmit(item)}
                    className={`${
                      item.n_YoutubeEmbed > 0 &&
                      item.n_req === 1 &&
                      item.is_req === 1
                        ? "bg-blue-500/50"
                        : "bg-blue-500  hover:bg-secondary hover:text-white "
                    }  px-4 py-3 rounded-3xl text-white border  duration-150 `}
                  >
                    {item.n_YoutubeEmbed > 0 &&
                    item.n_req === 1 &&
                    item.is_req === 1
                      ? "is Pending"
                      : "Request"}
                  </button>
                ) : null}
              </td>
            
                <td>
                {item.is_pdf === 1 ? (
                  <Link
                    to={`/skill/Pdf/${item.key}`}
                    className="bg-blue-500 px-4 py-3 rounded-3xl hover:bg-secondary hover:text-white text-white border duration-150"
                  >
                    PDF
                  </Link>
                ) : null}
                </td>
             
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

import React, { useState, useRef, useEffect, useReducer } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";

import ChatFooter from "../../../../MainComponents/fabs/sections/chatFooter/ChatFooter";
import { AnimatePresence, motion } from "framer-motion";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { set } from "react-hook-form";
import useChat from "../../../../MainComponents/Hooks/useChat";
export default function AsksShowDetails() {
  const [refetch, setRefetch] = useState(false);
  const stream = useRef(null);
  const AuthState = useAuthState();
  const recorder = useRef(null);
  const items = useRef([]);
  const counter = useRef(null);
  const seconds = useRef(null);
  const minutes = useRef(null);
  const textInput = useRef(null);
  const [messages, setMessages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const { studentID } = useParams();
  const navigate = useNavigate();
  const [submitExplanation, setSubmitExplanation] = useState({
    flag: "",
    depdency: false,
    data: {},
  });
  const [questionData, questionError, asksLoading] = useAxios(
    `https://api.bayoumymath.com/api/admin/askquestions/${studentID}`,
    "GET",
    "GET",
    ""
  );
  const [messageData] = useAxios(
    `https://api.bayoumymath.com/api/admin/askquestions/${studentID}`,
    "GET",
    "GET",
    refetch
  );

  const chat = useChat(refetch, setRefetch, studentID, messageData?.data, true);
  if (asksLoading) return <Loader />;
  if (questionError)
    return (
      <div className="min-h-screen w-full flex justify-center items-center text-3xl texb-black">
        {questionError?.response?.data?.message}
      </div>
    );
  return (
    <section className="  px-10 py-10 relative flex md:flex-col-reverse gap-5">
      <div className=" mx-auto p-8 border max-w-[66%] w-full border-secondary rounded-3xl shadow-lg ">
        <div className=" mb-[20px] flex flex-col gap-5 w-full pr-[20px]">
          <div className="w-full flex justify-between">
            <h2 className="text-2xl font-bold mb-4 text-black">
              Question:{" "}
              <div
                dangerouslySetInnerHTML={{
                  __html: questionData?.data?.question?.question,
                }}
              />
            </h2>
          </div>

          {/* Render answers */}
          <div>
            <h3 className="text-2xl font-semibold mb-2">Answers:</h3>
            <ul className="sm:grid-cols-1 grid-cols-2 grid gap-5 ">
              {["answer1", "answer2", "answer3", "answer4"].map(
                (key, index) => (
                  <li key={key} className="mb-2 flex gap-3 text-black text-2xl">
                    <span>{String.fromCharCode(97 + index)})</span>
                    {questionData?.data?.question?.[key] ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: questionData.data.question[key],
                        }}
                      />
                    ) : (
                      `${key}: Not provided`
                    )}
                  </li>
                )
              )}
            </ul>
          </div>

          <p className="text-xl text-gray-700 mb-4">
            Explanation:{" "}
            {questionData?.data?.question?.explanation ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: questionData.data.question.explanation,
                }}
              />
            ) : (
              "No explanation provided"
            )}
          </p>

          {questionData?.data?.question?.video && (
            <div className="mb-4">
              <div
                dangerouslySetInnerHTML={{
                  __html: questionData.data.question.video,
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className=" md:relative md:right-0 fixed bottom-5 right-5  max-w-[550px] w-full md:z-0 z-[2000]">
        {chat}
      </div>
    </section>
  );
}

import React, { useState } from 'react';
import { Collapse } from 'antd';
import HelmetTags from "../../../MainComponents/HelmetTags";
import { Loader } from "../../../MainComponents";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { Link, useParams } from "react-router-dom";

const StudentShowQuestionBank = () => {
  const { ID } = useParams();

  const [questionsBankData, questionsBankErrors, questionsBankLoading] =
    useAxios(
      `${process.env.REACT_APP_STUDENT_HISTORY_VIEW_GET_API}${ID}`,
      "GET",
      "GET"
    );

  const [activeKey, setActiveKey] = useState([]);

  const handleChange = (key) => {
    setActiveKey(key);
  };

  if (questionsBankLoading) {
    return <Loader />;
  }
  if (questionsBankErrors) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>There are no questions currently</p>
      </div>
    );
  }

  const items = questionsBankData?.data?.map((question, index) => ({
    key: `${question.id}`,
    label: (
      <div className="flex items-center justify-between text-xl font-bold text-blue-600">
        <span>{`Question (${index + 1}): ${question?.skills[0]?.name}`}</span>
        {question?.n_try > 0 && (
          <span className="text-red-500">
            {`${question?.n_try} times wrong in the assignment`}
          </span>
        )}
      </div>
    ),
    children: (
      <div className="bg-white px-5 py-5 shadow-3xl flex flex-col gap-5">
        <div
          dangerouslySetInnerHTML={{
            __html: question.q,
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: question.correct_answer,
          }}
          className="px-7 py-5 w-full text-black bg-green-300/50"
        />
        <div className="px-7 py-5 bg-blue-500/90 text-black flex flex-col gap-5 w-full ">
          <p>Explanation</p>
          <div
          className='text-wrap whitespace-pre-wrap w-full'
            dangerouslySetInnerHTML={{
              __html: question.explain,
            }}
          />
        </div>

        <Link
          to={`/exam/start/${question.id}`}
          className="px-4 py-3 rounded-3xl text-white bg-cyan-500 hover:bg-secondary hover:text-white w-fit"
        >
          Word problem as many as and times
        </Link>
      </div>
    ),
  }));

  return (
    <section className="px-10 mb-32 h-full dark:bg-dark dark:text-light py-10">
      <HelmetTags title="Question Bank | Mr Ahmed Bayoumy" />
      <div className="flex flex-col gap-5">
        {/* Use onChange to update active panels */}
        <Collapse
        style={{ backgroundColor: '#f0f2f5' }}
          accordion
          activeKey={activeKey}
          onChange={handleChange}
          items={items}
        />
      </div>
    </section>
  );
};

export default StudentShowQuestionBank;

import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
export default function AdminTrailExam() {
  const table = useTable("trailexam");

  return (
    <>
      <HelmetTags title="Trail Exams | Mr.Ahmed Bayoumy"></HelmetTags>
      <section
        className={`h-auto w-full  dark:bg-dark dark:text-light px-10 py-10`}
      >
        <Link
          to={"/admin/trialexam/add-trailExam"}
          className="w-fit px-4 py-3  duration-200 flex gap-5 items-center bg-secondary text-white hover:text-secondary hover:bg-white border border-secondary rounded-3xl"
        >
          <FontAwesomeIcon icon={faPlus} /> Add Exam
        </Link>

        <div className="h-fit  w-full py-3">{table}</div>
      </section>
    </>
  );
}

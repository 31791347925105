import { useState } from "react";
import QuestionModalAnswer from "./QuestionModalAnswer";
import { useNavigate, useParams } from "react-router-dom";

import { Loader } from "../../../../MainComponents";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";

const AdminModalAnswer = ({ assignments, skills, checkpoints, view }) => {
  const [collapse, setCollapse] = useState(false);
  const [activeQuestionId, setActiveQuestionId] = useState(0);
  const { StudentID, ModalAnswerID } = useParams();
  const navigate = useNavigate();
  const hidden = true;

  const api = assignments
    ? "https://api.bayoumymath.com/api/admin/followup/assignment/modelanswer/"
    : skills
      ? "https://api.bayoumymath.com/api/admin/followup/skill/modelanswer/"
      : checkpoints
        ? "https://api.bayoumymath.com/api/admin/followup/checkpoint/modelanswer/"
        : "https://api.bayoumymath.com/api/admin/followup/exam/modelanswer/";
  const [modalAnswerData, modalAnswerErrors, modalAnswerLoading] = useAxios(
    `${api}${ModalAnswerID}/${StudentID}`,
    "GET",
    ModalAnswerID,
    StudentID
  );

  const handleQuestionClick = (id) => {
    setCollapse(true);
    setActiveQuestionId(id);
  };

  if (modalAnswerLoading) return <Loader />;
  if (modalAnswerErrors)
    return (
      <div className="error-container flex justify-center items-center flex-col gap-5 min-h-screen">
        <p className="text-4xl font-bold text-center">
          Failed to load answers. Please try again later.
        </p>
        <button
          className="bg-secondary hover:scale-105 text-white rounded-3xl px-3 py-3"
          onClick={() => navigate(-1)}
        >
          Go Back
        </button>
      </div>
    );

  const answers = modalAnswerData?.data?.answers || [];
  console.log(answers);
  return (
    <div className="left-response-container flex items-start md:w-full min-h-screen">
      <HelmetTags title="Modal Answer" />
      <QuestionModalAnswer
        collapse={collapse}
        onCloseCollapse={() => setCollapse(false)}
        activeQuestionId={activeQuestionId}
        question={answers[activeQuestionId]}
        view={view}
        ID={ModalAnswerID}
        hidden={hidden}
      />
      <div className="all-questions flex h-full flex-col items-center overflow-y-auto  p-[40px] transition-all  duration-300 ease-in-out w-full md:order-2 md:w-full">
        <h1 className="text-2xl font-bold text-black dark:text-white ">
          Modal Answer
        </h1>
        <p className="my-[20px] text-center text-secondary dark:text-white">
          Click To The Number To Show The Modal
        </p>

        <div className="flex gap-5 flex-wrap">
          {answers.map((question, index) => (
            <div
              key={index}
              className={`w-14 h-14 text-white flex items-center justify-center cursor-pointer ${
                question.is_correct
                  ? "bg-green-500"
                  : view
                    ? "bg-lime-500"
                    : "bg-red-500"
              }`}
              onClick={() => handleQuestionClick(index)}
            >
              {index + 1}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminModalAnswer;

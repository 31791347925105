import {
  faCircleStop,
  faMicrophone,
  faPaperPlane,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "../../styles/studentChat.scss";
const ChatFooter = (props) => {
  const {
    state,
    stopRecording,
    cancelRecording,
    handleAddImages,
    handleTyping,
    textInput,
    startRecording,
    sendTextOrImages,
    handleKeyDown,
    uploadedImages,
    setUploadedImages,
  } = props;

  const onRemoveUploadedImage = (name) => {
    setUploadedImages((uploaded) =>
      uploaded.filter((img) => img.name !== name)
    );
  };

  return (
    <div className="studentChatFooter chat__options bg-secondary rounded-b-xl   w-full flex justify-between gap-2 items-center relative overflow-hidden">
      <div
        className={`recording__options bg-white absolute inset-0 w-full h-full bg-bg -100 flex justify-between items-center transition-all duration-300 ease-in-out z-30 ${
          state?.isRecording ? "top-0" : "top-full"
        }`}
      >
        <div className="record__stop--btn  w-12 flex h-full justify-center items-center  ">
          <FontAwesomeIcon
            fade
            onClick={stopRecording}
            className={`text-red-500
               text-xl cursor-pointer ${
                 state?.isRecording ? "block" : "hidden"
               }`}
            icon={faCircleStop}
          />
        </div>
        <div className="record__counter">{state.counter}</div>
        <div className="record__cancel--btn  w-12 flex h-full justify-center items-center cursor-pointer ">
          <FontAwesomeIcon
            onClick={cancelRecording}
            className={`text-delete text-xl cursor-pointer ${
              state?.isRecording ? "block" : "hidden"
            }`}
            icon={faTrash}
          />
        </div>
      </div>
      <label
        htmlFor="upload__images"
        className="w-12 flex h-full justify-center items-center cursor-pointer "
      >
        <FontAwesomeIcon className="text-bg text-xl text-white" icon={faUpload} />
        <input
          type="file"
          multiple
          // capture
          accept="image/jpeg,image/png,image/gif,video/mp4,video/quicktime"
          name="upload__images"
          id="upload__images"
          // max-size="90"
          hidden
          onChange={handleAddImages}
        />
      </label>
      <input
        autoFocus
        onKeyUp={handleTyping}
        onKeyDown={handleKeyDown}
        placeholder="Send a message ..."
        type="text"
        ref={textInput}
        className="bg-bg w-full rounded-md h-9 focus:outline-none  my-auto px-3 "
      />
      <div className="record__send--btns--wrapper  w-12 flex h-full justify-center items-center  ">
        <FontAwesomeIcon
          onClick={startRecording}
          className={`text-bg text-xl cursor-pointer text-white ${
            state?.isRecording ? "hidden" : "block"
          } ${(state.isTyping || uploadedImages.length > 0) && "hidden"}`}
          icon={faMicrophone}
        />

        <FontAwesomeIcon
          onClick={sendTextOrImages}
          className={`text-bg text-xl cursor-pointer text-white ${
            state.isTyping || uploadedImages.length > 0 ? "block" : "hidden"
          }`}
          icon={faPaperPlane}
        />
      </div>

      {uploadedImages.length > 0 && (
        <div className="onUploadImages  text-white p-2">
          <ul className="flex items-align  gap-3 flex-wrap justify-center">
            {uploadedImages.map((img, i) => {
              let imgSrc = URL.createObjectURL(img);
              return (
                <li key={i}>
                  <img src={imgSrc} alt="" />
                  <div
                    className="icon"
                    onClick={() => onRemoveUploadedImage(img.name)}
                  >
                    <AiOutlineCloseCircle />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ChatFooter;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const QuizTimer = ({ state, duration, onFinishQuiz }) => {
  const { ID } = useParams();
  const initialDuration = duration * 60;
  const [timerDuration, setTimerDuration] = useState(initialDuration);

  const [formattedTime, setFormattedTime] = useState("");
  const navigate = useNavigate();

  // Countdown timer
  useEffect(() => {
    const timer = setInterval(() => {
      setTimerDuration((prevDuration) => {
        const newDuration = prevDuration - 1;
        if (newDuration <= 0) {
          clearInterval(timer);
          onFinishQuiz();
          return ;
        }
        return newDuration;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);





  // Format countdown time
  useEffect(() => {
    if (timerDuration <= 0) {
      setFormattedTime("00 min : 00 sec");
    } else {
      const minutes = Math.max(0, Math.floor(timerDuration / 60));
      const seconds = Math.max(0, timerDuration % 60);
      const formatted = `${minutes.toString().padStart(2, "0")} min : ${seconds
        .toString()
        .padStart(2, "0")} sec`;
      setFormattedTime(formatted);
    }
  }, [timerDuration]);



  return (
    <div className="flex w-full flex-col items-center justify-center md:w-fit" onClick={() => onFinishQuiz(timerDuration)}>
      <div className="text-[#374151] font-bold">{formattedTime}</div>

    </div>
  );
};

export default QuizTimer;

import { Link, useLocation, useParams } from "react-router-dom";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useState } from "react";
import Loader from "../../../../MainComponents/Loader";
import VideoCard from "../../../../MainComponents/VideoCard";
import HelmetTags from "../../../../MainComponents/HelmetTags";

function Videos({ type }) {
  const AuthState = useAuthState();
  const { ID } = useParams();
  const [reFetch, setReFetch] = useState(false);
  const { pathname } = useLocation();

  const [showVideo, setShowVideo] = useState({
    show: false,
    src: "",
    iframe: "",
  });

  let api =
    type === "studentAssignment"
      ? `${process.env.REACT_APP_SKILLS_VIDEO_GET_API}/${ID}`
      : type === "studentSkills"
        ? `https://api.bayoumymath.com/api/skills/embedvideo/${ID}`
        : !type
          ? `${process.env.REACT_APP_ADMIN_GET_VIEDO_ASSIGMNETS_API}/${ID}`
          : `${process.env.REACT_APP_SKILLS_VIDEOS_GET_API}/${ID}`;
  //!---- get all videos -------
  const [videosSuccess, videosErrors, videosLoading] = useAxios(
    api,
    "GET",
    ID,
    reFetch || ID
  );
  const handleCloseVideo = () => {
    setShowVideo({
      show: false,
      src: "",
    });
  };

  console.log(showVideo, "ajjdj");

  return (
    <section
      className={`   min-h-screen  w-full   px-20 py-20  dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Videos | Mr.Ahmed Bayoumy"></HelmetTags>

      <div
        onClick={handleCloseVideo}
        className={`absolute inset-0 z-50 flex h-screen w-full origin-bottom items-center justify-center bg-blue-200/40 transition-all duration-300 ease-in-out ${
          showVideo.show
            ? "opacity-100 pointer-events-auto scale-100"
            : "opacity-0 pointer-events-none scale-0"
        } `}
      >
        <div className="iframe-wrapper aspect-video w-3/4 overflow-hidden rounded-3xl border-0 border-secondary">
          {showVideo.iframe ? (
            <div
              className="video-iframe h-full flex items-center justify-center"
              dangerouslySetInnerHTML={{ __html: showVideo.iframe }}
            />
          ) : (
            <iframe
              src={showVideo.src}
              width="100%"
              height="100%"
              title="YouTube video player"
              frameBorder="0"
              //style={{ aspectRatio: "16 / 9" }}
              allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}
        </div>
      </div>

      <div className="mb-20 flex flex-col items-start gap-20">
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_addvideos")
          )) && (
          <Link
            to={`/admin/assignments/${ID}/add-video`}
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add Video
          </Link>
        )}

        {videosLoading ? (
          <Loader />
        ) : (
          <div className="videos video-grid-auto-fit w-full ">
            {videosSuccess?.data?.length === 0 ? (
              <p>لا يوجد فيديوهات لهذة المحاضرة</p>
            ) : (
              videosSuccess?.data?.map((video) => (
                <VideoCard
                  reFetch={reFetch}
                  setReFetch={setReFetch}
                  setShowVideo={setShowVideo}
                  admin={pathname.includes("/admin")}
                  type={type}
                  video={video}
                  key={video.key}
                />
              ))
            )}
          </div>
        )}
      </div>
    </section>
  );
}

export default Videos;

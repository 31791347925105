import { Loader } from "../../../../MainComponents";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Table, Input, Form } from "antd";

export default function AssignmentsBooks() {
  const { ID } = useParams();
  const [coursesChecked, setCoursesChecked] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const [dataBooks, errorBooks, loadingBooks] = useAxios(
    `${process.env.REACT_APP_BOOKS_GET_API}`,
    "GET",
    "GET",
    refetch
  );

  const [dataBooksCheck, errorBooksCheck, loadingBooksCheck] = useAxios(
    `${process.env.REACT_APP_ADMIN_GET_ASSIGMNETS_BOOKS_API}${ID}`,
    "GET",
    "GET",
    ""
  );

  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    // Set initial selected keys based on checked books
    if (dataBooksCheck?.data?.checked_books) {
      setSelectedKeys(
        dataBooksCheck.data.checked_books.map((book) => book.key)
      );
    }
  }, [dataBooksCheck]);
  console.log(selectedKeys);
  const onSelectChange = (newSelectedKeys) => {
    setSelectedKeys(newSelectedKeys);
    const selectedRows = dataBooks?.data.filter((book) =>
      newSelectedKeys.includes(book.key)
    );
    setCoursesChecked(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const [submitAddBooks, setSubmitAddBooks] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [successAddBooks, errorAddBooks, loadingAddBooks] = useAxios(
    process.env.REACT_APP_ADMIN_SAVE_BOOKS_ASSIGMNETS_API,
    "POST",
    submitAddBooks.flag,
    submitAddBooks.dependency,
    submitAddBooks.data,
    true
  );

  const onSubmitAddBooksWithCourses = (data) => {
    const formData = {
      id: ID, // Single book ID from URL params
      books: coursesChecked, // Array of selected course keys
    };
    console.log(formData);
    setSubmitAddBooks({
      flag: "Add Book with courses",
      dependency: !submitAddBooks.dependency,
      data: formData,
    });
  };

  const CoursesHomeworkColumns = [
    {
      title: "Name",
      dataIndex: "name",
      editTable: true,
      width: 200,
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
    },
  ];

  function editableCell({
    editing,
    dataIndex,
    title,
    record,
    children,
    ...restProps
  }) {
    const input = <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[{ required: false }]}
          >
            {input}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  useEffect(() => {
    if (successAddBooks) {
      setRefetch(!refetch);
    }
  }, [successAddBooks, refetch]);

  if (loadingBooksCheck || loadingAddBooks || loadingBooks) return <Loader />;

  return (
    <main className="py-10 px-10 flex flex-col items-center justify-center w-full">
      <div className="w-full flex flex-col items-start gap-6">
        <form
          onSubmit={handleSubmit(onSubmitAddBooksWithCourses)}
          className="w-full"
        >
          <div className="py-10 px-2 w-full">
            <h3 className="text-5xl pb-10">Books Checked</h3>
            <div>
              <Table
                dataSource={dataBooks?.data}
                columns={CoursesHomeworkColumns}
                bordered
                pagination={{ position: ["bottomCenter"] }}
                components={{ body: { cell: editableCell } }}
                rowSelection={rowSelection}
              />
            </div>
          </div>
          <div className="py-10 flex items-center w-full justify-center">
            <button className="bg-blue-700 hover:bg-blue-700/50 duration-150 text-white px-4 py-3">
              Save
            </button>
          </div>
        </form>
      </div>
    </main>
  );
}

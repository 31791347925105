import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Table, Checkbox } from "antd";
import useAxios from "../../../../MainComponents/Hooks/useAxios";

export default function AdminVideoSpecific() {
  const { assignmentID, skillID } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [getCourses] = useAxios(
    process.env.REACT_APP_ADMIN_GET_COURSES_API,
    "GET",
    "GET",
    ""
  );
  let api = "";
  const [successData, errorData, loading] = useAxios(
    api,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );

  useEffect(() => {
    if (getCourses && getCourses.data) {
      setCourses(getCourses.data);
    }
  }, [getCourses]);

  const onSubmit = (data) => {
    const finalData = {
      id: assignmentID || skillID,
      courses: selectedCourses,
      ...data,
      video: data.video ? 1 : 0,
    };
    setSubmitAdd({
      flag: "Add Assignment",
      dependency: !submitAdd.dependency,
      data: finalData,
    });
  };
  const handleCourseChange = (courseId, checked) => {
    if (checked) {
      setSelectedCourses([...selectedCourses, courseId]);
    } else {
      setSelectedCourses(selectedCourses.filter((id) => id !== courseId));
    }
  };
  return (
    <div className="w-full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form shadow-3xl h-full max-w-[600px] !gap-10"
      >
        <h1 className="text-4xl font-bold text-black mx-auto w-full text-center">
          Video
        </h1>
        <div className="py-10 flex justify-center items-center w-full flex-wrap gap-5">
          <div className=" visibility flex w-full flex-col items-center justify-center gap-2 md:w-full">
            <div className="flex w-full items-center justify-center gap-10">
              <label className="visibility-switch">
                <input
                  className=""
                  id="visibility"
                  name="video"
                  {...register("video", {
                    required: false,
                  })}
                  type="checkbox"
                />
                <span className="visibility-slider">
                  <svg
                    className="slider-icon"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path fill="none" d="m4 16.5 8 8 16-16"></path>
                  </svg>
                </span>
              </label>
              <div>
                <label
                  className="w-full cursor-pointer truncate"
                  htmlFor="Enable"
                >
                  Show Video
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full py-10">
          <div className="">
            <Table
              dataSource={courses}
              columns={[
                {
                  title: "",
                  key: "",
                  width: 40,
                  render: (text, record) => (
                    <Checkbox
                      value={record.key}
                      onChange={(e) =>
                        handleCourseChange(record.key, e.target.checked)
                      }
                      checked={selectedCourses.includes(record.key)}
                    ></Checkbox>
                  ),
                },
                {
                  title: "Course Name",
                  dataIndex: "name",
                },
              ]}
              pagination={false}
              rowKey="key"
              scroll={{ y: 240 }}
            />
          </div>
          {errors.courses && (
            <span className="text-red-500">{errors.courses.message}</span>
          )}
        </div>

        <button type="submit" className="submit">
          Submit
        </button>
      </form>
    </div>
  );
}

import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useEffect, useRef, useState } from "react";
import ModalImage from "react-modal-image";
import { Link } from "react-router-dom";
import AskButton from "../../../../../MainComponents/AskButton";

const Question = ({
  state,
  dispatch,
  homework,
  exam,
  n_try,
  assignment,
  weekly,
  skill,
}) => {
  const { ID } = useParams();
  const essayAnswerRef = useRef();
  const fileInputRef = useRef();
  const [preview, setPreview] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const handleToggleExplanation = () => {
    setShowExplanation((prev) => !prev); // Toggle explanation visibility
    if (!showExplanation) setShowVideo(false); // Hide video if showing explanation
  };

  const handleToggleVideo = () => {
    setShowVideo((prev) => !prev); // Toggle video visibility
    if (!showVideo) setShowExplanation(false); // Hide explanation if showing video
  };
  const checkApi = homework
    ? "https://api.bayoumymath.com/api/homeworkskills/skill/updateanswer"
    : assignment
      ? process.env.REACT_APP_UPDATE_ANSWER_API
      : weekly
        ? "https://api.bayoumymath.com/api/checkweeklypoint/exam/updateanswer"
        : skill
          ? "https://api.bayoumymath.com/api/skills/updateanswer"
          : process.env.REACT_APP_STUDENT_QUIZZ_UPDATE_API;

  const handleSelectAnswer = (answer, questionId, n) => {
    dispatch({
      type: "setAnsweredQuestions",
      payload: {
        id: questionId,
        assignment_id: assignment ? ID : null,
        exam_id: assignment ? null : ID,
        order: answer || "unanswered", // Ensure "E" for null values
        is_essay: 0,
        n: n,
        n_try: n_try,
      },
    });

    async function fetchData() {
      try {
        await axios.post(checkApi, {
          id: questionId,
          order: answer || "unanswered", // Ensure "E" for null values
          assignment_id: assignment ? ID : null,
          exam_id: assignment ? null : ID,
          skill_id: skill || homework ? ID : null,
          is_essay: 0,
          n: n,
          n_try,
        });
      } catch (error) {
        console.error("Error:", error);
        toast.error(error.response.data.message);
      }
    }

    fetchData();

    dispatch({
      type: "setIsSelectedAnswer",
      payload: { questionId, answer, n },
    });
  };
  const handleOnBlurEssayAnswer = (questionId, n) => {
    const essayAnswer = essayAnswerRef.current.value.trim();
    dispatch({
      type: "setAnsweredQuestions",
      payload: {
        id: questionId,
        order: essayAnswer || "unanswered", // Ensure "E" for null values
        is_essay: 1,
        n,
        n_try,
      },
    });

    async function fetchData() {
      try {
        await axios.post(checkApi, {
          order: essayAnswer || "unanswered", // Ensure "E" for null values
          id: questionId,
          is_essay: 1,
          n: n,
          n_try,
        });
      } catch (error) {
        console.error("Error:", error);
        toast.error(error.response.data.message);
      }
    }

    fetchData();
  };
  /*   const handleFileChange = async () => {
    const file = fileInputRef.current.files[0];
    if (!file) return;

    // Set image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);

    const questionId = state.questions[state.currentQuestionIndex].id;
    const order = essayAnswerRef.current.value;

    const formData = new FormData();
    formData.append("id", questionId);
    formData.append("file", file);
    formData.append("lecture_id", ID);
    formData.append("is_essay", 1);
    formData.append("order", order);

    try {
      await axios.post(checkApi, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: "setAnsweredQuestions",
        payload: {
          id: questionId,
          order,
          is_essay: 1, // Assuming 1 indicates an essay
          file,
          n_try: state.n_try,
        },
      });
      toast.success("File uploaded successfully");
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.response?.data?.message ||
          "An error occurred while uploading the file"
      );
    }
  }; */

  useEffect(() => {
    const currentAnswerd = state.answeredQuestions.filter(
      (question) =>
        question?.id === state?.questions[state.currentQuestionIndex]?.id
    )[0];
    // if not essay question set textarea by the essay question value
    if (!state?.questions[state.currentQuestionIndex]?.answers) {
      if (
        state?.questions[state.currentQuestionIndex]?.answer ||
        currentAnswerd?.order
      ) {
        essayAnswerRef.current.value = currentAnswerd?.order
          ? currentAnswerd?.order
          : state?.questions[state.currentQuestionIndex]?.answer;
      } else {
        essayAnswerRef.current.value = "";
      }
    }
  }, [state.answeredQuestions, state.currentQuestionIndex, state?.questions]);

  const currentQuestion = state.questions[state.currentQuestionIndex];

  return (
    <div className="container max-w-[600px]">
      <div className="current-question flex-row">
        <p className="text-[#374151]">{`${state.currentQuestionIndex + 1}.`}</p>
        <div
          className="text-[#374151]"
          dangerouslySetInnerHTML={{
            __html: currentQuestion?.question,
          }}
        />
      </div>

      <div className="current-question-answers">
        {currentQuestion?.answers ? (
          currentQuestion?.answers.map((answer, i) => (
            <div
              key={i}
              className={`${
                currentQuestion.selected_answer === answer.order && "selected"
              }`}
              onClick={() =>
                handleSelectAnswer(
                  answer.order,
                  currentQuestion.id,
                  currentQuestion.n
                )
              }
            >
              <span>{answer.order} </span>
              <p dangerouslySetInnerHTML={{ __html: answer.answer }} />
            </div>
          ))
        ) : (
          // Essay input for is_essay questions
          <textarea
            ref={essayAnswerRef}
            onBlur={() =>
              handleOnBlurEssayAnswer(currentQuestion.id, currentQuestion.n)
            }
            placeholder="Write your answer"
            className="textareaQuiz !border-[#374151] !border-2 text-[#374151]  rounded-xl p-4 !placeholder:text-[#374151] "
          ></textarea>
        )}
      </div>

      {(preview || currentQuestion?.draft) && (
        <div className="flex items-center justify-center">
          <ModalImage
            small={preview || currentQuestion?.draft}
            large={preview || currentQuestion?.draft}
            className="h-[300px] object-cover mt-10"
          />
        </div>
      )}

      <div className="flex gap-5 mt-5">
        {(homework || skill) && currentQuestion?.explanation && (
          <button
            className="submit-btn rounded-md border-[#374151] bg-[#374151] px-[16px] py-[8px] font-bold text-white duration-200 hover:scale-110 active:scale-90"
            onClick={handleToggleExplanation}
          >
            {showExplanation ? "Hide" : "Show"} Explanation
          </button>
        )}
        {(skill || homework) &&
          currentQuestion?.video !== null &&
          currentQuestion?.video && (
            <button
              onClick={handleToggleVideo}
              className="submit-btn rounded-md border-[#374151] bg-[#374151] px-[16px] py-[8px] font-bold text-white duration-200 hover:scale-110 active:scale-90"
            >
              {showVideo ? "Hide" : "Show"} Video
            </button>
          )}
        {(skill || homework) && (
          <AskButton
            ID={currentQuestion?.id}
            essay={currentQuestion?.answers?.length > 0 ? 0 : 1}
          />
        )}
      </div>

      {showExplanation && currentQuestion?.explanation && (
        <div
          className="text-[#374151]"
          dangerouslySetInnerHTML={{ __html: currentQuestion.explanation }}
        />
      )}
      {showVideo && currentQuestion?.video && (
        <div
          className="text-[#374151]"
          dangerouslySetInnerHTML={{ __html: currentQuestion.video }}
        />
      )}
    </div>
  );
};

export default Question;

import HelmetTags from "../../../MainComponents/HelmetTags.jsx";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { useAuthDispatch } from "../../../MainComponents/GlobalContext.jsx";
import axios from "axios";
import { toast } from "react-hot-toast";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import logoLight from "../../../assets/logo-light.png";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function SignIn({ admin }) {
  const AuthDispatch = useAuthDispatch();
  const navigate = useNavigate();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [hide, setHide] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [forgetPassowrdLoading, setForgetPassowrdLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const handleSignIn = (data) => {
    setSubmitLoading(true);
    async function postData() {
      try {
        let res = await axios.post(
          admin
            ? "https://api.bayoumymath.com/api/admin/login"
            : process.env.REACT_APP_LOGIN_API,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        let userData = await res.data;
        setSubmitLoading(false);
        localStorage.setItem("userData", JSON.stringify(userData));
        AuthDispatch({ type: "setUserData", payload: userData });

        // set axios
        axios.defaults.headers.common["Authorization"] = JSON.parse(
          localStorage.userData
        ).student_token;

        toast.success(userData?.message);
        reset();
        navigate(`${admin ? "/admin/home" : "/home"}`, { replace: true });
      } catch (error) {
        setSubmitLoading(false);
        setServerErrors(error?.response?.data?.error);
        toast.error(
          error?.response?.data?.error || "An error occurred, please try again."
        );
      }
    }
    postData();
  };

  const handleForgotPassword = (data) => {
    setForgetPassowrdLoading(true);
    //!  if success
    setForgotPassword(false);
    setForgetPassowrdLoading(false);
    //!  if error
    setForgetPassowrdLoading(false);
    setServerErrors();

    reset();
  };

  return (
    <section className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm: w-full max-w-[400px] sm: mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-secondary shadow-lg transform sm:-skew-y-6  skew-y-0 -rotate-6 rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg  rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <HelmetTags
              title="Log in | Mr.Ahmed Bayoumy"
              description="Log in | Mr.Ahmed Bayoumy"
              index
            >
              <link rel="canonical" href="https://api.bayoumymath.com/login" />
            </HelmetTags>

            <div className="flex flex-col gap-5 items-center justify-center mb-8">
              <Link className="h-20 w-auto text-4xl" to="/">
                <img className="h-full" src={logoLight} alt="logo" />
              </Link>
              <h1 className="mt-5 text-3xl font-semibold text-center">
                Log in | Mr.Ahmed Bayoumy
              </h1>
              <p className="opacity-80 text-center text-gray-600">
                Welcome back! Please enter your information
              </p>
            </div>

            <form onSubmit={handleSubmit(handleSignIn)} method="post">
              <div className="space-y-9">
                {/* Username field */}
                <div className="relative">
                  <input
                    type="text"
                    id="username"
                    placeholder="Username"
                    className="peer h-10 w-full border-b-2 border-gray-300 text-lg text-gray-900 placeholder-transparent focus:outline-none focus:border-secondary"
                    {...register("username", {
                      required: true,
                      pattern: /^[A-Za-z0-9-_]*$/,
                      maxLength: 20,
                      minLength: 3,
                    })}
                  />
                  <label
                    htmlFor="username"
                    className="absolute left-0 -top-5 text-gray-600 text-xl peer-placeholder-shown:text-xl peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-lg"
                  >
                    User Name
                  </label>
                  {errors.username && (
                    <p className="text-lg text-red-600 mt-1">
                      {errors.username.message || "Invalid username"}
                    </p>
                  )}
                </div>

                {/* Password field */}
                <div className="relative">
                  <input
                    type={hide ? "text" : "password"}
                    id="password"
                    placeholder="Password"
                    className="peer h-10 w-full border-b-2 border-gray-300 text-lg text-gray-900 placeholder-transparent focus:outline-none focus:border-secondary"
                    {...register("password", {
                      required: true,
                      maxLength: 25,
                      minLength: 6,
                    })}
                  />
                  <label
                    htmlFor="password"
                    className="absolute left-0 -top-5 text-gray-600 text-xl peer-placeholder-shown:text-xl peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-lg"
                  >
                    Password
                  </label>
                  <div
                    onClick={() => setHide(!hide)}
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  >
                    <FontAwesomeIcon
                      icon={hide ? faEye : faEyeSlash}
                      className="text-gray-500"
                    />
                  </div>
                  {errors.password && (
                    <p className="text-xs text-red-600 mt-1">
                      {errors.password.message || "Invalid password"}
                    </p>
                  )}
                  {serverErrors && (
                    <p className="text-xs text-red-600 mt-1">{serverErrors}</p>
                  )}
                </div>

                {/* Forgot password link */}
                <div className="flex justify-end text-sm">
                  <Link
                    to="/forget-password"
                    className="text-secondary underline hover:text-secondary"
                  >
                    Forgot your password?
                  </Link>
                </div>

                {/* Submit button */}
                <button
                  type="submit"
                  className="w-full bg-secondary/95 text-white py-2 rounded-md hover:bg-secondary transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
                  disabled={!isValid || submitLoading}
                >
                  {submitLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    "Sign in"
                  )}
                </button>
              </div>
            </form>

            {/* Register link */}
            {!admin && (
              <div className="mt-10 text-center text-lg">
                Don't have an account?{" "}
                <Link className="font-semibold text-secondary" to="/register">
                  Create account now
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignIn;

import React from "react";
import { useParams } from "react-router-dom";
import useTable from "../../../../MainComponents/Hooks/useTable";

export default function AdminAssignmentsViewStudents() {
  const { ID } = useParams();
  const table = useTable("assignmentviewstudent", "", "", "", "", ID);
  return (
    <section className="py-10 px-10">
      <div className="py-10">{table}</div>
    </section>
  );
}

import { useState } from "react";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

export default function StudentAsks() {
  const [filter, setFilter] = useState("notYetAnswered"); // State to store the selected filter
  const [askData, askError, askLoading] = useAxios(
    process.env.REACT_APP_ASK_QUESTION_GET_API,
    "GET",
    "GET",
    ""
  );

  // Filter the data based on the selected filter
  const filteredData = askData?.data?.filter((question) => {
    if (filter === "notYetAnswered") return question.is_replied === 0;
    if (filter === "answering") return question.is_replied === 1 && question.is_answered === 0;
    if (filter === "ratedAndClosed")
      return question.is_replied === 1 && question.is_answered === 1;
    return question.is_replied === 0; // Show all for "all" filter
  });

  return (
    <section className="relative w-full min-h-screen flex flex-col justify-center items-center">
      {/* Filter Buttons */}
      <div className="mb-5 flex gap-3 flex-wrap">
        <button
          onClick={() => setFilter("notYetAnswered")}
          className={`px-4 py-3 rounded-3xl text-white ${
            filter === "notYetAnswered" ? "bg-red-400 " : "bg-red-500"
          }`}
        >
          Not Yet Answered
        </button>
        <button
          onClick={() => setFilter("answering")}
          className={`px-4 py-3 rounded-3xl text-white ${
            filter === "answering" ? "bg-yellow-400 " : "bg-yellow-500"
          }`}
        >
          Answering
        </button>
        <button
          onClick={() => setFilter("ratedAndClosed")}
          className={`px-4 py-3 rounded-3xl text-white ${
            filter === "ratedAndClosed" ? "bg-green-400 " : "bg-green-500"
          }`}
        >
          Rated and Closed
        </button>
      </div>

      {/* Questions List */}
      <div className="flex gap-5 flex-wrap">
        {filteredData?.map((question, index) => (
          <div className="relative w-24 h-24">
           {question.notfication === 1 && <div className="bg-white px-2 py-2 rounded-3xl absolute -top-4 -right-4"><div className="w-6 h-6 rounded-full bg-red-500"></div></div>  }
         <Link
            to={`/asks/${question?.id}`}
            key={index}
            className={`w-full h-full rounded-2xl text-white text-3xl flex items-center justify-center cursor-pointer border border-black ${
              question.is_replied === 0
                ? "bg-red-500"
                : question.is_replied === 1 && question.is_answered === 1
                  ? "bg-green-500"
                  : "bg-yellow-500"
            }`}
          >
            {index + 1}
          </Link>

          </div>
        
        ))}
      </div>
    </section>
  );
}

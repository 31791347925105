import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

export default function AdminAddSkill() {
  const navigate = useNavigate();
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [successSkillAdd, errorSkillAdd, loadingSkillAdd] = useAxios(
    process.env.REACT_APP_SKILLS_ADD_API,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );

  useEffect(() => {
    if (successSkillAdd) navigate(-1);
  }, [successSkillAdd]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    setSubmitAdd({
      flag: "Add Skill",
      dependency: !submitAdd.dependency,
      data,
    });
  };
  return (
    <>
      <HelmetTags title={"Add Skill | Mr. Ahmed Bayoumy"} />
      <section className="flex flex-col px-10 py-10 md:px-0 w-full min-h-screen justify-center items-center">
        <form
          className=" form  shadow-3xl max-w-[600px] w-full "
          onSubmit={handleSubmit(onSubmit)}
        >
          <h4 className="text-3xl font-bold text-center w-full">
          Add Skill
          </h4>
          <div className="w-full">
            <div className="flex gap-5 w-full items-start flex-col">
              <label>Name</label>
              <input
                type="text"
                placeholder="Add Name"
                className="signin-inputs w-full py-5 "
                {...register("name", {
                  required: true,
                })}
              />
              {errors.name && (
                <p className="text-lg text-bold">the field is required</p>
              )}
            </div>
            {errors.name && (
              <p className="text-red-500 text-xl pt-4">
                {errors.name.type === "required" && "This field is required."}
              </p>
            )}
          </div>
          <div className="w-full">
              <label>Youtube embed</label>
              <input
                type="text"
                placeholder="Link"
                className="signin-inputs w-full py-5 "
                {...register("youtubelink", { required: true })}
              />
              {errors.youtubelink && (
                <p className="text-lg text-bold">the field is required</p>
              )}
            </div>
          <button
            disabled={!isValid || loadingSkillAdd}
            type="submit"
            className="submit"
          >
            {loadingSkillAdd ? <Spin tip={"loading"} /> : "Add Skill"}
          </button>
        </form>
      </section>
    </>
  );
}

import { Link, useNavigate, useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";

import useAxios from "../../../../MainComponents/Hooks/useAxios";

import { Loader } from "../../../../MainComponents";

const AdminQuestionCorrection = () => {
  const { studentID, correctionType, examId } = useParams();

  //!--------- get the Question info for Correction -------

  const [QeustionInfo, QeustionInfoErrors, QuestionInfoLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_QUESTION_CORRECTION_API}/${studentID}/${correctionType}/${examId}`,
    "GET",
    examId,
    examId
  );

  if (QuestionInfoLoading) {
    return <Loader />;
  }

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title="Correction | Mr.Ahmed Sabry"></HelmetTags>
      <h1 className="w-full text-4xl font-bold md:text-center mb-32">
        Correction
      </h1>

      {QeustionInfoErrors && (
        <p className="text-center">{QeustionInfoErrors}</p>
      )}

      {QeustionInfo?.data?.map((question) => (
        <div className="flex flex-col gap-12  !border-secondary !border-2 rounded-xl p-8 mb-5">
          <div className="flex items-center justify-between">
            <h2
              className="w-full text-3xl font-bold md:text-center "
              dangerouslySetInnerHTML={{
                __html: question?.question,
              }}
            />
            <Link
              to={`/admin/correction/${studentID}/${correctionType}/${examId}/correction-student/${question.id}`}
              className="w-60 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
            >
              Correction
            </Link>
          </div>
          <textarea
            disabled
            className="textareaQuiz !border-secondary !border-2 rounded-xl p-4 !placeholder:text-slate-400"
          >
            {question?.answer}
          </textarea>
        </div>
      ))}
    </section>
  );
};

export default AdminQuestionCorrection;

import {  faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion , AnimatePresence } from "framer-motion";

const RightQuestionsSidebar = ({ state, dispatch , setShow , show }) => {
  
    return (
      <AnimatePresence mode="wait">
        {show &&       <div
          onClick={() => setShow(false)}
          className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-wrap items-center justify-center px-[20px] transition-all duration-300 ease-in-out w-full mb-10 bg-black bg-opacity-50 h-screen z-[999] `}
        >
          <motion.div
          key="modal"
            initial={{ opacity: 0, y: 20, scale: 0.8 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 20, scale: 0.8 }}
            transition={{ 
            
              duration: 0.2
            }}
            onClick={(e) => e.stopPropagation() }
            className={` max-w-[600px] max-h-[400px] overflow-y-auto shadow-3xl z-10 bg-white p-8 `}>
            <div className=" w-full mt-12">
              <div className=" w-full flex flex-col items-center">
                <div className="flex flex-col items-center w-full gap-10 py-10 ">
                  <h1 className="text-4xl font-bold text-center text-[#374151]">{state.quizInfo.name}</h1>
                  <p className="w-full h-4 text-center text-[#374151] border-b border-b-[#374151]"></p>
                </div>
                <div className=" gap-10  w-full !flex flex-wrap items-center justify-center flex-row">
                  {state.questions.map((question, i) => (
                    <span
                      key={i}
                      className="flex flex-col gap-5 items-center relative"
                      onClick={() => {
                        dispatch({ type: "setCurrentQuestionIndex", payload: i });
                        setShow(!show);
                      }}
                    >
                      {state.currentQuestionIndex === i && 
                        <FontAwesomeIcon icon={faLocationDot} className="absolute top-[-20px]  text-[#374151]"/>}
                      <span className={` ${
                        question.is_selected
                          ? "myquestion !bg-green-500 !border-green-500"
                          : "myquestion !bg-[#f345a4] !border-[#f345a4] !text-[#374151]"
                      }`}>
                      {i + 1}
                      </span>
                      
                    </span>
                  ))}
                </div>
  
                {/* TODO: ADD RESULT CONTAINER */}
           {/*      <div
                   ref={resultContainer}
                  className="result-container flex h-fit w-full flex-col items-center  gap-[16px]"
                ></div> */}
              </div>
            </div>
          </motion.div>
        </div>}
  
      </AnimatePresence>
    );
  

};

export default RightQuestionsSidebar;

import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

export default function CoursesAddCourse() {
  const navigate = useNavigate();
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [successAddCourse, errorAddCourse, loadingAddCourse] = useAxios(
    process.env.REACT_APP_ADMIN_ADD_COURSES_API,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );
  useEffect(() => {
    if (successAddCourse) navigate(-1);
  }, [successAddCourse]);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    setSubmitAdd({
      flag: "Add Course",
      dependency: !submitAdd.dependency,
      data,
    });
  };
  return (
    <>
      <HelmetTags title={"Add Course | Mr. Ahmed Bayoumy"} />
      <section className="flex flex-col px-10 py-10 md:px-0 w-full min-h-screen justify-center items-center">
        <form
          className=" form  shadow-3xl max-w-[600px] w-full "
          onSubmit={handleSubmit(onSubmit)}
        >
          <h4 className="text-3xl font-bold text-center w-full">
            Add new Course
          </h4>
          <div className="w-full">
            <div className="relative flex flex-col justify-center items-start gap-2 ">
              <label htmlFor="course">Course Name</label>
              <input
                type="text"
                placeholder="Add Course Name"
                className="signin-inputs w-full"
                {...register("name", {
                  required: true,
                })}
              />
            </div>
            {errors.name && (
              <p className="text-red-500 text-xl pt-4">
                {errors.name.type === "required" && "This field is required."}
              </p>
            )}
          </div>

          <button
            disabled={!isValid || loadingAddCourse}
            type="submit"
            className="submit"
          >
            {loadingAddCourse ? <Spin tip={"loading"} /> : "Submit"}
          </button>
        </form>
      </section>
    </>
  );
}

import { useParams } from "react-router-dom";
import useTable from "../../../../MainComponents/Hooks/useTable";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";
import { useState } from "react";

export default function ViewGroup() {
  const { CourseNumber } = useParams();
  const [refetch, setReFetch] = useState();
  const table = useTable("viewGroup", "", "", "", "", CourseNumber);

  return (
    <main className="py-10 px-10">
      <h1 className="text-5xl">Students</h1>
      <div className="py-20">{table}</div>
    </main>
  );
}

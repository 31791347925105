import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useState } from "react";
import AskButton from "../../../../MainComponents/AskButton";
import { Loader } from "../../../../MainComponents";
const QuestionModalAnswer = ({
  collapse,
  onCloseCollapse,
  activeQuestionId,
  question,
  view,
  hidden,
  ID,
  loading,
  asked,
  essay,
  setReFetchButton
}) => {
  const [showExplanation, setShowExplanation] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const correctAnswer = question?.correct_answer;
  const studentAnswer = question?.student_answer;
  const handleToggleExplanation = () => {
    setShowExplanation((prev) => !prev); // Toggle explanation visibility
    if (!showExplanation) setShowVideo(false); // Hide video if showing explanation
  };
  const handleToggleVideo = () => {
    setShowVideo((prev) => !prev); // Toggle video visibility
    if (!showVideo) setShowExplanation(false); // Hide explanation if showing video
  };

  return (
    <div
      className={`single-question fixed left-0 top-0  z-[2000] h-screen w-[80%] overflow-y-auto bg-red-200 dark:bg-dark px-[20px] pb-[40px] pt-[128px] transition-all  duration-300 ease-in-out md:w-full ${
        collapse ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="close-btn-wrapper mb-[20px] flex w-full justify-end pr-[20px]">
            <div
              onClick={() => onCloseCollapse(false)}
              className="close-question-btn flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-accent text-light shadow-md shadow-black/40 transition-all duration-300 ease-in-out hover:scale-110 active:scale-90"
            >
              <FontAwesomeIcon
                className="transition-all duration-300 ease-in-out hover:scale-110 active:scale-90"
                icon={faXmark}
              />
            </div>
          </div>

          {view && (
            <div className="flex flex-col gap-5">
              <p className="text-2xl font-bold ">{question?.name}</p>
            </div>
          )}

          <div className="current-question flex flex-row-reverse">
            {`.${activeQuestionId + 1}`}
            <div
              dangerouslySetInnerHTML={{
                __html: question?.question,
              }}
            />
          </div>

          <div className="input-container response-question-input flex flex-col items-end"></div>

          <div className="response-question-answers">
            {view || question?.is_essay === 1 ? (
      
              <>
              {question?.is_essay === 1 &&   question?.student_answer === question?.correct_answer ?  
               <div className="bg-lime-500 border-lime-500 text-white">
                <p
                    className="transition-all  text-3xl"
                    dangerouslySetInnerHTML={{ __html: question?.student_answer }}
                  />
                </div> 
                : !view ?
                <>
                 <div className="bg-lime-500 border-lime-500 text-white flex flex-col gap-5 items-start text-start">
  {question?.correct_answers?.map((item, index) => (
    <p className="w-full flex items-start" key={index}>{item[`answer${index + 1}`]}</p>
  ))}
</div>
                  <div className="bg-red-500 border-red-500 text-white">
                <p
                    className="transition-all  text-3xl !text-white"
                    dangerouslySetInnerHTML={{ __html: question?.student_answer }}
                  />
                </div>
                 </> 
                 : 
                 <div className="bg-lime-500 border-lime-500 text-white">
                 <p
                     className="transition-all  text-3xl"
                     dangerouslySetInnerHTML={{ __html: question?.correct_answer }}
                   />
                 </div>
                }
                
            
           
              </>
          

                
             
           
            ) : (
              Object.keys(question?.answers || {}).map((key) => {
                const answer = question.answers[key];
                const isCorrect = key === correctAnswer;
                const isSelected = key === studentAnswer;

                return (
                  <div
                    key={key}
                    className={`response-answer px-4 py-2 my-2 rounded-md border ${
                      isCorrect
                        ? "bg-lime-500 border-lime-500 text-white"
                        : isSelected
                          ? "bg-red-500 border-red-500 text-white"
                          : "bg-gray-100 border-gray-300"
                    }`}
                  >
                    <p
                      className={`transition-all ${
                        isCorrect || isSelected ? "!text-white" : "text-black"
                      }`}
                      dangerouslySetInnerHTML={{ __html: answer }}
                    />
                  </div>
                );
              })
            )}

            {view &&
              (question?.skills?.length > 0 ? (
                question?.skills?.map((skill, index) => (
                  <Link
                    key={index}
                    to={`/skills/exam/start/${question?.skills[index]?.id}`}
                    className="bg-blue-500 text-white text-xl rounded-3xl hover:bg-secondary"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: question?.skills[index]?.name,
                      }}
                    />
                  </Link>
                ))
              ) : (
                null
              ))}
          </div>

          <div className="flex gap-5 mt-5">
            {question?.explanation && (
              <button
                className="submit-btn rounded-md border border-blue-500 bg-blue-500 px-[16px] py-[8px] font-bold text-white duration-200 hover:scale-110 active:scale-90"
                onClick={handleToggleExplanation}
              >
                {showExplanation ? "Hide" : "Show"} Explanation
              </button>
            )}
            {question?.video !== null && question?.video && (
              <button
                onClick={handleToggleVideo}
                className="submit-btn rounded-md border border-blue-500 bg-blue-500 px-[16px] py-[8px] font-bold text-white duration-200 hover:scale-110 active:scale-90"
              >
                {showVideo ? "Hide" : "Show"} Video
              </button>
            )}
            {hidden === false && (
              <AskButton
                ID={question?.id}
                essay={question?.is_essay === 0 || essay === 0 ? 0 : 1}
                asked={question?.is_asked === 0 || asked === 0 ? 0 : 1}
                setReFetchButton={setReFetchButton}
              />
            )}
          </div>

          {showExplanation && question?.explanation && (
            <div
              className="text-[#374151]"
              dangerouslySetInnerHTML={{ __html: question.explanation }}
            />
          )}

          {showVideo && question?.video && (
            <div
              className="text-[#374151]"
              dangerouslySetInnerHTML={{ __html: question.video }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default QuestionModalAnswer;

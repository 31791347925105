import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useEffect } from "react";
import { Loader } from "../../../../MainComponents";
export default function EditVideo() {
  const { questionID, type } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [submitEdit, setSubmitEdit] = React.useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [GETSuccess, GETError, GETLoading] = useAxios(
    `https://api.bayoumymath.com/api/admin/questions/video/${questionID}/${type}`,
    "GET",
    "GET",
    submitEdit.dependency
  );

  const [editSuccess, editError, editLoading] = useAxios(
    "https://api.bayoumymath.com/api/admin/questions/updatevideo",
    "POST",
    submitEdit.flag,
    submitEdit.dependency,
    submitEdit.data,
    true
  );
  useEffect(() => {
    if (GETSuccess) {
      setValue("videoembed", GETSuccess.data.video);
    }
  }, [GETSuccess]);

  const onSubmit = (data) => {
    setSubmitEdit({
      flag: "editVideo",
      dependency: !submitEdit.dependency,
      data: { id: questionID, type: type, ...data },
    });
  };

  if (GETLoading) return <Loader />;

  return (
    <div className="w-full px-10 py-10">
      <h2 className="text-3xl font-bold">Edit Video</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="form shadow-3xl">
        <div className="w-full">
          <label htmlFor="videoEmbed">Video Embed:</label>
          <textarea
            id="videoEmbed"
            className="signin-inputs"
            {...register("videoembed", { required: true })}
            placeholder="Paste video embed code here"
            rows="5"
          />
          {errors.videoembed && <span>This field is required</span>}
        </div>
        <button type="submit" className="submit" disabled={editLoading}>
          {editLoading ? "Submitting..." : "Submit"}
        </button>
      </form>
    </div>
  );
}

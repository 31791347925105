import React, { useState, useEffect } from "react";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useParams } from "react-router-dom";
import { DatePicker } from "antd";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";

const { RangePicker } = DatePicker;

export default function AdminFollowUp() {
  const { CourseNumber } = useParams();
  const [allData, setAllData] = useState([]);
  const [date, setDate] = useState([]);
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    depdency: false,
    data: {},
  });

  const [successData, errorData, loadingSuccess] = useAxios(
    "https://api.bayoumymath.com/api/admin/followup",
    "POST",
    submitAdd.flag,
    submitAdd.depdency,
    submitAdd.data,
    true
  );

  // Handle the date change
  const handleChangeDate = (dates) => {
    if (dates[0] && dates[1]) {
      const startDate = dates[0];
      const endDate = dates[1];
      setDate([startDate, endDate]);
    }
  };

  useEffect(() => {
    if (date.length === 2) {
      const [from, to] = date;
      setSubmitAdd({
        flag: "Change Table",
        depdency: !submitAdd.depdency, // Toggle to trigger the API call
        data: {
          course_id: CourseNumber,
          from,
          to,
        },
      });
    }
  }, [date, CourseNumber]);

  // Pass formatted data to useTable
  const table = useTable(
    "followUp",
    "",
    false,
    successData?.data,
    "",
    "",
    true,
    successData?.data
  );

  return (
    <div className="px-10 py-10 flex flex-col gap-5">
      <div className="w-1/2 sm:w-full">
        <RangePicker
          className="signin-inputs"
          onChange={(_, dateString) => handleChangeDate(dateString)}
        />
      </div>
      {loadingSuccess ? <Loader /> : successData ? <div>{table}</div> : null}
    </div>
  );
}

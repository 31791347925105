import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

export default function AddEditTrailExam({ edit }) {
  const navigate = useNavigate();
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [dataCourses, errorCourses, loadingCourses] = useAxios(
    process.env.REACT_APP_ADMIN_GET_COURSES_API,
    "GET",
    "GET",
    ""
  );
  const [successAddExam, errorAddExam, loadingAddExam] = useAxios(
    process.env.REACT_APP_ADMIN_ADD_ASSIGMNETS_API,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );

  useEffect(() => {
    if (successAddExam) navigate(-1);
  }, [successAddExam]);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    setSubmitAdd({
      flag: "Add Exam",
      dependency: !submitAdd.dependency,
      data,
    });
  };
  return (
    <>
      <HelmetTags title={"Add Exam | Mr. Ahmed Bayoumy"} />
      <section className="flex flex-col px-10 py-10 md:px-0 w-full min-h-screen justify-center items-center">
        <form
          className=" form  shadow-3xl max-w-[600px] w-full "
          onSubmit={handleSubmit(onSubmit)}
        >
          <h4 className="text-3xl font-bold text-center w-full">
           {edit ? "Edit" : "Add"}  Exam
          </h4>
          <div className="w-full">
            <div className="flex gap-5 w-full items-start flex-col">
              <label>Name</label>
              <input
                type="text"
                placeholder="Add Name"
                className="signin-inputs w-full py-5 "
                {...register("name", {
                  required: true,
                })}
              />
              {errors.name && (
                <p className="text-lg text-bold">the field is required</p>
              )}
            </div>
            {errors.name && (
              <p className="text-red-500 text-xl pt-4">
                {errors.name.type === "required" && "This field is required."}
              </p>
            )}
          </div>
          <div className="flex gap-5 w-full items-start flex-col">
            <label>Description</label>
            <textarea
              className="signin-inputs w-full py-5 "
              placeholder="Add Description"
              {...register("description")}
            ></textarea>
          </div>
          <div className="flex gap-5 w-full items-start flex-col">
            <label>Duration</label>
            <input
              type="number"
              placeholder="Add Duration"
              className="signin-inputs w-full py-5 "
              {...register("duration", {
                required: true,
              })}
            />
            {errors.duration && (
              <p className="text-lg text-bold">the field is required</p>
            )}
          </div>
          <div className="flex gap-5 w-full items-start flex-col">
            <label>Course</label>
            <select
              placeholder="Choose Course"
              className="signin-inputs w-full "
            >
              {dataCourses?.data?.map((item, idx) => (
                <option value={item.name} key={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <button
            disabled={!isValid || loadingAddExam}
            type="submit"
            className="submit"
          >
            {loadingAddExam ? <Spin tip={"loading"} /> : "Add Exam"}
          </button>
        </form>
      </section>
    </>
  );
}

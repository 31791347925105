import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useParams } from "react-router-dom";
import { useState, useReducer, useEffect } from "react";
import { Table, Input, Form } from "antd";
import { Loader } from "../../../../MainComponents";

export default function AddSkillsQuestionBank() {
  const { questionID } = useParams();
  const [skills, setSkills] = useState([]);
  const [duration, setDuration] = useState("");
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [dataSkills, errorSkills, loadingSkills] = useAxios(
    `https://api.bayoumymath.com/api/admin/assignments/addQuestionSkills/${questionID}`,
    "GET",
    "GET",
    ""
  );
  const [
    successCreateCheckpoint,
    errorCreateCheckpoint,
    loadingCreateCheckpoint,
  ] = useAxios(
    `https://api.bayoumymath.com/api/admin/assignments/submitQuestionSkills`,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );

  const [skillLimits, setSkillLimits] = useState({});

  const handleLimitChange = (skillId, value) => {
    setSkillLimits((prev) => ({ ...prev, [skillId]: value }));
  };

  const [state, dispatch] = useReducer(reducer, {
    sortedInfo: null,
    filteredInfo: null,
  });

  function reducer(state, action) {
    switch (action.type) {
      case "SetSortingOptions":
        return { ...state, sortedInfo: action.payload };
      case "SetFilteredInfo":
        return { ...state, filteredInfo: action.payload };
      default:
        return state;
    }
  }

  const onSubmit = () => {
    const finalData = {
      id: questionID,
      skills: skills.map((skill) => skill.key),
    };

    console.log(finalData);
    setSubmitAdd({
      flag: "Add SaveSkillsAndCourses",
      dependency: !submitAdd.dependency,
      data: finalData,
    });
  };

  const SkillsHomeworkColumns = [
    {
      title: "Name",
      dataIndex: "name",
      editTable: true,
      width: 200,
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
    },
  ];

  const [editingKey, setEditingKey] = useState("");
  const [searchText, setSearchText] = useState("");
  const [checkedIds, setCheckedIds] = useState([]);

  useEffect(() => {
    if (!loadingSkills && dataSkills?.data?.checkedIds) {
      setCheckedIds(dataSkills.data.checkedIds);
    }
  }, [loadingSkills, dataSkills]);

  const filteredData = dataSkills?.data?.skills.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase())
  );

  if (loadingSkills) return <Loader />;
  return (
    <section className="min-h-screen flex flex-col items-center justify-center w-full px-10 py-10">
      <div className="py-10 px-2 w-full ">
        <h3 className="text-5xl pb-10">Skills</h3>
        <div>
          <div className="flex gap-4 mb-4">
            <Input
              placeholder="Search skills..."
              onChange={(e) => setSearchText(e.target.value)}
              className="max-w-[400px] w-full px-2 py-3 signin-inputs"
            />
          </div>
          <Table
            dataSource={filteredData}
            columns={SkillsHomeworkColumns}
            pagination={{ position: ["bottomCenter"] }}
            bordered
            scroll={{ x: 800, y: 800 }}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: checkedIds,
              onChange: (selectedRowKeys, selectedRows) => {
                setSkills(selectedRows);
                setCheckedIds(selectedRowKeys);
              },
            }}
            className="border border-secondary p-1 rounded-3xl"
          />
        </div>
      </div>
      <div className="mt-4 flex w-full items-center justify-center gap-10 md:flex-col md:items-center">
        <button
          className="w-fit rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
          disabled={skills.length <= 0}
          onClick={() => onSubmit()}
        >
          save
        </button>
      </div>
    </section>
  );
}

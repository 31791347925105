import React, { useEffect, useCallback, useReducer, useState } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import QuizHeader from "./component/quizHeader/QuizHeader";
import { DisplayQuestion } from "./component/displayQuestion/DisplayQuestion";
import Question from "./component/displayQuestion/Question";
import QuizFooter from "./component/quizFooter/QuizFooter";
import RightQuestionsSidebar from "./component/quizHeader/RightQuestionsSidebar";
import DisplayAnswers from "./component/displayAnswers/DisplayAnswers";
import QuestionsAnswers from "./component/displayAnswers/QuestionsAnswers";
import PercentageSidebar from "./component/displayAnswers/PercentageSidebar";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { Loader } from "../../../MainComponents";

function reducer(state, action) {
  switch (action.type) {
    case "setLoading":
      return { ...state, loading: action.payload };

    case "setCollapse":
      return { ...state, collapse: !state.collapse };

    case "setQuestions":
      return { ...state, questions: action.payload };

    case "setQuizInfo":
      return { ...state, quizInfo: action.payload };

    case "setCurrentQuestionIndex":
      return {
        ...state,
        collapse: false,
        currentQuestionIndex: action.payload,
      };

    case "setIsShowAnswers":
      return { ...state, isShowAnswers: true };

    case "setIsSelectedAnswer":
      return {
        ...state,
        questions: state.questions.map((question) =>
          question.id === action.payload.questionId
            ? {
                ...question,
                selected_answer: action.payload.answer,
                is_selected: action.payload.questionId,
                n: action.payload.n,
              }
            : question
        ),
      };

    case "setAnsweredQuestions":
      const answerExists = state.answeredQuestions.some(
        (answer) => answer.id === action.payload.id
      );

      if (!answerExists) {
        return {
          ...state,
          answeredQuestions: [...state.answeredQuestions, action.payload],
        };
      } else {
        return {
          ...state,
          answeredQuestions: state.answeredQuestions.map((question) =>
            question.id === action.payload.id
              ? { ...question, ...action.payload }
              : question
          ),
        };
      }

    case "setFinishQuizData":
      return { ...state, finishQuizData: action.payload };

    case "setAllData":
      return { ...initialState };

    case "setRefetch":
      return { ...state, refetch: action.payload };

    default:
      throw Error("Unknown action: " + action.type);
  }
}

const initialState = {
  loading: true,
  currentQuestionIndex: 0,
  collapse: false,
  questions: [],
  quizInfo: {},
  answeredQuestions: [],
  finishQuizData: {},
  numOfSelected: 0,
  isShowAnswers: false,
  refetch: false,
};

const QuizNew = ({ homework, exam, assignment, weekly, skill, type }) => {
  const { ID } = useParams();
  const [stopwatchTime, setStopwatchTime] = useState(0);
  const [show, setShow] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  // Memoized API endpoint selection
  const checkApi = useCallback(() => {
    if (weekly) return process.env.REACT_APP_STUDENT_WEEKLY_EXAM_GET_API;
    if (homework) return process.env.REACT_APP_STUDENT_HOMEWORK_SKILL_GET_API;
    if (type === "skills")
      return process.env.REACT_APP_STUDENT_SKILLS_HOMEWORK_EXAM_GET_API;
    if (skill) return process.env.REACT_APP_STUDENT_SKILLS_EXAM_GET_API;
    if (assignment) return process.env.REACT_APP_EXAM_ASSIGNMENTS_GET_API;
    return process.env.REACT_APP_STUDENT_QUIZZ_API;
  }, [homework, weekly, type, skill, assignment]);

  // Memoized finish API endpoint selection
  const checkFinishApi = useCallback(() => {
    if (homework)
      return "https://api.bayoumymath.com/api/homeworkskills/skill/submit";
    if (assignment) return process.env.REACT_APP_EXAM_ASSIGNMENTS_SUMBIT_API;
    if (weekly) return process.env.REACT_APP_STUDENT_WEEKLY_SUBMIT_EXAM_API;
    if (skill) return process.env.REACT_APP_SKILLS_SUBMIT_API;
    return "";
  }, [homework, assignment, weekly, skill]);

  // Fetch quiz data
  const [quizData, quizErrors, quizLoading] = useAxios(
    `${checkApi()}/${ID}`,
    "GET",
    "GET",
    state.refetch,
    ""
  );

  // Set axios default authorization
  useEffect(() => {
    const userData = JSON.parse(localStorage.userData);
    axios.defaults.headers.common["Authorization"] =
      `Bearer ${userData.student_token}`;
  }, []);

  // Update questions and quiz info
  useEffect(() => {
    if (quizData) {
      const combinedQuestions = [
        ...(quizData.choosequestions || []),
        ...(quizData.essayquestions || []),
      ];

      dispatch({
        type: "setQuestions",
        payload: combinedQuestions,
      });

      dispatch({
        type: "setQuizInfo",
        payload: quizData.exam || {},
      });

      dispatch({ type: "setRefetch", payload: false });
    }
  }, [quizData]);

  // Stopwatch timer
  useEffect(() => {
    const stopwatchTimer = setInterval(() => {
      setStopwatchTime((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(stopwatchTimer);
  }, []);

  // Persist stopwatch time
  useEffect(() => {
    localStorage.setItem(`Quiz${ID}`, stopwatchTime.toString());
  }, [stopwatchTime, ID]);

  // Track answered questions
  useEffect(() => {
    const updateAnsweredQuestions = (questionFilter, isEssay) => {
      state.questions.filter(questionFilter).forEach((question) => {
        dispatch({
          type: "setAnsweredQuestions",
          payload: {
            id: question.id,
            order: isEssay ? question.answer : question.selected_answer,
            is_essay: isEssay ? 1 : 0,
            n: question.n,
            n_try: quizData?.exam?.n_try,
          },
        });
      });
    };

    updateAnsweredQuestions((q) => q.is_selected === 1, false);
    updateAnsweredQuestions((q) => q.answer?.length > 1, true);
  }, [state.questions, quizData]);

  // Finish quiz handler
  const onFinishQuiz = useCallback(() => {
    const fetchData = async () => {
      try {
        const allAnswers = state.questions.map((question) => {
          const answeredQuestion = state.answeredQuestions.find(
            (ans) => ans.id === question.id
          );

          return answeredQuestion
            ? {
                ...answeredQuestion,
                order: answeredQuestion.order || "unanswered",
              }
            : {
                id: question.id,
                order: "unanswered",
                is_essay: question?.answers?.length > 1 ? 0 : 1,
                n: question.n,
                n_try: question.n_try || quizData?.exam?.n_try,
              };
        });

        const res = await axios.post(checkFinishApi(), {
          n_try: quizData?.exam?.n_try,
          id: ID,
          answers: allAnswers,
          duration: localStorage.getItem(`Quiz${ID}`) || 0,
        });

        dispatch({ type: "setFinishQuizData", payload: res.data.data });
        dispatch({ type: "setIsShowAnswers" });
      } catch (error) {
        toast.error(error.response?.data?.message || "An error occurred");
      }
    };

    fetchData();
  }, [state.questions, state.answeredQuestions, ID, quizData, checkFinishApi]);

  // Loading and error states
  if (quizLoading) return <Loader />;

  if (!quizData?.choosequestions?.length && !quizData?.essayquestions?.length) {
    return (
      <div className="h-screen bg-secondary w-full text-white flex items-center justify-center">
        No Data
      </div>
    );
  }

  // Render main component
  return (
    <section
      className={`flex h-fit min-h-screen w-full flex-col items-center ${
        state.isShowAnswers ? "pt-[80px]" : "pt-[128px]"
      }`}
    >
      <HelmetTags
        title={`${
          assignment ? "Assignments" : type ? type : "Skills"
        } | Mr.Ahmed Bayoumy`}
      />

      {quizErrors && (
        <div className="flex justify-center items-center h-screen w-full flex-col">
          <p className="text-5xl font-bold">
            {quizErrors?.response?.data?.message}
          </p>
          <div
            onClick={() => navigate(-1)}
            className="flex justify-center my-6"
          >
            <div className="collapse-btn top-[96px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-accent text-light shadow-md shadow-black/40 duration-300 hover:scale-110 hover:text-light">
              <FontAwesomeIcon
                className={`transition-all duration-300 ease-in-out hover:scale-110 ${
                  state.collapse && "rotate-180"
                }`}
                icon={faChevronRight}
              />
            </div>
          </div>
        </div>
      )}

      {!quizLoading && !quizErrors && (
        <main className="w-full min-h-fit md:min-h-fit flex-col md:gap-[40px] flex max-w-[1700px] justify-center items-center">
          {!state.isShowAnswers ? (
            <>
              <QuizHeader
                state={state}
                dispatch={dispatch}
                onFinishQuiz={onFinishQuiz}
                quizErrors={quizErrors}
                quizLoading={quizLoading}
                exam={exam}
                quizData={quizData}
              />

              <DisplayQuestion state={state}>
                <Question
                  state={state}
                  dispatch={dispatch}
                  homework={homework}
                  exam={exam}
                  assignment={assignment}
                  weekly={weekly}
                  skill={skill}
                  n_try={quizData?.exam?.n_try}
                />
              </DisplayQuestion>
              <QuizFooter
                state={state}
                dispatch={dispatch}
                onFinishQuiz={onFinishQuiz}
                setShow={setShow}
                show={show}
              />
            </>
          ) : (
            <DisplayAnswers>
              <QuestionsAnswers state={state} dispatch={dispatch} exam={exam} />
              <PercentageSidebar state={state} />
            </DisplayAnswers>
          )}

          <RightQuestionsSidebar
            state={state}
            dispatch={dispatch}
            show={show}
            setShow={setShow}
          />
        </main>
      )}
    </section>
  );
};

export default React.memo(QuizNew);

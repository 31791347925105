import { useState } from "react";
import { message, Upload } from "antd";
import { useParams } from "react-router-dom";
import { useAuthState } from "./GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaFilePdf } from "react-icons/fa6";
import useAxios from "./Hooks/useAxios";
import { useForm } from "react-hook-form";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const { Dragger } = Upload;

const Attachment = () => {
  const { assignmentID, skillID } = useParams();
  const [refetch, setRefetch] = useState(false);
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    depdency: false,
    data: {},
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const [deletePdfSuccess, deletePdfError, deletePdfLoading] = useAxios(
    `https://api.bayoumymath.com/api/admin/pdf/delete`,
    "POST",
    submitDelete.flag,
    submitDelete.depdency,
    submitDelete.data,
    true
  );

  const [PdfInfo, PdfError, PdfLoading] = useAxios(
    `https://api.bayoumymath.com/api/admin/pdf/${
      assignmentID ? "assignment" : "skill"
    }/${assignmentID || skillID}`,
    "GET",
    "GET",
    refetch
  );
  const AuthState = useAuthState();
  const uploadProps = {
    name: "file",
    multiple: true,
    action: "https://api.bayoumymath.com/api/admin/pdf/add",
    headers: {
      Authorization: `Bearer ${
        AuthState?.userData?.student_token || AuthState?.userData?.admin_token
      }`, // Add token if required
    },
    data: (file) => ({
      id: assignmentID ? assignmentID : skillID,

      type: assignmentID ? "assignment" : "skill",
      address: file,
    }),
    beforeUpload: (file) => {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        message.error("You can only upload PDF files!");
      }
      return isPdf || Upload.LIST_IGNORE; // Prevent non-PDF files from being uploaded
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        const uploadedFilePath = info.file.response?.address || info.file.name; // Assuming server response contains the address
        message.success(`${info.file.name} uploaded successfully.`);
        setRefetch(!refetch);
        console.log("Uploaded File Address:", uploadedFilePath);
      } else if (status === "error") {
        message.error(`${info.file.name} upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleDelete = (pdfId) => {
    setSubmitDelete({
      flag: true, // Trigger the useAxios hook
      depdency: !submitDelete.depdency, // Toggle the dependency to re-trigger useAxios
      data: { id: pdfId }, // Send the ID of the PDF to delete
    });
    setRefetch(!refetch);
  };

  return (
    <section className="min-h-screen w-full flex flex-col items-center justify-center md:px-0 px-10 py-10">
      <form className="mx-auto flex flew-wrap gap-8 py-10">
        {PdfInfo?.data?.map((item, index) => (
          <div key={index} className="form shadow-3xl">
            <p className="text-4xl text-black">{item.name}</p>
            <div className="flex  gap-5">
              <a
                href={item.address}
                download
                target="_blank"
                className=" px-4 py-3 text-white rounded-3xl bg-blue-500 font-semibold flex w-full justify-center items gap-5 hover:bg-blue-700 duration-200"
              >
                Open
              </a>

              <button
                type="button"
                className="bg-red-500 px-4 py-3 rounded-3xl text-white hover:bg-red-800 "
                onClick={() => handleDelete(item.key)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </form>
      <div className="max-w-[700px] form shadow-3xl">
        <h1 className="text-black text-3xl font-bold text-center">
          Upload File
        </h1>
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <FaFilePdf className="text-secondary text-7xl mx-auto" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Only PDF files are allowed.
          </p>
        </Dragger>
      </div>
    </section>
  );
};

export default Attachment;

import { useEffect, useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useForm } from "react-hook-form";
import Loader from "../../../../MainComponents/Loader";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function AdminBooks() {
  const table = useTable("books");

  return (
    <>
      <HelmetTags title="Books | Mr.Ahmed Bayoumy"></HelmetTags>
      <section
        className={` h-auto w-full   dark:bg-dark dark:text-light py-10 px-10`}
      >
        <Link
          to={"/admin/books/add-book"}
          className="w-fit px-4 py-3  duration-200 flex gap-5 items-center bg-secondary text-white hover:text-secondary hover:bg-white border border-secondary rounded-3xl"
        >
          <FontAwesomeIcon icon={faPlus} /> Add Book
        </Link>
        <div className="h-fit w-full py-3">{table}</div>
      </section>
    </>
  );
}

import { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import QuestionModalAnswer from "../../../Admin/Dashboard/Exams/QuestionModalAnswer";
import { useAuthState } from "../../../../MainComponents/GlobalContext";

const ModalAnswer = ({ exam, type, view }) => {
  const [collapse, setCollapse] = useState(false);
  const [activeQuestionId, setActiveQuestionId] = useState(0);
  const { ID } = useParams();
  const navigate = useNavigate();
  const AuthState = useAuthState();
  const [refetch, setReFetch] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (refetch) {
      setReFetch(false);
    }
  }, [refetch]);

  const api = useMemo(() => {
    if (exam) return process.env.REACT_APP_STUDENT_TRIAL_EXAM_MODAL_ANSWER_API;
    if (view) return process.env.REACT_APP_STUDENT_HISTORY_VIEW_GET_API;
    if (type === "studentSkills")
      return process.env.REACT_APP_STUDENT_HOMEWORK_MODELANSWER_GET_API;
    if (type === "assignments")
      return "https://api.bayoumymath.com/api/assignment/modelanswer/";
    return process.env.REACT_APP_STUDENT_WEEKLY_MODEL_GET_API;
  }, [exam, view, type]);

  const [modalAnswerData, modalAnswerErrors, modalAnswerLoading] = useAxios(
    `${api}${ID}`,
    "GET",
    ID,
    refetch
  );

  useEffect(() => {
    if (!modalAnswerLoading && !hasLoaded) {
      setHasLoaded(true);
    }
  }, [modalAnswerLoading, hasLoaded]);

  const handleQuestionClick = (id) => {
    setCollapse(true);
    setActiveQuestionId(id);
  };
  if (!hasLoaded && modalAnswerLoading) return <Loader />;

  if (modalAnswerErrors)
    return (
      <div className="error-container flex justify-center items-center flex-col gap-5 min-h-screen">
        <p className="text-4xl font-bold text-center">
          Failed to load answers. Please try again later.
        </p>
        <button
          className="bg-secondary hover:scale-105 text-white rounded-3xl px-3 py-3"
          onClick={() => navigate(-1)}
        >
          Go Back
        </button>
      </div>
    );
  const answers = [
    ...(modalAnswerData?.data?.answers || []), // Default to empty array if null
    ...(modalAnswerData?.data?.essay_questions || []),
  ];
  console.log(answers);
  return (
    <div className="left-response-container flex items-start md:w-full min-h-screen">
      <HelmetTags title="Modal Answer" />
      <QuestionModalAnswer
        collapse={collapse}
        onCloseCollapse={() => setCollapse(false)}
        activeQuestionId={activeQuestionId}
        question={answers[activeQuestionId]}
        view={view}
        ID={ID}
        hidden={false}
        setReFetchButton={setReFetch}
      />
      <div className="all-questions flex h-full flex-col items-center overflow-y-auto  p-[40px] transition-all  duration-300 ease-in-out w-full md:order-2 md:w-full">
        <h1 className="text-3xl font-bold text-black dark:text-white ">
          Modal Answer
        </h1>
        <p className="my-[20px] text-center text-secondary dark:text-white">
          Click To The Number To Show The Modal
        </p>
        {modalAnswerData?.data?.getresults?.dgree >= 0 ||
          (modalAnswerData?.data?.result?.dgree >= 0 && (
            <div className="flex  gap-5 items-end py-10 w-full ">
              <h2 className="text-3xl font-bold text-black">Total Degree:</h2>
              <p className="text-bold text-3xl text-red-500">
                {modalAnswerData?.data?.getresults?.dgree ||
                  modalAnswerData?.data?.result?.dgree}{" "}
                <span className="text-black">out of</span>{" "}
                {modalAnswerData?.data?.getresults?.out_of ||
                  modalAnswerData?.data?.result?.out_of}
              </p>
            </div>
          ))}

        <div className="flex gap-5 flex-wrap">
          {type === "weekly"
            ? answers.map((question, index) => (
                <div
                  key={index}
                  className={`w-14 h-14 text-white flex items-center justify-center cursor-pointer ${
                    question.visited === 1
                      ? "bg-gray-700"
                      : question.correct_answer === question.student_answer
                        ? "bg-green-500"
                        : question.student_answer === null ||
                            question.student_answer === "unanswered"
                          ? "bg-yellow-500"
                          : "bg-red-500"
                  }`}
                  onClick={() => {
                    handleQuestionClick(index);
                  }}
                >
                  {index + 1}
                </div>
              ))
            : answers.map((question, index) => (
                <div
                  key={index}
                  className={`w-14 h-14 text-white flex items-center justify-center cursor-pointer ${
                    question.correct_answer === question.student_answer
                      ? "bg-green-500"
                      : question.student_answer === null ||
                          question.student_answer === "unanswered"
                        ? "bg-yellow-500"
                        : "bg-red-500"
                  }`}
                  onClick={() => {
                    handleQuestionClick(index);
                  }}
                >
                  {index + 1}
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default ModalAnswer;

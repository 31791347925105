import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../../MainComponents/Loader";

export default function EditWeekly() {
  const { CourseNumber } = useParams();
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [dataWeekly, errorWeekly, loadingWeekly] = useAxios(
    `${process.env.REACT_APP_BASE_ADMIN_API}weekly/edit/${CourseNumber}`,
    "GET",
    "GET",
    ""
  );

  const [successUpdate, errorUpdate, loadingUpdate] = useAxios(
    `${process.env.REACT_APP_BASE_ADMIN_API}weekly/update`,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    const finalData = {
      cource_id: dataWeekly.data.checkpointcourse.course_id,
      ...data,
    };
    setSubmitAdd({
      flag: "Add Assignment",
      dependency: !submitAdd.dependency,
      data: finalData,
    });
  };

  // Update form values when dataWeekly is loaded
  useEffect(() => {
    if (dataWeekly) {
      reset({
        numberofquestions1input: dataWeekly.data.checkpointcourse
          .numberofquestions
          ? dataWeekly.data.checkpointcourse.numberofquestions
          : 0,
        numberofquestions2input: dataWeekly.data.checkpointcourse
          .numberofquestions2
          ? dataWeekly.data.checkpointcourse.numberofquestions2
          : 0,
        duration1input: dataWeekly.data.checkpointcourse.duration1
          ? dataWeekly.data.checkpointcourse.duration1
          : 0,
        duration2input: dataWeekly.data.checkpointcourse.duration2
          ? dataWeekly.data.checkpointcourse.duration2
          : 0,
        dateselect: dataWeekly.data.checkpointcourse.date,
        timeinput: dataWeekly.data.checkpointcourse.time,
      });
    }
  }, [dataWeekly, reset]);

  if (loadingWeekly) return <Loader />;

  return (
    <main className="h-auto width flex flex-col items-center mt-6">
      <h1 className="text-3xl font-bold">Edit Weekly Check-Point</h1>

      <form
        className="flex flex-col justify-center items-start bg-white p-16  rounded-3xl shadow-3xl w-3/4 my-6 gap-6"
        onSubmit={handleSubmit(onSubmit)}
      >

          <div className="w-full">
            <div className="relative flex flex-wrap justify-center items-start gap-7 ">
              <p>Course Name:</p>
              <p>{dataWeekly?.data?.course?.name}</p>
            </div>
          </div>
            <div className="w-full">
            <div className="relative flex flex-wrap justify-center items-start gap-7 ">
            <label>Number Of Questions 1</label>
            <input
              type="number"
     className="signin-inputs w-full"
              {...register("numberofquestions1input")}
            />
          </div>
            </div>
         <div className="w-full">
         <div className="relative flex flex-wrap justify-center items-start gap-7 ">
            <label>Number Of Questions 2</label>
            <input
              type="number"
              className="signin-inputs w-full"
              {...register("numberofquestions2input")}
            />
          </div>
         </div>
         <div className="w-full">
         <div className="relative flex flex-wrap justify-center items-start gap-7 ">
            <label>Duration 1 in Minutes</label>
            <input
              type="number"
              {...register("duration1input")}
                 className="signin-inputs w-full"
            />
          </div>
         </div>
         
          <div className="w-full">
          <div className="relative flex flex-wrap justify-center items-start gap-7 ">
            <label>Duration 2 in Minutes</label>
            <input
              type="number"
              {...register("duration2input")}
             className="signin-inputs w-full"
            />
          </div>
          </div>
          
            <div className="w-full">
            <div className="relative flex flex-wrap justify-center items-start gap-7 ">
            <label >Date</label>
            <select {...register("dateselect")} className="signin-inputs w-full">
              {[
                { name: "Sunday", value: "Sun" },
                { name: "Monday", value: "Mon" },
                { name: "Tuesday", value: "Tue" },
                { name: "Wednesday", value: "Wed" },
                { name: "Thursday", value: "Thu" },
                { name: "Friday", value: "Fri" },
                { name: "Saturday", value: "Sat" },
              ].map((item, idx) => (
                <option key={idx} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
            </div>
            <div className="w-full">
            <div className="relative flex flex-wrap justify-center items-start gap-7 ">
            <label>Time</label>
            <input type="time" {...register("timeinput")} className="signin-inputs w-full" />
          </div>
              </div>
         

          <button
            disabled={!isValid || loadingUpdate}
            type="submit"
           className=" submit max-w-60 mt-10"
          >
            Update
          </button>

      </form>
    </main>
  );
}

import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { Loader } from "../../../../MainComponents";
import useTable from "../../../../MainComponents/Hooks/useTable";
import toast from "react-hot-toast";

export default function AdminAddBook() {
  const navigate = useNavigate();
  const [selectedRowsCourses, setSelectedRowsCourses] = useState([]);
  const [selectedRowsBooks, setSelectedRowsBooks] = useState([]);
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [submitAddBooksAndCourse, setSubmitAddBooksAndCourse] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const booksTable = useTable(
    "books",
    "",
    "",
    submitAdd.dependency,
    "",
    "",
    "",
    "",
    "",
    "",
    setSelectedRowsBooks
  );
  const [successAddBook, errorAddBook, loadingAddBook] = useAxios(
    process.env.REACT_APP_BOOKS_ADD_API,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );

  const [
    successAddBooksAndCourse,
    errorAddBooksAndCourse,
    loadingAddBooksAndCourse,
  ] = useAxios(
    process.env.REACT_APP_BOOKS_SUBMIT_COURSES_API,
    "POST",
    submitAddBooksAndCourse.flag,
    submitAddBooksAndCourse.dependency,
    submitAddBooksAndCourse.data,
    true
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmitAddBooksWithCourses = (data) => {
    const formData = {
      books: selectedRowsBooks.map((book) => book.key),
      course: selectedRowsCourses[0].key,
    };
    setSubmitAddBooksAndCourse({
      flag: "Add Book with courses",
      dependency: !submitAddBooksAndCourse.dependency,
      data: formData,
    });
  };

  useEffect(() => {
    if (selectedRowsCourses.length > 1) {
      toast.error("Please Select only one Course");
    }
  }, [selectedRowsCourses]);
  useEffect(() => {
    if (successAddBooksAndCourse) {
      navigate(-1);
    }
  }, [successAddBooksAndCourse]);

  const onSubmit = (data) => {
    setSubmitAdd({
      flag: "Add Book",
      dependency: !submitAdd.dependency,
      data,
    });
  };

  const coursesTable = useTable(
    "courses",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    setSelectedRowsCourses
  );


  return (
    <>
      <HelmetTags title={"Add Book | Mr. Ahmed Bayoumy"} />
      <section className="flex flex-col px-10 py-10 md:px-0 w-full min-h-screen justify-center items-center">
        <form
          className=" form  shadow-3xl max-w-[600px] w-full "
          onSubmit={handleSubmit(onSubmit)}
        >
          <h4 className="text-3xl font-bold text-center w-full">
            Add New Book
          </h4>
          <div className="w-full">
            <div className="relative flex flex-col justify-center items-start gap-2 ">
              <label htmlFor="course">Book Name</label>
              <input
                type="text"
                placeholder="Add Book Name"
                className="signin-inputs w-full"
                {...register("name", {
                  required: true,
                })}
              />
            </div>
            {errors.name && (
              <p className="text-red-500 text-xl pt-4">
                {errors.name.type === "required" && "This field is required."}
              </p>
            )}
          </div>

          <button
            disabled={!isValid || loadingAddBook}
            type="submit"
            className="submit"
          >
            {loadingAddBook ? <Spin tip={"loading"} /> : "Add"}
          </button>
        </form>
        <form
          onSubmit={handleSubmit(onSubmitAddBooksWithCourses)}
          className="flex flex-col gap-10 w-full"
        >
          <div className="flex flex-col gap-10 w-full">
            <div className="flex flex-col gap-10">
              <h1 className="text-4xl text-black font-bold">Books</h1>
              {booksTable}
            </div>

            <div className="flex flex-col gap-10">
              <h1 className="text-4xl text-black font-bold">Courses</h1>
              {coursesTable}
            </div>

            <button
              disabled={
                selectedRowsBooks.length === 0 &&
                selectedRowsCourses.length === 0 &&
                selectedRowsCourses.length > 1
              }
              type="submit"
              className="submit !w-fit mx-auto !px-4 !py-3"
            >
              {loadingAddBooksAndCourse ? <Spin tip={"loading"} /> : "Add"}
            </button>
          </div>
        </form>
      </section>
    </>
  );
}

import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../../../../MainComponents";
export default function EditVideoSkills() {
  const { ID } = useParams();
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const [VideosEditData, VideosEditError, VideosLoading] = useAxios(
    `${process.env.REACT_APP_SKILLS_VIDEOS_EDIT_GET_API}/${ID}`,
    "GET",
    "GET",
    ""
  );
  const [VideoEditSuccess, VideoEditError, VideoEditLoading] = useAxios(
    process.env.REACT_APP_SKILLS_VIDEOS_UPDATE_API,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );
  const onSubmit = (data) => {
    const finalData = {
      id: ID,
      ...data,
    };
    setSubmitAdd({
      flag: "Edit Video",
      dependency: !submitAdd.dependency,
      data: finalData,
    });
  };
  if (VideosLoading) return <Loader />;
  return (
    <section className="py-10">
      <div className="flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light py-10 px-10">
        <form
          className="flex flex-col justify-center items-start bg-white p-16 rounded-3xl shadow-3xl w-3/4 my-6 gap-6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h4 className="text-3xl font-bold text-center w-full">Edit Video</h4>

          {/* Embed Video */}
          <div className="w-full">
            <div className="relative flex flex-col justify-center items-start gap-2">
              <label>Embed Video</label>
              <textarea
                defaultValue={VideosEditData?.data?.embed}
                placeholder="Enter video embed code"
                className="signin-inputs w-full py-5"
                {...register("embed", { required: true })}
              ></textarea>
            </div>
            {errors.embed && (
              <p className="text-red-500 text-xl pt-4">
                {errors.embed.type === "required" && "This field is required."}
              </p>
            )}
          </div>

          {/* Platform */}
          <div className="w-full">
            <div className="relative flex flex-col justify-center items-start gap-2">
              <label>Platform</label>
              <select
                defaultValue={VideosEditData?.data?.platform}
                className="signin-inputs w-full"
                {...register("platform", { required: true })}
              >
                <option disabled>Please Select The Platform</option>
                <option value={0}>Vdochiper</option>
                <option value={1}>YouTube</option>
              </select>
            </div>
            {errors.platform && (
              <p className="text-red-500 text-xl pt-4">
                {errors.platform.type === "required" &&
                  "This field is required."}
              </p>
            )}
          </div>

          {/* Count View After */}
          <div className="w-full">
            <div className="relative flex flex-col justify-center items-start gap-2">
              <label>Count view after (minutes)</label>
              <input
                type="number"
                min={0}
                defaultValue={VideosEditData?.data?.countview}
                placeholder="Enter count view duration"
                className="signin-inputs w-full"
                {...register("countview")}
              />
            </div>
          </div>

          {/* Views */}
          <div className="w-full">
            <div className="relative flex flex-col justify-center items-start gap-2">
              <label>Views</label>
              <input
                type="number"
                min={0}
                defaultValue={VideosEditData?.data?.views}
                placeholder="Enter view count"
                className="signin-inputs w-full"
                {...register("views", { required: true })}
              />
            </div>
            {errors.views && (
              <p className="text-red-500 text-xl pt-4">
                {errors.views.type === "required" && "This field is required."}
              </p>
            )}
          </div>

          {/* Submit Button */}
          <input
            className=" submit max-w-60 mt-10 "
            type="submit"
            value="Edit"
          />
        </form>
      </div>
    </section>
  );
}

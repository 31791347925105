import { Link } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function Courses() {
  const table = useTable("courses");
  return (
    <>
      <HelmetTags title="Courses | Mr.Ahmed Bayoumy"></HelmetTags>
      <section
        className={`h-auto w-full  dark:bg-dark dark:text-light py-16 px-20`}
      >
        <Link to={"/admin/courses/add-course"} className="w-fit px-4 py-3  duration-200 flex gap-5 items-center bg-secondary text-white hover:text-secondary hover:bg-white border border-secondary rounded-3xl">
          <FontAwesomeIcon icon={faPlus} /> Add Course
        </Link>

        <div className="h-fit  w-full py-3">{table}</div>
      </section>
    </>
  );
}

import { Col, Row } from "antd";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import Loader from "../../../../MainComponents/Loader";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function Home() {
  const [count, setCount] = useState(0);
  const [dataCountingStudents, errorCountingStudents, loadingCountingStudents] =
    useAxios(process.env.REACT_APP_ADMIN_COUNTING_STUDENTS, "GET", "GET", "");
  useEffect(() => {
    if (dataCountingStudents?.data) {
      const totalCount = dataCountingStudents.data.reduce(
        (acc, item) => acc + item.count,
        0
      );
      setCount(totalCount);
    }
  }, [dataCountingStudents]);

  if (loadingCountingStudents) {
    return <Loader />;
  }

  return (
    <>
      <HelmetTags title="Dashboard | Mr.Ahmed Bayoumy"></HelmetTags>
      <section
        className={`flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light pt-16 relative`}
      >
        <img
          src="https://api.bayoumymath.com/assets/images/tables-left-dec.png"
          alt=""
          className=" absolute top-0 left-0"
        />
        <img
          src="https://api.bayoumymath.com/assets/images/tables-right-dec.png"
          alt=""
          className="absolute top-44 right-0"
        />
        <p className="text-center text-secondary text-5xl font-bold">
          Students
        </p>
        {dataCountingStudents?.data?.length > 0 ? (
          <p className="rounded-3xl bg-secondary text-white py-2 px-4">
            {dataCountingStudents?.data?.length > 0 ? `total ${count}` : null}
          </p>
        ) : null}

        <Row gutter={[24, 24]} className="h-fit  w-full px-12 py-12">
          {dataCountingStudents?.data?.map((item, idx) => (
            <Col xs={24} sm={24} md={12} lg={8} key={idx}>
              <div className="bg-white shadow-[0px_0px_15px_rgba(0,0,0,0.1)] bg-[url(https://api.bayoumymath.com/assets/images/first-plan-bg.png)] h-[204px] bg-no-repeat gap-5 flex items-center justify-center flex-col shadow-2xl  rounded-[50px] py-5">
                <Link
                  to={`/admin/students/viewgroup/${item.id}`}
                  className="text-black text-4xl font-bold text-center max-w-[270px]"
                >
                  {item.course}
                </Link>
                <p className="text-secondary text-7xl font-bold">
                  {item.count}
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </section>
    </>
  );
}

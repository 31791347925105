import React, { useState, useRef, useReducer } from "react";

import ChatFooter from "../fabs/sections/chatFooter/ChatFooter";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useAuthState } from "../GlobalContext";

export default function useChat(refetch, setRefetch, ID, data, admin) {
  const AuthState = useAuthState();
  const stream = useRef(null);
  const recorder = useRef(null);
  const items = useRef([]);
  const counter = useRef(null);
  const seconds = useRef(null);
  const minutes = useRef(null);
  const textInput = useRef(null);
  const [uploadedImages, setUploadedImages] = useState([]);

  let api = admin
    ? "https://api.bayoumymath.com/api/admin/askquestion/answer"
    : "https://api.bayoumymath.com/api/askquestion/sendmessage";

  function reducer(state, action) {
    switch (action.type) {
      case "setIsRecording": {
        return {
          ...state,
          isRecording: action.payload,
        };
      }
      case "setIsTyping": {
        return {
          ...state,
          isTyping: action.payload,
        };
      }
      case "setIsSendingIMG": {
        return {
          ...state,
          isSendingIMG: action.payload,
        };
      }
      case "setCounter": {
        return {
          ...state,
          counter: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    isRecording: false,
    isTyping: false,
    isSendingIMG: false,
    counter: "00:01",
  });

  function handleTyping(e) {
    if (e.target.value !== "") {
      dispatch({ type: "setIsTyping", payload: true });
    } else {
      dispatch({ type: "setIsTyping", payload: false });
    }
  }

  function sendTextOrImages() {
    if (textInput.current?.value !== "") {
      let msg = textInput.current?.value;
      let finalObject = { message: msg, answer_type: "text", id: ID };

      axios
        .post(api, finalObject, {
          headers: {
            Authorization: `Bearer ${
              AuthState?.userData?.student_token ||
              AuthState?.userData?.admin_token
            }`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          toast.success("Answer sent successfully");
          setUploadedImages([]);
          setRefetch(!refetch);
        });

      textInput.current.value = "";
      textInput.current.focus();
    }

    // ... handle image uploads similar to AsksShowDetails ...
    if (uploadedImages?.length > 0) {
      uploadedImages.forEach((file, index) => {
        let formData = new FormData();
        formData.append("message", null);
        formData.append("id", ID);

        // Determine file type and set appropriate answer_type
        const fileType = file.type.split("/")[0]; // 'image' or 'video'
        formData.append("answer_type", fileType);
        formData.append("src", file);

        axios
          .post(api, formData, {
            headers: {
              Authorization: `Bearer ${
                AuthState?.userData?.student_token ||
                AuthState?.userData?.admin_token
              }`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            toast.success("Answer sent successfully");
            setUploadedImages([]);
            setRefetch(!refetch);
          });
      });
    }

    dispatch({ type: "setIsTyping", payload: false });
    dispatch({ type: "setIsSendingIMG", payload: false });
  }

  function startCounter() {
    seconds.current = 1;
    minutes.current = 0;
    let secondsWithZero;
    let minutesdWithZero;
    counter.current = setInterval(() => {
      seconds.current++;
      if (seconds.current / 60 === 1) {
        seconds.current = 0;
        minutes.current++;
        if (minutes.current === 5) {
          stopRecording();
          resetCounter();
        }
      }
      if (seconds.current < 10) {
        secondsWithZero = "0" + seconds.current.toString();
      } else {
        secondsWithZero = seconds.current;
      }

      if (minutes.current < 10) {
        minutesdWithZero = "0" + minutes.current.toString();
      } else {
        minutesdWithZero = minutes.current;
      }
      dispatch({
        type: "setCounter",
        payload: minutesdWithZero + ":" + secondsWithZero,
      });
    }, 1000);
  }

  function resetCounter() {
    dispatch({ type: "setCounter", payload: "00:01" });
    dispatch({ type: "setIsRecording", payload: false });
    clearInterval(counter.current);
    seconds.current = 1;
    minutes.current = 0;
  }

  function startRecording() {
    dispatch({ type: "setIsRecording", payload: true });

    let device = navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    device
      .then((streamobj) => {
        stream.current = streamobj;
        recorder.current = new MediaRecorder(streamobj);
        recorder.current.ondataavailable = (e) => {
          items.current.push(e.data);
        };
        recorder.current.start();
        startCounter();
      })
      .catch((Error) => {});
  }

  function stopRecording() {
    resetCounter();
    recorder.current.stop();
    recorder.current.onstop = (e) => {
      let blob = new Blob(items.current, { type: "audio/webm" });
      items.current = [];
      let audioUrl = URL.createObjectURL(blob);

      let formData = new FormData();
      formData.append("src", blob, audioUrl);
      formData.append("message", null);
      formData.append("answer_type", "voice");
      formData.append("id", ID);

      stream.current.getAudioTracks().forEach((track) => track.stop());
      stream.current = null;

      axios
        .post(api, formData, {
          headers: {
            Authorization: `Bearer ${
              AuthState?.userData?.student_token ||
              AuthState?.userData?.admin_token
            }`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          toast.success("Answer sent successfully");
          setRefetch(!refetch);
        })
        .catch((error) => {});
    };
  }

  function cancelRecording() {
    resetCounter();
    recorder.current.stop();
    recorder.current.onstop = (e) => {
      items.current = [];
      stream.current.getAudioTracks().forEach((track) => track.stop());
      stream.current = null;
    };
  }

  function handleAddImages(e) {
    setUploadedImages([...uploadedImages, ...Array.from(e.target.files)]);
    if (uploadedImages.length > 0) {
      //logic for not pushing an already exist image
      const newImages = [];
      uploadedImages.forEach((newItem) => {
        let exist = false;
        uploadedImages.forEach((oldItem) => {
          if (oldItem.name === newItem.name) {
            exist = true;
          }
        });
        if (!exist) {
          newImages.push(newItem);
        }
      });
      setUploadedImages([...uploadedImages, ...newImages]);
      setRefetch(!refetch);
    }

    if (uploadedImages?.length > 0) {
      dispatch({ type: "setIsSendingIMG", payload: true });
    } else {
      dispatch({ type: "setIsSendingIMG", payload: false });
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendTextOrImages();
    }
  };

  return (
    <div className="w-full h-full ">
      {" "}
      <div className="relative md:w-full min-h-[550px] border-4 border-secondary rounded-3xl  md:mr-0 mr-5   bg-white p-2">
        <div className="flex gap-2 items-center">
          {data?.messages?.length > 0 && (
            <div className="h-[300px] w-full overflow-y-auto">
              {data?.messages?.map((message, index) => (
                <div
                  key={index}
                  className={`flex ${
                    message.is_student ? "justify-end" : "justify-start"
                  } mb-4`}
                >
                  <div
                    className={`max-w-[70%] rounded-lg p-3 ${
                      message.is_student
                        ? "bg-secondary text-white"
                        : "bg-gray-200"
                    }`}
                  >
                    {message.answer_type === "text" && <p>{message.answer}</p>}
                    {message.answer_type === "image" && (
                      <img
                        src={message.src}
                        alt="Message"
                        className="max-w-[300px] rounded"
                      />
                    )}
                    {message.answer_type === "voice" && (
                      <audio controls>
                        <source src={message.src} />
                        Your browser does not support the audio element.
                      </audio>
                    )}
                    {message.answer_type === "video" && (
                      <video controls>
                        <source src={message.src} />
                        Your browser does not support the video element.
                      </video>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="absolute bottom-0 right-0 left-0">
          <ChatFooter
            state={state}
            stopRecording={stopRecording}
            cancelRecording={cancelRecording}
            handleAddImages={handleAddImages}
            handleTyping={handleTyping}
            textInput={textInput}
            startRecording={startRecording}
            sendTextOrImages={sendTextOrImages}
            handleKeyDown={handleKeyDown}
            uploadedImages={uploadedImages}
            setUploadedImages={setUploadedImages}
          />
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";
import { useForm } from "react-hook-form";

export default function AdminVideoSkills() {
  const { ID } = useParams();
  const [reFetch, setReFetch] = useState(false);
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const [VideosData, VideosError, VideosLoading] = useAxios(
    `${process.env.REACT_APP_SKILLS_VIDEOS_GET_API}/${ID}`,
    "GET",
    "GET",
    reFetch
  );
  const [VideoSuccess, VideoError, VideoLoading] = useAxios(
    process.env.REACT_APP_SKILLS_VIDEOS_ADD_API,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );
  const [VideoDeleteSuccess, VideoDeleteError, VideoDeleteLoading] = useAxios(
    process.env.REACT_APP_SKILLS_VIDEOS_DELETE_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );

  useEffect(() => {
    if (VideoDeleteSuccess) {
      setReFetch((prev) => !prev);
    }
  }, [VideoDeleteSuccess]); // Removed reFetch as dependency
  useEffect(() => {
    if (VideoSuccess) {
      setReFetch((prev) => !prev);
    }
  }, [VideoSuccess]); // Removed reFetch as dependency

  const onSubmit = (data) => {
    const finalData = {
      id: ID,
      ...data,
    };
    setSubmitAdd({
      flag: "Add Video",
      dependency: !submitAdd.dependency,
      data: finalData,
    });
  };

  const onSubmitDelete = (videoId) => {
    const finalData = {
      id: videoId,
    };
    setSubmitDelete({
      flag: "Delete Video",
      dependency: !submitDelete.dependency,
      data: finalData,
    });
  };

  if (VideosLoading) return <Loader />;

  return (
    <section className="w-full py-10 px-10">
      <div className="w-full flex flex-col justify-center gap-10">
        {/* Add New Video */}
        <div className="h-auto width flex flex-col items-center mt-6">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className=" flex flex-col justify-center items-start bg-white p-16  rounded-3xl shadow-3xl w-3/4 my-6 gap-6"
          >
            <h4 className="text-3xl font-bold text-center w-full">
              Add New Video
            </h4>
            <div className="w-full">
              <div className="relative flex flex-col justify-center items-start gap-2 ">
                <label>Embed</label>
                <textarea
                  className="signin-inputs w-full py-3"
                  {...register("embed", { required: true })}
                ></textarea>
                {errors.embed && (
                  <p className="text-red-500 text-xl pt-4">
                    {errors.embed.type === "required" &&
                      "This field is required."}
                  </p>
                )}
              </div>
            </div>
            <div className="w-full">
              <div className="relative flex flex-col justify-center items-start gap-2 ">
                <label>Platform</label>
                <select
                  className="signin-inputs w-full"
                  {...register("platform", { required: true })}
                >
                  <option disabled>Please Select The Platform</option>
                  <option value={0}>Vdochiper</option>
                  <option value={1}>Youtube</option>
                </select>
                {errors.platform && (
                  <p className="text-red-500 text-xl pt-4">
                    {errors.platform.type === "required" &&
                      "This field is required."}
                  </p>
                )}
              </div>
            </div>
            <div className="w-full">
              <div className="relative flex flex-col justify-center items-start gap-2 ">
                <label>Count view after (minutes)</label>
                <input
                  defaultValue={0}
                  min={0}
                  className="signin-inputs w-full"
                  type="number"
                  {...register("countview", { required: true })}
                />
                 {errors.countview && (
                  <p className="text-red-500 text-xl pt-4">
                    {errors.countview.type === "required" &&
                      "This field is required."}
                  </p>
                )}
              </div>
            </div>
            <div className="w-full">
              <div className="flex flex-col gap-4 pt-5 w-full">
                <label>Views</label>
                <input
                  min={0}
                  defaultValue={0}
                  className="signin-inputs w-full"
                  type="number"
                  {...register("views", { required: true })}
                />
                 {errors.views && (
                  <p className="text-red-500 text-xl pt-4">
                    {errors.views.type === "required" &&
                      "This field is required."}
                  </p>
                )}
              </div>
            </div>

            <button className=" submit max-w-60 mt-10 " type="submit">
              Add
            </button>
          </form>
        </div>

        <div className="flex gap-10 flex-wrap justify-center pt-10">
          {VideosData?.videos?.map((item, idx) => (
            <div
              key={idx}
              className="flex flex-col gap-10 bg-white rounded-3xl shadow-3xl  hover:scale-105 duration-200 max-w-[312px] w-full py-3 relative"
            >
              <p className="px-4 py-3 rounded-lg bg-blue-600 text-white absolute top-5 right-5">
                {item.duration}
              </p>
              <img
                src={item.poster}
                alt={item.title}
                className="w-full h-[250px] bg-black"
              />
              <Link
                to={`/admin/skills/video/${item.id}`}
                className="text-blue-600 text-wrap text-3xl hover:text-blue-500 px-2"
              >
                {item.title}
              </Link>
              <div className="flex gap-5 items-center px-3">
                <Link
                  to={`/admin/skills/video/edit/${item.id}`}
                  className="bg-blue-600 text-white border border-blue-600 px-4 py-3 hover:bg-white hover:text-blue-600 duration-200"
                >
                  Edit
                </Link>
                <button
                  type="button"
                  onClick={() => onSubmitDelete(item.id)}
                  className="bg-red-600 text-white border border-red-600 px-4 py-3 hover:bg-red-600/50 hover:text-white"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import Loader from "../../../../MainComponents/Loader.jsx";
import { useForm } from "react-hook-form";

function QuestionBankCategory({
  type,
  QuizQuestionsData,
  refetchQuiz,
  setRefetchQuiz,
  id,
}) {
  const Modal = useRef(null);
  const {
    handleSubmit,
    reset,
    register,
    formState: { isValid },
  } = useForm();
  const AuthState = useAuthState();
  const [deleteId, setdeleteId] = useState("");
  const [sendQuestionKey, setSendQuestionKey] = useState([]);
  const allQuestions = useRef(null);
  const [reFetch, setReFetch] = useState(false);
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [submitShortcut, setSubmitShortcut] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const navigate = useNavigate();
  const { categoryID } = useParams();

  //!--------- get all Questions -------
  let api = "";

  if (categoryID) {
    api =
      type === "assignments"
        ? `${process.env.REACT_APP_ASSIGNMENT_GET_QUESTIONS_API}${categoryID}`
        : `${process.env.REACT_APP_SKILLS_QUESTIONS_GET_API}${categoryID}`;
  }

  const [allQuestionsData, allQuestionsErrors, loading] = useAxios(
    api,
    "GET",
    "GET",
    reFetch // Only trigger if `api` is non-empty and `reFetch` is true
  );

  //!--------- get Assigment Questions -------

  //!--------- delete Question -------
  let deleteApi =
    type === "assignments"
      ? process.env.REACT_APP_ASSIGNMENT_DELETE_QUESTIONS_ESSAY_API
      : process.env.REACT_APP_DELETE_QUESTION_API;

  const [deleteQuestionSuccess] = useAxios(
    deleteApi,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );

  const [allAssignments, errorAssignmets, loadingAssignemnts] = useAxios(
    process.env.REACT_APP_ASSIGNMENT_GET_API,
    "GET",
    "GET",
    ""
  );
  const [ShortCutsuccess, errorShortCut, loadingShortCut] = useAxios(
    "https://api.bayoumymath.com/api/admin/assignments/copyessayquestions",
    "POST",
    submitShortcut.flag,
    submitShortcut.dependency,
    submitShortcut.data,
    true
  );

  const onSubmit = (data) => {
    setSubmitShortcut({
      flag: "submitShortcut",
      dependency: !submitShortcut.dependency,
      data: {
        essay: sendQuestionKey,
        quiz_id: data.assignments,
      },
    });
  };
  /* useEffect(() => {
    //!

    if ( allQuestions) {
      displayQuestions();
  //console.log("allQuestionsallQuestionsallQuestions");
    }
  }, [ allQuestions]); */

  useEffect(() => {
    if ((QuizQuestionsData?.length > 0 || allQuestionsData) && allQuestions) {
      displayQuestions();
      displayEssay();
    }
  }, [allQuestionsData, QuizQuestionsData, allQuestions]);

  useEffect(() => {
    //! refetch the data on success delete Question

    if (deleteQuestionSuccess) {
      type ? setRefetchQuiz(!refetchQuiz) : setReFetch(!reFetch);
      setdeleteId("");
    }
  }, [deleteQuestionSuccess]);

  function handleDelete() {
    setSubmitDelete({
      flag: "deleteQuestion",
      dependency: !submitDelete.dependency,
      data: { id: deleteId },
    });
  }

  function displayQuestions() {
    const finalData =
      QuizQuestionsData?.length > 0
        ? QuizQuestionsData
        : type === "assignments"
          ? allQuestionsData?.data?.questions
          : allQuestionsData?.data;

    finalData?.forEach((ques) => {
      //!--create containers
      let singleQuestionWrapper = document.createElement("div");
      singleQuestionWrapper.classList.add("singleQuestionWrapper");
      //!-----------
      let optionsQuestionWrapper = document.createElement("div");
      optionsQuestionWrapper.classList.add("optionsQuestionWrapper");
      //!-----------
      let questionOptions = document.createElement("div");
      questionOptions.classList.add("questionOptions");
      //!----------- delete btn
      let deleteQuestionBtn = document.createElement("button");
      deleteQuestionBtn.classList.add("deleteQuestionBtn");
      deleteQuestionBtn.innerText = "Delete";
      const selectDialog = document.getElementById("dialog");
      deleteQuestionBtn.addEventListener("click", () => {
        Modal.current.classList.remove("hide");
        setdeleteId(ques.key);
      });
      //!----------- delete btn
      let videoQuestionBtn = document.createElement("button");
      videoQuestionBtn.classList.add("videoQuestionBtn");
      videoQuestionBtn.innerText = "Video";

      videoQuestionBtn.addEventListener("click", () => {
        if (ques.is_essay) {
          navigate(`/admin/video/${ques.key}/essay`);
        } else {
          navigate(`/admin/video/${ques.key}/choose`);
        }
      });
      //!----------- edit btn
      let editQuestionBtn = document.createElement("button");
      editQuestionBtn.classList.add("editQuestionBtn");
      editQuestionBtn.innerText = "Edit";
      editQuestionBtn.addEventListener("click", () => {
        if (ques.is_essay) {
          if (type === "homework") {
            navigate(
              `/admin/lectures/sessions/homework/edit-essay-question/${ques.key}`,
              {
                state: { lectureQuestion: true },
              }
            );
          } else if (type === "quiz") {
            navigate(
              `/admin/lectures/sessions/quiz/edit-essay-question/${ques.key}`,
              {
                state: { lectureQuestion: true },
              }
            );
          } else {
            navigate(
              `/admin/question-bank/${categoryID}/edit-essay-question/${ques.key}`
            );
          }
        } else {
          if (type === "homework") {
            navigate(
              `/admin/lectures/sessions/homework/edit-choose-question/${ques.key}`,
              {
                state: { lectureQuestion: true },
              }
            );
          } else if (type === "quiz") {
            navigate(
              `/admin/lectures/sessions/quiz/edit-choose-question/${ques.key}`,
              {
                state: { lectureQuestion: true },
              }
            );
          } else {
            navigate(
              `/admin/question-bank/${categoryID}/edit-choose-question/${ques.key}`
            );
          }
        }
      });
      let skillsQuestionBtn = document.createElement("button");
      skillsQuestionBtn.classList.add("skillsQuestionBtn");
      skillsQuestionBtn.innerText = "Skills";
      skillsQuestionBtn.addEventListener("click", () => {
        if (ques.is_essay) {
          navigate(`/admin/question-bank/skills/${ques.key}`);
        } else {
          navigate(`/admin/question-bank/skills/${ques.key}`);
        }
      });
      //!----------- edit delete permissions

      if (
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission?.name.startsWith("questionbank_deletequestion")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission?.name.startsWith("lecture_addquiz")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission?.name.startsWith("lecture_addHw")
        )
      ) {
        questionOptions.append(deleteQuestionBtn);
      }
      if (
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_editquestion")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addquiz")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addHw")
        )
      ) {
        questionOptions.append(editQuestionBtn);
      }
      if (
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_editquestion")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addquiz")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addHw")
        )
      ) {
        questionOptions.append(videoQuestionBtn);
      }
      if (
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_editquestion")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addquiz")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addHw")
        )
      ) {
        questionOptions.append(skillsQuestionBtn);
      }
      //!----------- categoryQuestionWrapper
      let categoryQuestionWrapper = document.createElement("div");
      categoryQuestionWrapper.classList.add("categoryQuestionWrapper");
      let questionTitle = document.createElement("p");
      questionTitle.innerText = `Question No. ${ques.n}`;
      let checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.addEventListener("change", () => {
        if (checkbox.checked) {
          setSendQuestionKey((prevKeys) => [...prevKeys, ques.key]);
        } else {
          setSendQuestionKey((prevKeys) =>
            prevKeys.filter((key) => key !== ques.key)
          );
        }
      });

      let essayQuestion = document.createElement("span");
      essayQuestion.innerText = "Essay question";
      let essayTitleWrapper = document.createElement("div");
      /* if (!type) {
        let questionDifficulty = document.createElement("h4");
        questionDifficulty.innerText = `${ques.difficulty}`;
        essayTitleWrapper.append(questionDifficulty);
      } */
      if (ques.is_essay) {
        essayTitleWrapper.append(essayQuestion);
      }
      essayTitleWrapper.append(checkbox);
      essayTitleWrapper.append(questionTitle);
      essayTitleWrapper.classList.add("essayTitleWrapper");
      let categoryQuestion = document.createElement("div");
      categoryQuestion.innerHTML = `${ques.q}`;
      //!--remove the first child sent by the text editor
      /*   if (categoryQuestion.childNodes.length > 0) {
        categoryQuestion.removeChild(categoryQuestion.firstChild);
      } */
      categoryQuestionWrapper.append(essayTitleWrapper, categoryQuestion);
      //!-----------
      //!--append the options and the question to their parent div
      optionsQuestionWrapper.append(questionOptions, categoryQuestionWrapper);
      let categoryAnswersWrapper = document.createElement("div");
      categoryAnswersWrapper.classList.add("categoryAnswersWrapper");

      //!--append the answers to the answers div and check if it is not essay so it has answers
      if (!ques.is_essay) {
        let answer1Wrapper = document.createElement("div");
        answer1Wrapper.classList.add("categorySingleAnswer", "answer1Wrapper");
        if (ques.correct_answer === "answer1") {
          answer1Wrapper.classList.add("correctSingleAnswer");
        }
        let answer1Title = document.createElement("p");
        answer1Title.innerText = "First answer";
        let answer1 = document.createElement("div");
        answer1.innerHTML = `${ques.answer1}`;
        /*       answer1.removeChild(answer1.firstElementChild); */
        answer1Wrapper.append(answer1Title, answer1);
        //!----------
        let answer2Wrapper = document.createElement("div");
        answer2Wrapper.classList.add("categorySingleAnswer", "answer2Wrapper");
        if (ques.correct_answer === "answer2") {
          answer2Wrapper.classList.add("correctSingleAnswer");
        }
        let answer2Title = document.createElement("p");
        answer2Title.innerText = "Second answer";
        let answer2 = document.createElement("div");
        answer2.innerHTML = `${ques.answer2}`;
        /*   answer2.removeChild(answer2.firstElementChild); */
        answer2Wrapper.append(answer2Title, answer2);
        //!----------
        let answer3Wrapper = document.createElement("div");
        answer3Wrapper.classList.add("categorySingleAnswer", "answer3Wrapper");
        if (ques.correct_answer === "answer3") {
          answer3Wrapper.classList.add("correctSingleAnswer");
        }

        let answer3Title = document.createElement("p");
        answer3Title.innerText = "Third answer";
        let answer3 = document.createElement("div");
        answer3.innerHTML = `${ques.answer3}`;
        /* answer3.removeChild(answer3.firstElementChild); */
        answer3Wrapper.append(answer3Title, answer3);
        //!----------
        let answer4Wrapper = document.createElement("div");
        answer4Wrapper.classList.add("categorySingleAnswer", "answer4Wrapper");
        if (ques.correct_answer === "answer4") {
          answer4Wrapper.classList.add("correctSingleAnswer");
        }
        let answer4Title = document.createElement("p");
        answer4Title.innerText = "Fourth answer";
        let answer4 = document.createElement("div");
        answer4.innerHTML = `${ques.answer4}`;
        /*  answer4.removeChild(answer4.firstElementChild); */
        answer4Wrapper.append(answer4Title, answer4);

        let explanationWrapper = document.createElement("div");
        explanationWrapper.classList.add("aa", "explanationWrapper");

        let explanationTitle = document.createElement("p");
        explanationTitle.innerText = "explanation";
        let explanation = document.createElement("div");
        explanation.innerHTML = `${ques.explanation}`;
        /*  explanation.removeChild(explanation.firstElementChild); */
        explanationWrapper.append(explanationTitle, explanation);
        //!----------
        categoryAnswersWrapper.append(
          answer2Wrapper,
          answer1Wrapper,
          answer4Wrapper,
          answer3Wrapper,
          explanationWrapper
        );
      }

      //!--append the children to the parents
      singleQuestionWrapper.append(optionsQuestionWrapper);
      if (!ques.is_essay) {
        singleQuestionWrapper.append(categoryAnswersWrapper);
      }
      allQuestions?.current?.appendChild(singleQuestionWrapper);
    });
  }

  function displayEssay() {
    const finalData =
      QuizQuestionsData?.length > 0
        ? QuizQuestionsData
        : type === "assignments"
          ? allQuestionsData?.data?.essay_questions
          : /* allQuestionsData?.data */ null;

    finalData?.forEach((ques) => {
      //!--create containers
      let singleQuestionWrapper = document.createElement("div");
      singleQuestionWrapper.classList.add("singleQuestionWrapper");
      //!-----------
      let optionsQuestionWrapper = document.createElement("div");
      optionsQuestionWrapper.classList.add("optionsQuestionWrapper");
      //!-----------
      let questionOptions = document.createElement("div");
      questionOptions.classList.add("questionOptions");
      //!----------- delete btn
      let deleteQuestionBtn = document.createElement("button");
      deleteQuestionBtn.classList.add("deleteQuestionBtn");
      deleteQuestionBtn.innerText = "Delete";
      const selectDialog = document.getElementById("dialog");
      deleteQuestionBtn.addEventListener("click", () => {
        Modal.current.classList.remove("hide");
        setdeleteId(ques.key);
      });
      let checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.addEventListener("change", () => {
        if (checkbox.checked) {
          setSendQuestionKey((prevKeys) => [...prevKeys, ques.key]);
        } else {
          setSendQuestionKey((prevKeys) =>
            prevKeys.filter((key) => key !== ques.key)
          );
        }
      });
      let videoQuestionBtn = document.createElement("button");
      videoQuestionBtn.classList.add("videoQuestionBtn");
      videoQuestionBtn.innerText = "Video";

      videoQuestionBtn.addEventListener("click", () => {
        if (ques.is_essay) {
          navigate(`/admin/video/${ques.key}/essay`);
        } else {
          navigate(`/admin/video/${ques.key}/choose`);
        }
      });
      //!----------- edit btn
      let editQuestionBtn = document.createElement("button");
      editQuestionBtn.classList.add("editQuestionBtn");
      editQuestionBtn.innerText = "Edit";
      editQuestionBtn.addEventListener("click", () => {
        if (ques.is_essay) {
          if (type === "homework") {
            navigate(
              `/admin/lectures/sessions/homework/edit-essay-question/${ques.key}`,
              {
                state: { lectureQuestion: true },
              }
            );
          } else if (type === "quiz") {
            navigate(
              `/admin/lectures/sessions/quiz/edit-essay-question/${ques.key}`,
              {
                state: { lectureQuestion: true },
              }
            );
          } else {
            navigate(
              `/admin/question-bank/${categoryID}/edit-essay-question/${ques.key}`
            );
          }
        } else {
          if (type === "homework") {
            navigate(
              `/admin/lectures/sessions/homework/edit-choose-question/${ques.key}`,
              {
                state: { lectureQuestion: true },
              }
            );
          } else if (type === "quiz") {
            navigate(
              `/admin/lectures/sessions/quiz/edit-choose-question/${ques.key}`,
              {
                state: { lectureQuestion: true },
              }
            );
          } else {
            navigate(
              `/admin/question-bank/${categoryID}/edit-choose-question/${ques.key}`
            );
          }
        }
      });
      let skillsQuestionBtn = document.createElement("button");
      skillsQuestionBtn.classList.add("skillsQuestionBtn");
      skillsQuestionBtn.innerText = "Skills";
      skillsQuestionBtn.addEventListener("click", () => {
        if (ques.is_essay) {
          navigate(`/admin/question-bank/skills/${ques.key}`);
        } else {
          navigate(`/admin/question-bank/skills/${ques.key}`);
        }
      });

      //!----------- edit delete permissions

      if (
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission?.name.startsWith("questionbank_deletequestion")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission?.name.startsWith("lecture_addquiz")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission?.name.startsWith("lecture_addHw")
        )
      ) {
        questionOptions.append(deleteQuestionBtn);
      }
      if (
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_editquestion")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addquiz")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addHw")
        )
      ) {
        questionOptions.append(editQuestionBtn);
      }
      if (
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_editquestion")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addquiz")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addHw")
        )
      ) {
        questionOptions.append(skillsQuestionBtn);
      }
      if (
        AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_editquestion")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addquiz")
        ) ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_addHw")
        )
      ) {
        questionOptions.append(videoQuestionBtn);
      }
      //!----------- categoryQuestionWrapper
      let categoryQuestionWrapper = document.createElement("div");
      categoryQuestionWrapper.classList.add("categoryQuestionWrapper");
      let questionTitle = document.createElement("p");
      questionTitle.innerText = `Question No. ${ques.n}`;
      let essayQuestion = document.createElement("span");
      essayQuestion.innerText = "Essay question";
      let essayTitleWrapper = document.createElement("div");
      /* if (!type) {
        let questionDifficulty = document.createElement("h4");
        questionDifficulty.innerText = `${ques.difficulty}`;
        essayTitleWrapper.append(questionDifficulty);
      } */
      /*   if (ques.is_essay) {
        essayTitleWrapper.append(essayQuestion);
      } */
      essayTitleWrapper.append(checkbox);
      essayTitleWrapper.append(questionTitle);
      essayTitleWrapper.classList.add("essayTitleWrapper");
      let categoryQuestion = document.createElement("div");
      categoryQuestion.innerHTML = `${ques.q}`;
      //!--remove the first child sent by the text editor
      /*   if (categoryQuestion.childNodes.length > 0) {
        categoryQuestion.removeChild(categoryQuestion.firstChild);
      } */
      categoryQuestionWrapper.append(essayTitleWrapper, categoryQuestion);
      //!-----------
      //!--append the options and the question to their parent div
      optionsQuestionWrapper.append(questionOptions, categoryQuestionWrapper);
      let categoryAnswersWrapper = document.createElement("div");
      categoryAnswersWrapper.classList.add("categoryAnswersWrapper");

      //!--append the answers to the answers div and check if it is not essay so it has answers
      if (!ques.is_essay) {
        let answer1Wrapper = document.createElement("div");

        let answer1Title = document.createElement("p");
        answer1Title.innerText = "Answer";
        let answer1 = document.createElement("div");
        answer1.innerHTML = `${ques.answers[0]?.answer}`;
        /*       answer1.removeChild(answer1.firstElementChild); */
        answer1Wrapper.append(answer1Title, answer1);

        let explanationWrapper = document.createElement("div");
        explanationWrapper.classList.add("aa", "explanationWrapper");

        let explanationTitle = document.createElement("p");
        explanationTitle.innerText = "explanation";
        let explanation = document.createElement("div");
        explanation.innerHTML = `${ques.explanation}`;
        /*  explanation.removeChild(explanation.firstElementChild); */
        explanationWrapper.append(explanationTitle, explanation);
        //!----------
        categoryAnswersWrapper.append(
          answer1Wrapper,

          explanationWrapper
        );
      }

      //!--append the children to the parents
      singleQuestionWrapper.append(optionsQuestionWrapper);
      if (!ques.is_essay) {
        singleQuestionWrapper.append(categoryAnswersWrapper);
      }
      allQuestions?.current?.appendChild(singleQuestionWrapper);
    });
  }

  if (loading) {
    return <Loader />;
  }
  return (
    <section
      className={`h-full w-full overflow-y-auto ${
        !type && "px-20"
      }  py-20 dark:bg-dark dark:text-light sm:px-10`}
    >
      {!type && (
        <HelmetTags title="Question Bank | Mr Ahmed Bayoumy"></HelmetTags>
      )}
      <div
        ref={Modal}
        className="modal hide absolute bottom-1/2 right-1/2  w-80 translate-x-1/2  translate-y-1/2 flex-col items-center gap-4 rounded-2xl bg-blue-200 p-4"
      >
        <p className="mb-4 w-full text-center">Confirm Delete?</p>
        <div className="flex items-center justify-center gap-5">
          <button
            onClick={() => {
              Modal.current.classList.add("hide");
              setdeleteId("");
            }}
            className="closeModal "
          >
            الغاء
          </button>
          <button onClick={handleDelete} className="confirmModal">
            تأكيد
          </button>
        </div>
      </div>

      {!type ? (
        <>
          <h2 className="mb-16 w-full text-start text-4xl font-bold sm:text-center">
            Category Questions {allQuestionsData?.name}
          </h2>
          <div className="flex py-10 gap-5 px-10">
            <Link
              to={`/admin/skills/edit-choose-question/${categoryID}`}
              className="bg-yellow-400 px-4 py-3 rounded-3xl text-black hover:bg-yellow-400/50"
            >
              Add a question
            </Link>
            <Link
              to={`/admin/skills/edit-choose-essay/${categoryID}`}
              className="bg-yellow-400 px-4 py-3 rounded-3xl text-black hover:bg-yellow-400/50"
            >
              Add a essay
            </Link>
          </div>
        </>
      ) : (
        <div className="flex py-10 gap-5 px-10">
          <Link
            to={`/admin/${
              type === "assignments" ? "assignments" : "skills"
            }/edit-choose-question/${categoryID}`}
            className="bg-yellow-400 px-4 py-3 rounded-3xl text-black hover:bg-yellow-400/50"
          >
            Add a question
          </Link>
          <Link
            to={`/admin/${
              type === "assignments" ? "assignments" : "skills"
            }/edit-choose-essay/${categoryID}`}
            className="bg-yellow-400 px-4 py-3 rounded-3xl text-black hover:bg-yellow-400/50"
          >
            Add a essay
          </Link>
        </div>
      )}

      <div
        ref={allQuestions}
        className="all-questions flex h-auto w-full flex-col gap-14 px-10"
      >
        {!type &&
          allQuestionsErrors &&
          typeof allQuestionsErrors === "string" && (
            <p className="w-full text-center">{allQuestionsErrors}</p>
          )}
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form shadow-3xl !w-3/4 "
      >
        <div className="w-full space-y-4">
          {" "}
          <label htmlFor="category">Assignments</label>
          <select
            id="category"
            className="signin-inputs"
            {...register("assignments", { required: true })}
          >
            {allAssignments?.data?.map((item) => (
              <option value={item.key}>{item.name}</option>
            ))}
          </select>
        </div>

        <button className="submit border border-secondary" disabled={!isValid}>
          Shortcut
        </button>
      </form>
    </section>
  );
}

export default QuestionBankCategory;

import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../../../../MainComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
export default function WhatsappMessage() {
  const { studentID, CourseNumber } = useParams();
  const [studentName, setStudentName] = useState("");
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [successAddMessage, errorAddMessage, loadingAddMessage] = useAxios(
    `${process.env.REACT_APP_BASE_API}api/admin/students/whatsapp/${studentID}`,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );
  const [getStudent, errorStudent, loadingStudent] = useAxios(
    `${process.env.REACT_APP_STUDENTS_VIEWGROUP_API}/${CourseNumber}`,
    "GET",
    "GET",
    ""
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  useEffect(() => {
    if (!loadingStudent && getStudent) {
      const filterStudent = getStudent?.data?.find(
        (e) => e.key === parseInt(studentID)
      );
      setStudentName(filterStudent?.name || "");
    }
  }, [loadingStudent, getStudent, studentID]);

  const onSubmit = (data) => {
    const finalData = {
      student_id: studentID,
      message: data.message,
    };
    setSubmitAdd({
      flag: "Add Assigment",
      dependency: !submitAdd.dependency,
      data,
    });
  };

  if (loadingStudent) return <Loader />;
  return (
    <main className="py-10 px-3 flex flex-col items-center justify-center w-full h-screen  gap-10 ">
      <div className="h-auto width flex flex-col items-center mt-6 gap-10">
        {!loadingStudent && (
          <h1 className="text-3xl font-bold">Student Name: {studentName}</h1>
        )}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col justify-center items-start bg-white p-16  rounded-3xl shadow-3xl w-3/4 my-6 gap-10"
        >
          <label className="text-black text-3xl ">Message</label>
          <textarea
          placeholder="write a message"
            {...register("message", { required: true })}
            className="signin-inputs w-full py-5 "
          ></textarea>
          {errors.message && <p className="text-lg text-bold">the field is required</p>}
          {!loadingAddMessage ? (
            <button
              type="sumbit"
              className="submit w-full"
            >
              Send Message To Whatsapp
            </button>
          ) : (
            <button
              disabled
              className="submit w-full"
            >
              <FontAwesomeIcon icon={faSpinner} /> loading
            </button>
          )}
         
        </form>
      </div>
    </main>
  );
}

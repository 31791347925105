import { useParams } from "react-router-dom";
import useTable from "../../../../MainComponents/Hooks/useTable";

export default function ResultSkills() {
  const {ID} = useParams();
  const table = useTable("resultskills","","","","",ID);
  return (
    <main className="py-10 px-3">
      <div>{table}</div>
    </main>
  );
}

import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalculator,
  faChevronRight,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";

import { useState } from "react";
import QuizTimer from "./QuizTimer";

const QuizHeader = ({
  state,
  dispatch,
  quizErrors,
  onFinishQuiz,
  quizLoading,
  exam,
  quizData,
}) => {
  const { ID } = useParams();
  const [hide, setHide] = useState();
  const [remainingTime, setRemainingTime] = useState(null);


  return (
    <div>
      <header
        className={`w-full px-10  ${
          state.isShowAnswers ? "h-[80px]" : "h-[120px]"
        } fixed inset-0 bg-white z-[999] flex  items-center  w-full border-b-2 border-b-[hsl(217,19%,27%)] border-dashed  justify-between`}
      >
        <p className="text-[#374151] font-bold whitespace-nowrap">
          {quizData?.exam?.name}
        </p>

        {/*           <div className="header-above  h-[32px] w-fit">
            <h4 className="exam-title font-bold">{state?.quizInfo?.title}</h4>
          <div> */}
        {quizData?.exam?.duration && (
          <QuizTimer
            duration={quizData?.exam?.duration}
            state={state}
            onFinishQuiz={onFinishQuiz}
            setRemainingTime={setRemainingTime}
          />
        )}

        {/*  {state.isShowAnswers && (
            <Link to={`${exam ? `/exams/${ID}` : `/lectures/sessions/${ID}`}`}>
              <div className="collapse-btn top-[96px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-accent text-light shadow-md shadow-black/40 duration-300 hover:scale-110 hover:text-light">
                <FontAwesomeIcon
                  className={`transition-all duration-300 ease-in-out hover:scale-110 ${
                    state.collapse && "rotate-180"
                  }`}
                  icon={faChevronRight}
                />
              </div>
            </Link>
          )} */}
        {/*    <div
            className={`header-underneath  flex w-full items-center justify-between gap-[20px] ${
              state.isShowAnswers && "pointer-events-none hidden"
            }`}
          >
            <div className="flex w-1/2 justify-end md:w-fit">
              {!quizErrors && !quizLoading && (
                <div
                  onClick={() => dispatch({ type: "setCollapse" })}
                  className="collapse-btn hover:bg-   top-[96px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-accent text-light shadow-md  shadow-black/40 duration-300 hover:scale-110 hover:text-light"
                >
                  <FontAwesomeIcon
                    className={`transition-all duration-300 ease-in-out hover:scale-110 ${
                      state.collapse && "rotate-180"
                    }`}
                    icon={faChevronRight}
                  />
                </div>
              )}
            </div> 
          </div> */}
        <div className="flex gap-20 items-center">
          <li className="list-none md:hidden flex flex-col gap-5 items-center">
            <a
              href="https://www.desmos.com/calculator"
              target="_blank"
              className=" list-none"
            >
              <FontAwesomeIcon
                icon={faCalculator}
                className="text-5xl text-black"
              />
            </a>
            <p className="text-[#374151]">Calculator</p>
          </li>
          <div className="relative">
            <button className="text-[#374151]" onClick={() => setHide(!hide)}>
              <FontAwesomeIcon icon={faEllipsis} />
            </button>
            {hide && (
              <button
                onClick={() => onFinishQuiz(remainingTime)}
                disabled={state.answeredQuestions.length >= 1 ? false : true}
                className="absolute top-20 right-5 submit-btn rounded-md border-[#374151] bg-[#374151] px-[16px]  py-[8px]  font-bold text-white duration-200 hover:scale-110 active:scale-90 "
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

export default QuizHeader;

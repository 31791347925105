import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useState, useEffect } from "react";
import { Table, Checkbox } from "antd";
import { useParams } from "react-router-dom";
import { Loader } from "../../../../MainComponents";

export default function AddEditAssignment({ edit }) {
  const { assignmentID } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [getCourses] = useAxios(
    process.env.REACT_APP_ADMIN_GET_COURSES_API,
    "GET",
    "GET",
    ""
  );

  const [getInfoAssigment, errorInfoAssigment, loadingInfoAssigment] = useAxios(
    `https://api.bayoumymath.com/api/admin/assignments/info/${assignmentID}`,
    "GET",
    edit,
    edit
  );
  let api = edit
    ? process.env.REACT_APP_ASSIGNMENT_UPDATE_ASSIGNMENTS_API
    : process.env.REACT_APP_ADMIN_ADD_ASSIGMNETS_API;
  const [successData, errorData, loading] = useAxios(
    api,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );

  useEffect(() => {
    if (getCourses && getCourses.data) {
      setCourses(getCourses.data);
    }
  }, [getCourses]);

  useEffect(() => {
    if (getInfoAssigment && getInfoAssigment.data) {
      const {
        name,
        description,
        duration,
        courses,
        visability,
        v_model,
        is_dur,
      } = getInfoAssigment.data;
      setValue("name", name);
      setValue("description", description);
      setValue("duration", duration);
      setValue("visability", visability);
      setValue("v_model", v_model);
      setValue("is_dur", is_dur);
      setSelectedCourses(courses.map((course) => course.id));
    }
  }, [getInfoAssigment, setValue]);

  const onSubmit = (data) => {
    const finalData = {
      id: assignmentID,
      courses: selectedCourses,
      ...data,
      visability: data.visability ? 1 : 0,
      v_model: data.v_model ? 1 : 0,
      is_dur: data.is_dur ? 1 : 0,
    };
    setSubmitAdd({
      flag: "Add Assignment",
      dependency: !submitAdd.dependency,
      data: finalData,
    });
  };

  const handleCourseChange = (courseId, checked) => {
    if (checked) {
      setSelectedCourses([...selectedCourses, courseId]);
    } else {
      setSelectedCourses(selectedCourses.filter((id) => id !== courseId));
    }
  };
  if (loadingInfoAssigment) return <Loader />;
  return (
    <div className="w-full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form shadow-3xl h-full max-w-[600px] !gap-10"
      >
        <div className="w-full">
          <label htmlFor="name" className="block mb-1">
            Name
          </label>
          <input
            id="name"
            type="text"
            {...register("name", { required: "Name is required" })}
            className="signin-inputs text-black"
          />
          {errors.name && (
            <span className="text-red-500">{errors.name.message}</span>
          )}
        </div>

        <div className="w-full">
          <label htmlFor="description" className="block mb-1">
            Description
          </label>
          <textarea
            id="description"
            {...register("description", {
              required: "Description is required",
            })}
            className="signin-inputs text-black"
          />
          {errors.description && (
            <span className="text-red-500">{errors.description.message}</span>
          )}
        </div>

        <div className="w-full">
          <label htmlFor="duration" className="block mb-1">
            Duration (in minutes)
          </label>
          <input
            id="duration"
            type="number"
            {...register("duration", {
              required: "Duration is required",
              min: 1,
            })}
            className="signin-inputs text-black"
          />
          {errors.duration && (
            <span className="text-red-500">{errors.duration.message}</span>
          )}
        </div>
        <div className="py-10 flex justify-between items-start w-full flex-wrap gap-5">
          <div className=" visibility flex w-full flex-col items-end justify-center gap-2 md:w-full">
            <div className="flex w-full items-center justify-end gap-10">
              <label className="visibility-switch">
                <input
                  className=""
                  id="visibility"
                  name="visibility"
                  {...register("visability", {
                    required: false,
                  })}
                  type="checkbox"
                />
                <span className="visibility-slider">
                  <svg
                    className="slider-icon"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path fill="none" d="m4 16.5 8 8 16-16"></path>
                  </svg>
                </span>
              </label>
              <div>
                <label
                  className="w-full cursor-pointer truncate"
                  htmlFor="Enable"
                >
                  Visibility
                </label>
              </div>
            </div>
          </div>
          <div className=" visibility flex w-full flex-col items-end justify-center gap-2 md:w-full">
            <div className="flex w-full items-center justify-end gap-10">
              <label className="visibility-switch">
                <input
                  className=""
                  id="visibility"
                  name="visibility"
                  {...register("v_model", {
                    required: false,
                  })}
                  type="checkbox"
                />
                <span className="visibility-slider">
                  <svg
                    className="slider-icon"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path fill="none" d="m4 16.5 8 8 16-16"></path>
                  </svg>
                </span>
              </label>
              <div>
                <label
                  className="w-full cursor-pointer truncate"
                  htmlFor="Enable"
                >
                  Model Answer
                </label>
              </div>
            </div>
          </div>
          <div className=" visibility flex w-full flex-col items-end justify-center gap-2 md:w-full">
            <div className="flex w-full items-center justify-end gap-10">
              <label className="visibility-switch">
                <input
                  className=""
                  id="visibility"
                  name="visibility"
                  {...register("is_dur", {
                    required: false,
                  })}
                  type="checkbox"
                />
                <span className="visibility-slider">
                  <svg
                    className="slider-icon"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path fill="none" d="m4 16.5 8 8 16-16"></path>
                  </svg>
                </span>
              </label>
              <div>
                <label
                  className="w-full cursor-pointer truncate"
                  htmlFor="Enable"
                >
                  Student Control of Time
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full py-10">
          <div className="">
            <Table
              dataSource={courses}
              columns={[
                {
                  title: "",
                  key: "",
                  width: 40,
                  render: (text, record) => (
                    <Checkbox
                      value={record.key}
                      onChange={(e) =>
                        handleCourseChange(record.key, e.target.checked)
                      }
                      checked={selectedCourses.includes(record.key)}
                    ></Checkbox>
                  ),
                },
                {
                  title: "Course Name",
                  dataIndex: "name",
                },
              ]}
              pagination={false}
              rowKey="key"
              scroll={{ y: 240 }}
            />
          </div>
          {errors.courses && (
            <span className="text-red-500">{errors.courses.message}</span>
          )}
        </div>

        <button type="submit" className="submit">
          Submit
        </button>
      </form>
    </div>
  );
}

import { useCallback } from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const useExportExcel = () => {
  const exportToExcel = useCallback((data, fileName = "ExportedData.xlsx") => {
    if (!data || data.length === 0) {
      console.error("No data provided for export.");
      return;
    }

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Styled Sheet");

    // Add headers dynamically based on keys of the first object
    const headers = Object.keys(data[0]);
    worksheet.addRow(headers);

    // Style headers
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      //   cell.fill = {
      //     type: "pattern",
      //     pattern: "solid",
      //     fgColor: { argb: "FFFF00" }, // Yellow background
      //   };
      cell.font = {
        bold: true,
        color: { argb: "FF0000" }, // Red text
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // Add data rows
    data.forEach((rowData) => {
      const rowValues = Object.values(rowData);
      worksheet.addRow(rowValues);
    });

    // Adjust column widths automatically
    worksheet.columns = headers.map((key, index) => {
        const maxContentLength = Math.max(
          key.length,
          ...data.map((row) => (row[key] ? row[key].toString().length : 0))
        );
        return { width: maxContentLength + 2 };
      });
    // Fill empty cells with red background
    worksheet.eachRow((row, rowIndex) => {
      if (rowIndex > 1) {
        // Skip header row
        row.eachCell({ includeEmpty: true }, (cell) => {
          if (!cell.value) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF0000" }, // Red background
            };
          }
        });
      }
    });

    // Generate the Excel file and save it
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer]), fileName);
    });
  }, []);

  return { exportToExcel };
};

export default useExportExcel;

import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable"

export default function VideoRequests() {
    const table = useTable("videoRequests");

  return (
    <>
     <HelmetTags title="Video Requests | Mr.Ahmed Bayoumy"></HelmetTags>
     <section className=" px-10 py-10 flex flex-col  gap-20 ">
       
        <h2 className="text-black text-5xl font-semibold">Video Requests</h2>
        <div className="mt-10">{table}</div>
    </section>
    </>
   
  )
}

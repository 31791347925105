import useAxios from "./Hooks/useAxios";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
export default function AskButton({ ID, essay, asked , setReFetchButton }) {
  const [submitAnswer, setSubmitAnswer] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [askSuccess, askError, askLoading] = useAxios(
    process.env.REACT_APP_ASK_QUESTION_POST_API,
    "POST",
    submitAnswer.flag,
    submitAnswer.dependency,
    submitAnswer.data,
    true
  );
  useEffect(() => {
    if ((askSuccess || askError) && submitAnswer.dependency) {
      setSubmitAnswer({
        flag: "",
        dependency: false,
        data: {},
      });
    }
  }, [askSuccess, askError]);
  const { handleSubmit } = useForm();
  const onSubmit = (data) => {
    if (askLoading) return;

    const finalData = {
      question_id: ID,
      is_essay: essay === 1 ? 1 : 0,
    };

    setSubmitAnswer({
      flag: "ask",
      dependency: true,
      data: finalData,
    });
    setReFetchButton(true)
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <button
        disabled={askLoading || asked === 1}
        type="submit"
        className={`submit-btn rounded-md border border-blue-500 ${
          asked === 1 ? "bg-gray-500" : "bg-blue-500"
        } px-[16px] py-[8px] font-bold text-white duration-200 hover:scale-110 active:scale-90`}
      >
        {askLoading ? "is Loading" : "Ask"}
      </button>
    </form>
  );
}

import { useState } from "react";
import useTable from "../../../../MainComponents/Hooks/useTable";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useParams } from "react-router-dom";

export default function AdminAskPermession() {
  const { adminID } = useParams();
  const [selectedRows, setSelectedRows] = useState([]);
  const [submitPermission, setSubmitPermission] = useState({
    flag: "",
    depedency: false,
    data: {},
  });
  const table = useTable(
    "coursesAsk",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    setSelectedRows
  );
  const [successPerimssion, errorPermission, loadingPermission] = useAxios(
    " https://api.bayoumymath.com/api/admin/user/addaskpermission",
    "POST",
    submitPermission.flag,
    submitPermission.depedency,
    submitPermission.data,
    true
  );

  const onSubmit = () => {
    let finalData = {
      id: adminID,
      course_id: selectedRows?.map((row) => row?.key),
    };
    setSubmitPermission({
      flag: "set Permession",
      depedency: !submitPermission.depedency,
      data: finalData,
    });
  };
  return (
    <section className="px-10 py-10 w-full flex flex-col items-center justify-center gap-10">
      <button
        type="button"
        disabled={selectedRows?.length === 0}
        onClick={() => onSubmit()}
        className="bg-blue-500 px-4 py-3 rounded-3xl hover:bg-blue-700 duration-200 text-white mx-auto"
      >
        Submit
      </button>
      <div class>{table}</div>
    </section>
  );
}

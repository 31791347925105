import { Link } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import useTable from "../../../MainComponents/Hooks/useTable";
import "../../../SASS/Assignments.scss";
import { useState } from "react";
import { Loader } from "../../../MainComponents";
import HelmetTags from "../../../MainComponents/HelmetTags";
export default function Assignments() {
  const [submitRequest, setSubmitRequest] = useState({
    flag: "",
    dependancy: false,
    data: {},
  });

  const [sumbitRequest, errorRequest, loadingRequest] = useAxios(
    process.env.REACT_APP_ADMIN_ASSIGMNETS_REQUEST_API,
    "POST",
    submitRequest.flag,
    submitRequest.dependancy,
    submitRequest.data,
    true
  );
  const [getAssignment, errorAssigment, loadingAssignment] = useAxios(
    process.env.REACT_APP_STUDENT_ASSIGMNENTS_GET_API,
    "GET",
    "GET",
    submitRequest.dependancy
  );
  const onSubmit = (data) => {
    const finalData = { id: data.key };
    setSubmitRequest({
      flag: "student_skills",
      dependancy: !submitRequest.dependancy,
      data: finalData,
    });
  };
  if (loadingAssignment && submitRequest.dependancy === false)
    return <Loader />;
  return (
    <section className="min-h-screen md:px-0 px-10 py-10 flex flex-col  w-full ">
      <HelmetTags title="Assignments | Mr Ahmed Bayoumy"></HelmetTags>
      <h1 className="text-center text-4xl py-5 font-bold">Assignments</h1>
      <table className="tableassignments">
        <tbody>
          {getAssignment?.data?.map((item) => (
            <tr className="py-5 text-blue-500" key={item.key}>
              <td className="px-5">
                {item.n_result > 0 && (
                  <Link
                    to={`/assignemnts/showmodelquiz/${item?.results[0]?.id}`}
                    className="text-blue-500 hover:text-secondary"
                  >
                    Try {item.n_result}
                  </Link>
                )}
              </td>
              <td>
                <p>{item.name}</p>
                <p className="text-black">{item.description}</p>
              </td>
              <td>
                <Link
                  to={`exam/start/${item.key}`}
                  className="bg-blue-500 px-4 py-3 rounded-3xl hover:bg-secondary hover:text-white text-white border duration-150"
                >
                  {item.n_result === 0
                    ? "Start"
                    : item.n_result > 0 && "Retake"}
                </Link>
              </td>
              <td>
                {item.n_YoutubeEmbed > 0 &&
                item.is_req === 1 &&
                item.is_video === 0 ? (
                  <button
                    disabled={
                      item.n_YoutubeEmbed > 0 &&
                      item.n_req === 1 &&
                      item.is_req === 1
                    }
                    onClick={() => onSubmit(item)}
                    className={`${
                      item.n_YoutubeEmbed > 0 &&
                      item.n_req === 1 &&
                      item.is_req === 1
                        ? "bg-blue-500/50"
                        : "bg-blue-500  hover:bg-secondary hover:text-white "
                    }  px-4 py-3 rounded-3xl text-white border  duration-150 `}
                  >
                    {item.n_YoutubeEmbed > 0 &&
                    item.n_req === 1 &&
                    item.is_req === 1
                      ? "is Pending"
                      : "Request"}
                  </button>
                ) : null}
                {item.is_video === 1 ? (
                  <Link
                    to={`/homework/videos/${item.key}`}
                    className="bg-blue-500 px-4 py-3 rounded-3xl hover:bg-secondary hover:text-white text-white border  duration-150 "
                  >
                    Videos
                  </Link>
                ) : null}
              </td>

              <td>
                {item?.is_pdf === 1 ? (
                  <Link
                    to={`/assignment/Pdf/${item.key}`}
                    className="bg-blue-500 px-4 py-3 rounded-3xl hover:bg-secondary hover:text-white text-white border duration-150"
                  >
                    PDF
                  </Link>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

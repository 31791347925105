import React from "react";
import useTable from "../../../../MainComponents/Hooks/useTable";

export default function AdminAsks() {
  const tablestudnets = useTable("askstudents");
  const tableadminpoints = useTable("adminpoints");
  return (
    <section className="flex gap-5 flex-col min-h-screen">
      <div className="px-10 py-10">{tablestudnets}</div>
      <div className="px-10 py-10">{tableadminpoints}</div>
    </section>
  );
}

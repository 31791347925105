import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Loader } from "../../../../MainComponents";
import { Table, Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useNavigate } from "react-router-dom";

export default function AddSkills() {
  const { StudentID } = useParams();
  const navigate = useNavigate();
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [DataSkills, ErrorSkills, LoadingSkills] = useAxios(
    `https://api.bayoumymath.com/api/admin/students/getskills/${StudentID}`,
    "GET",
    "GET",
    refetch
  );
  const [SubmitSuccess, SubmitError, SubmitLoading] = useAxios(
    `https://api.bayoumymath.com/api/admin/students/addskills`,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );

  const handleSuccess = () => {
    setRefetch(!refetch);
  };

  useEffect(() => {
    if (SubmitSuccess) {
      handleSuccess();
    }
  }, [SubmitSuccess]);
  console.log(selectedSkills);

  useEffect(() => {
    if (DataSkills?.data) {
      const formattedData = DataSkills.data.map((skill) => ({
        ...skill,
        key: skill.id,
        is_homework: skill.is_homework,
        checked: skill.is_selected === 1, // Mark as checked if already selected
      }));
      setFilteredData(formattedData);
      const initiallySelectedKeys = formattedData
        .filter((skill) => skill.checked)
        .map((skill) => skill.key);
      setSelectedRowKeys(initiallySelectedKeys);
    }
  }, [DataSkills]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = () => {
    // Get the list of skills that are already selected and included in the request
    const finalData = {
      student_id: StudentID,
      skills: selectedSkills.map((skill) => ({
        skill_id: skill.id,
        is_homework: skill.is_homeworkskill, // Send the homework status
      })),
    };

    setSubmitAdd({
      flag: "Add Skill",
      dependency: !submitAdd.dependency,
      data: finalData,
    });
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      // Add selected rows (including those disabled)
      const updatedRows = selectedRows.map((row) => ({
        ...row,
        is_homeworkskill: row.is_homeworkskill,
      }));
      setSelectedRowKeys(selectedRowKeys);
      setSelectedSkills(updatedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.is_homeworkskill === 0 && record.is_selected === 1, // Disable if is_homeworkskill is 0
    }),
  };

  const handleSearch = (value) => {
    const searchValue = value.toLowerCase();
    const filtered = DataSkills?.data?.filter((item) =>
      item.name.toLowerCase().includes(searchValue)
    );
    setFilteredData(filtered || []);
    setSearchText(value);
  };

  const columns = [
    {
      title: "Skill",
      dataIndex: "name",
      key: "name",
      width: 150,
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase());
      },
    },
  ];

  if (LoadingSkills && submitAdd.dependency === false) return <Loader />;

  return (
    <main className="px-10 py-10">
      <div className="py-10 md:px-2 w-full flex items-start flex-col justify-center">
        <h3 className="text-5xl pb-10">Add Skills</h3>
        <div className="w-full mb-4">
          <Input
            placeholder="Search skills..."
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            className="max-w-xs"
          />
        </div>
        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey="id"
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          bordered
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100"],
          }}
          className="w-full"
        />
        {!LoadingSkills && (
          <Button
            type="primary"
            onClick={onSubmit}
            className="bg-secondary hover:bg-secondary/50 mt-10 h-auto py-3 px-6 rounded-md text-white text-base mx-auto"
          >
            Add
          </Button>
        )}
      </div>
    </main>
  );
}

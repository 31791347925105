import { Loader } from "../../../../MainComponents";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Table, Input, Form } from "antd";
export default function BooksCourses() {
  const { id } = useParams();
  const [courses, setCourses] = useState([]);
  const [submitAddBooksAndCourse, setSubmitAddBooksAndCourse] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const [dataCourses, errorCourses, loadingCourses] = useAxios(
    process.env.REACT_APP_ADMIN_GET_COURSES_API,
    "GET",
    "GET",
    ""
  );

  const [
    successAddBooksAndCourse,
    errorAddBooksAndCourse,
    loadingAddBooksAndCourse,
  ] = useAxios(
    process.env.REACT_APP_BOOKS_COURSE_ADD_API,
    "POST",
    submitAddBooksAndCourse.flag,
    submitAddBooksAndCourse.dependency,
    submitAddBooksAndCourse.data,
    true
  );

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setCourses(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const onSubmitAddBooksWithCourses = (data) => {
    const formData = {
      id: id, // Single book ID from URL params
      cources: courses.map((course) => course.key), // Array of selected course keys
    };
    console.log(formData);
    setSubmitAddBooksAndCourse({
      flag: "Add Book with courses",
      dependency: !submitAddBooksAndCourse.dependency,
      data: formData,
    });
  };

  const CoursesHomeworkColumns = [
    {
      title: "Name",
      dataIndex: "name",
      editTable: true,
      width: 200,
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
    },
  ];
  function editableCell({
    editing,
    dataIndex,
    title,
    record,
    children,
    ...restProps
  }) {
    const input = <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[{ required: false }]}
          >
            {input}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  useEffect(() => {
    setCourses(dataCourses?.data);
  }, [dataCourses, loadingCourses]);

  if (loadingCourses || loadingAddBooksAndCourse) return <Loader />;

  // Ensure dataCourses.data is defined before accessing it

  return (
    <main className="py-10 px-10 flex flex-col items-center justify-center w-full">
      <div className="w-full flex flex-col items-start gap-6">
        <form
          onSubmit={handleSubmit(onSubmitAddBooksWithCourses)}
          className="w-full"
        >
          <div className="py-10 px-2 w-full">
            <h3 className="text-5xl pb-10">Courses</h3>
            <div>
              <Table
                dataSource={dataCourses?.data}
                columns={CoursesHomeworkColumns}
                bordered
                pagination={{ position: ["bottomCenter"] }}
                components={{ body: { cell: editableCell } }}
                rowSelection={{ type: "checkbox", ...rowSelection }}
              />
            </div>
          </div>
          <div className="py-10 flex items-center w-full justify-center">
            <button className="bg-blue-700 hover:bg-blue-700/50 duration-150 text-white px-4 py-3">
              Save
            </button>
          </div>
        </form>
      </div>
    </main>
  );
}

import { Link } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import useTable from "../../../MainComponents/Hooks/useTable";
import "../../../SASS/Assignments.scss";
import { Loader } from "../../../MainComponents";
export default function Weekly() {
  const [getweekly, errorweekly, loadingweekly] = useAxios(
    process.env.REACT_APP_STUDENT_WEEKLY_GET_API,
    "GET",
    "GET",
    ""
  );
  if (loadingweekly) return <Loader />;
  return (
    <section className="px-10 py-10 flex flex-col min-h-screen w-full ">
      <table className="tableassignments">
        <tbody>
          {getweekly?.data?.map((item) => (
            <tr className="py-5 text-blue-500" key={item.key}>
              {/*    {item.results.length > 0 ? (
                <td>
                  {item.results[0].degree} Out OF {item.results[0].out_of}
                </td>
              ) : (
                <td></td>
              )} */}

              <td className={`${item.n_result > 0 ? "" : "px-3"}`}>
                {item.n_result > 0 && (
                  <Link
                    to={`modalanswer/${item.result_id}`}
                    className="bg-blue-500 px-4 py-3 rounded-3xl hover:bg-secondary text-white duration-150 "
                  >
                    Model Answer
                  </Link>
                )}
              </td>
              <td className="text-3xl font-bold">{item.exam_date}</td>
              <td>
                {item.n_result === 0 ? (
                  <Link
                    to={`exam/start/${item.key}`}
                    className="bg-blue-500 px-4 py-3 rounded-3xl hover:bg-secondary text-white duration-150 "
                  >
                    Start
                  </Link>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

import React, { useState, useRef, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../MainComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faMessage } from "@fortawesome/free-solid-svg-icons";
import ReactStars from "react-stars";
import { AnimatePresence, motion } from "framer-motion";
import ChatFooter from "../../../MainComponents/fabs/sections/chatFooter/ChatFooter";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useAuthState } from "../../../MainComponents/GlobalContext";
import { Popconfirm } from "antd";
import useChat from "../../../MainComponents/Hooks/useChat";

export default function ShowQuestionAsked() {
  const { ID } = useParams();
  const navigate = useNavigate();
  const [refetch, setRefetch] = useState(false);
  const [dataRate, setDataRate] = useState({
    id: ID,
    rate: 0,
  });
  const [submitrate, setsubmitrate] = useState({
    flag: "",
    depdency: false,
    data: {},
  });
  const [questionData, questionError, questionLoading] = useAxios(
    `${process.env.REACT_APP_ASK_QUESTION_GET_API}/${ID}`,
    "GET",
    ID,
     submitrate.depdency
  );
  const [messageData, messageError, Loading] = useAxios(
    `${process.env.REACT_APP_ASK_QUESTION_GET_API}/${ID}`,
    "GET",
    ID,
    refetch
  );
  const [rateSuccess, rateError, rateLoading] = useAxios(
    process.env.REACT_APP_ASK_QUESTION_SUBMIT_RATE_API,
    "POST",
    submitrate.flag,
    submitrate.depdency,
    submitrate.data,
    true
  );
  const chat = useChat(refetch, setRefetch, ID, messageData?.data);
  const ratingChanged = (newRating) => {
    setDataRate((prev) => ({
      ...prev,
      rate: newRating,
    }));
  };
  const onSubmitRate = () => {
    if (dataRate.rate === 0) {
      alert("Please select a rating before submitting");
      return;
    }

    setsubmitrate({
      flag: "Add Rate",
      depdency: !submitrate.depdency,
      data: dataRate,
    });
  };

  if (questionLoading) return <Loader />;
  if (questionError)
    return (
      <div className="min-h-screen w-full flex justify-center items-center text-3xl texb-black">
        {questionError?.response?.data?.message}
      </div>
    );
  return (
    <section className="relative min-h-screen bg-white flex md:flex-col-reverse gap-5 justify-center items-center md:px-2 px-10 py-10">
      <div className="relative p-8 border rounded-3xl border-secondary shadow-lg md:mx-2  w-full max-w-[66%] mx-auto">
        <div className=" absolute top-1 right-1 flex items-center justify-center">
          <button
            onClick={() => navigate(-1)}
            className="bg-red-500 w-14 h-14 rounded-full text-white  scale-90 duration-300 hover:scale-100"
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <div className=" mb-[20px] flex flex-col gap-5 w-full pr-[20px]">
          <div className="w-full flex justify-between gap-5 lg:flex-col-reverse">
            <h2 className="text-2xl font-bold mb-4 text-black">
              Question:{" "}
              <div
                dangerouslySetInnerHTML={{
                  __html: questionData?.data?.question?.question,
                }}
              />
            </h2>
            {questionData?.data?.question?.is_answered === 1 ||
            questionData?.data?.question?.rate ? (
              <div className="flex flex-col gap-5 items-center justify-center">
                <ReactStars
                  count={5}
                  size={40}
                  color2={"#ffd700"}
                  value={questionData?.data?.question?.rate || 0}
                  edit={false}
                />
              </div>
            ) : questionData?.data?.question?.is_replied === 0 ? (
              <p className="text-red-500 text-2xl font-bold">is Pending ...</p>
            ) : (
              <div className="flex flex-col gap-5 items-center justify-center">
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={40}
                  color2={"#ffd700"}
                  value={dataRate.rate}
                />
                <div className="w-full mx-auto">
                  <Popconfirm
                    okText="Confirm"
                    okType="red"
                    cancelText="Cancel"
                    color="#b1e8f4"
                    disabled={dataRate.rate === 0}
                    onConfirm={() => onSubmitRate()}
                    title="Confirm Rate - This will close the chat"
                  >
                    {questionData?.data?.question?.rate === null && (
                      <button className="submit">
                        {dataRate.rate === 0
                          ? "Choose Rating"
                          : "Submit Rating"}
                      </button>
                    )}
                  </Popconfirm>
                </div>
              </div>
            )}
          </div>

          {/* Render answers */}
          <div>
            <h3 className="text-2xl font-semibold mb-2">Answers:</h3>
            <ul className="grid sm:grid-cols-1 grid-cols-2 gap-5">
              {["answer1", "answer2", "answer3", "answer4"].map(
                (key, index) => (
                  <li key={key} className="mb-2 flex gap-3 text-black text-2xl">
                    <span>{String.fromCharCode(97 + index)})</span>
                    {questionData?.data?.question?.[key] ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: questionData.data.question[key],
                        }}
                      />
                    ) : (
                      `${key}: Not provided`
                    )}
                  </li>
                )
              )}
            </ul>
          </div>
          <p className="text-xl text-gray-700 mb-4">
            Explanation:{" "}
            {questionData?.data?.question?.explanation ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: questionData.data.question.explanation,
                }}
              />
            ) : (
              "No explanation provided"
            )}
          </p>
          {questionData?.data?.question?.video && (
            <div className="mb-4">
              <div
                dangerouslySetInnerHTML={{
                  __html: questionData.data.question.video,
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className=" md:relative md:right-0 fixed bottom-5 right-5   max-w-[550px] w-full md:z-0 z-[2000]">
        {chat}
      </div>
    </section>
  );
}

import React from "react";

export const DisplayQuestion = ({ state, children }) => {
  return (
    <div
      className={` flex w-3/4 flex-col  items-center px-[5%] py-[40px] md:w-full ${
        state.response && "hidden"
      }`}
    >
      {children}
    </div>
  );
};

import { Navigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Loader } from "../../../../MainComponents";
import { Table, Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

export default function AddAssigment() {
  const { ID, CourseID } = useParams();
  const navigate = useNavigate();
  const [selectedAssignments, setSelectedAssignments] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [DataAssigments, ErrorAssigments, LoadingAssigment] = useAxios(
    process.env.REACT_APP_STUDENTS_GET_ASSIGMENT_API,
    "GET",
    "GET",
    ""
  );

  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  let api = CourseID
    ? "https://api.bayoumymath.com/api/admin/students/addAssignmentforselected"
    : process.env.REACT_APP_STUDENTS_ADD_ASSIGMENT_API;

  const [successAddCourse, errorAddCourse, loadingAddCourse] = useAxios(
    api,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );

  useEffect(() => {
    if (DataAssigments?.data) {
      setFilteredData(DataAssigments.data);
    }
  }, [DataAssigments]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = () => {
    const finalData = ID
      ? {
          student_id: ID,
          assignments: selectedAssignments.map((assignment) => assignment.key),
        }
      : {
          assignments: selectedAssignments.map((assignment) => assignment.key),
          students: JSON.parse(localStorage.getItem("studentview") || "[]").map(
            (student) => student.key
          ),
        };

    setSubmitAdd({
      flag: "Add Assigment",
      dependency: !submitAdd.dependency,
      data: finalData,
    });
    localStorage.removeItem("studentview");
    setSelectedAssignments([]);
    reset();
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedAssignments(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  useEffect(() => {
    if (successAddCourse) {
      navigate(-1);
    }
  }, [successAddCourse]);

  const handleSearch = (value) => {
    const searchValue = value.toLowerCase();
    const filtered = DataAssigments?.data?.filter(
      (item) =>
        item.name.toLowerCase().includes(searchValue) ||
        item.n.toString().includes(searchValue)
    );
    setFilteredData(filtered || []);
    setSearchText(value);
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "n",
      key: "n",
      width: 10,
      align: "center",
    },
    {
      title: "Assignment",
      dataIndex: "name",
      key: "name",
      width: 150,
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase());
      },
    },
  ];

  function editableCell({
    editing,
    dataIndex,
    title,
    record,
    children,
    ...restProps
  }) {
    const input = <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[{ required: false }]}
          >
            {input}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  if (LoadingAssigment) return <Loader />;

  return (
    <main className="px-10 py-10">
      <div className="py-10 md:px-2 w-full flex items-start flex-col justify-center">
        <h3 className="text-5xl pb-10">Add Assignment</h3>
        <div className="w-full mb-4">
          <Input
            placeholder="Search assignments..."
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            className="max-w-xs"
          />
        </div>
        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey="key"
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          bordered
          components={{ body: { cell: editableCell } }}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100"],
          }}
          className="w-full"
        />
        {!LoadingAssigment && (
          <Button
            type="primary"
            onClick={onSubmit}
            className="bg-secondary hover:bg-secondary/50 mt-10 h-auto py-3 px-6 rounded-md text-white text-base"
          >
            Add
          </Button>
        )}
      </div>
    </main>
  );
}

import { useEffect, useReducer, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import logoLight from "../../../assets/logo-light.png";
import Loader from "../../../MainComponents/Loader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Register({ edit, addNew, StudentInfo, studentInfoLoading, type }) {
  const [values, setValues] = useState(null);
  const { studentID } = useParams();
  const navigate = useNavigate();
  const [countrySelected, setCountrySelected] = useState("20");
  const [countryParentSelected, setCountryParentSelected] = useState("20");
  const [mobile, setMobile] = useState("");
  const [parentMobile, setParentMobile] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
    values,
  });

  const watchCountries = watch("countries");
  const watchGovernorate = watch("governorate");
  const watchLevel = watch("stage");
  const watchType = watch("edu_type");
  const watchPlace = watch("is_online");

  function reducer(state, action) {
    switch (action.type) {
      case "setStates": {
        return {
          ...state,
          states: action.payload,
        };
      }
      case "setGroups": {
        return {
          ...state,
          groups: action.payload,
        };
      }
      case "setSelectedState": {
        return {
          ...state,
          selectedState: action.payload,
        };
      }
      case "setCitySelected": {
        return {
          ...state,
          citySelected: action.payload,
        };
      }
      case "setGovernorate": {
        return {
          ...state,
          governorate: action.payload,
        };
      }
      case "setCities": {
        return {
          ...state,
          cities: action.payload,
        };
      }
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setHide": {
        return {
          ...state,
          hide: !state.hide,
        };
      }
      case "setSubmitLoading": {
        return {
          ...state,
          submitLoading: action.payload,
        };
      }
      case "setSubmitEditStudent": {
        return {
          ...state,
          submitEditStudent: action.payload,
        };
      }
      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [selectedValue, setSelectedValue] = useState("");
  const [state, dispatch] = useReducer(reducer, {
    hide: false,
    states: [],
    governorate: [],
    cities: [],
    groups: [],
    selectedState: "",
    selectedCity: "",
    errors: {},
    submitLoading: false,
    submitEditStudent: { flag: "", dependency: false, data: {} },
  });

  /*   useEffect(() => {
    // const stateSelector = document.querySelector(".state-selector");
    // const citySelector = document.querySelector(".city-selector");
    // stateSelector.addEventListener("change", function handleStateSelected(e) {
    //   dispatch({
    //     type: "setSelectedState",
    //     payload: e.target.selectedIndex,
    //   });
    // });
    // citySelector?.addEventListener("change", function handleStateSelected(e) {
    //   dispatch({
    //     type: "setCitySelected",
    //     payload: e.target.selectedIndex,
    //   });
    // });
    async function fetchData() {
      try {
        const res = await axios(process.env.REACT_APP_GOVERNORATES_API);
        const data = await res.data;
        dispatch({
          type: "setGovernorate",
          payload: data?.states,
        });
        // dispatch({
        //   type: "setStates",
        //   payload: data?.states,
        // });
        dispatch({
          type: "setGroups",
          payload: data?.groups,
        });
      } catch (error) {
        toast.error(
          "An error occurred while loading cities. Please try again."
        );
      }
    }
    fetchData();
  }, []); */

  const [StudentEditSuccess, StudentEditErrors, StudentEditLoading] = useAxios(
    process.env.REACT_APP_EDIT_STUDENT_INFO_API,
    "POST",
    state.submitEditStudent.flag,
    state.submitEditStudent.dependency,
    state.submitEditStudent.data,
    true
  );
  const [CoursesData, CoursesErrors, CoursesLoading] = useAxios(
    "https://api.bayoumymath.com/api/allcourses",
    "GET",
    "GET",
    ""
  );

  useEffect(() => {
    if (StudentEditSuccess) {
      reset();
      navigate(-1);
    }
  }, [StudentEditSuccess]);

  useEffect(() => {
    if (StudentInfo) {
      dispatch({
        type: "setSelectedState",
        payload: StudentInfo?.data[0]?.selected_state,
      });
      setValue("governorate", StudentInfo?.data[0]?.governorate);
      setValue("is_online", StudentInfo?.data[0]?.is_online);
      // setValue("stage", StudentInfo?.data[0]?.stage);
      setValue("countries", StudentInfo?.data[0]?.country);

      delete StudentInfo?.data[0]?.governorate;
      delete StudentInfo?.data[0]?.is_online;
      setValues({
        ...StudentInfo?.data[0],
        active: StudentInfo?.data[0]?.active === 1 ? true : false,
        edu_type: String(StudentInfo?.data[0]?.edu_type),
        stage: String(StudentInfo?.data[0]?.stage),
      });
    }
  }, [StudentInfo]);

  /*  useEffect(() => {
    if (watchCountries === "") {
      dispatch({
        type: "setGovernorate",
        payload: state?.states?.filter(
          (state) => state.Country === "مصر" //  egypt
        )[0]?.states,
      });

      setValue("countries", "مصر");
    }
  }, [StudentInfo?.data, setValue, state?.states, watchCountries]); */
  /* 
  useEffect(() => {
    if (watchGovernorate) {
      dispatch({
        type: "setCities",
        payload: state.governorate?.filter(
          (city) => city.state === watchGovernorate
        )[0]?.cities,
      });
    }

    if (watchGovernorate === "") {
      dispatch({
        type: "setCities",
        payload: state.governorate?.filter(
          (city) => city.state === "القاهرة"
        )[0]?.cities,
      });
      setValue("governorate", "القاهرة");
      setValue("area", 1);
    }
  }, [watchGovernorate, state.governorate]); */

  const onSubmit = (data) => {
    if (mobile.trim().includes(" ")) {
      toast.error("Mobile number should not contain any spaces.");
      return;
    } else if (parentMobile.trim().includes(" ")) {
      toast.error("Parent mobile number should not contain any spaces.");
      return;
    }
    const studentData = edit
      ? {
          ...data,
          group_id: data?.group_id || null,
          // profile_pic: data?.profile_pic[0] || null,
          magnetic_id: data?.magnetic_id || null,
          active: data?.active === true ? 1 : 0,
          id: studentID,
        }
      : {
          ...data,
          mobile_key: countrySelected,
          mobileparent_key: countryParentSelected,
          mobile: mobile,
          parent_mobile: parentMobile,
          /*  profile_pic: data?.profile_pic[0], */
          school: data?.school || null,
          /*    active: addNew ? 1 : 0, */
        };

    if (edit) {
      dispatch({
        type: "setSubmitEditStudent",
        payload: {
          flag: "AddEditStudent",
          dependency: !state.submitEditStudent.dependency,
          data: studentData,
        },
      });
    } else {
      dispatch({
        type: "setSubmitLoading",
        payload: true,
      });
      async function postData() {
        try {
          const res = await axios.post(
            process.env.REACT_APP_REGISTER_API,
            studentData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );

          const data = await res.data;
          toast.success(data?.message);
          dispatch({
            type: "setSubmitLoading",
            payload: false,
          });
          !edit && navigate("/login");
          reset();
        } catch (error) {
          dispatch({
            type: "setErrors",
            payload: error.response.data?.errors,
          });
          dispatch({
            type: "setSubmitLoading",
            payload: false,
          });
          toast.error(
            "An error occurred while creating a new account. Please review the data"
          );
        }
      }

      postData();
    }
  };

  useEffect(() => {
    if (watchType) {
      setValue("stage", null);
    }
  }, [setValue, watchType]);

  if (studentInfoLoading) {
    return <Loader />;
  }

  if (type === "registerStudent") {
    return (
      <>
        <div className="min-w-screen min-h-screen bg-white flex items-center justify-center px-5 py-5">
          <div
            className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden"
            style={{ maxWidth: "1000px" }}
          >
            <div className="flex w-full">
              <div className="md:hidden flex justify-center items-center w-1/2 bg-secondary/60 py-10 px-10 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 1"
                  width="500"
                  height="747.07702"
                  viewBox="0 0 744.84799 747.07702"
                >
                  <path
                    id="fa3b9e12-7275-481e-bee9-64fd9595a50d-1191"
                    data-name="Path 1"
                    d="M299.205,705.80851l-6.56-25.872a335.96693,335.96693,0,0,0-35.643-12.788l-.828,12.024-3.358-13.247c-15.021-4.29394-25.24-6.183-25.24-6.183s13.8,52.489,42.754,92.617l33.734,5.926-26.207,3.779a135.92592,135.92592,0,0,0,11.719,12.422c42.115,39.092,89.024,57.028,104.773,40.06s-5.625-62.412-47.74-101.5c-13.056-12.119-29.457-21.844-45.875-29.5Z"
                    transform="translate(-227.576 -76.46149)"
                    fill="#f2f2f2"
                  />
                  <path
                    id="bde08021-c30f-4979-a9d8-cb90b72b5ca2-1192"
                    data-name="Path 2"
                    d="M361.591,677.70647l7.758-25.538a335.93951,335.93951,0,0,0-23.9-29.371l-6.924,9.865,3.972-13.076c-10.641-11.436-18.412-18.335-18.412-18.335s-15.315,52.067-11.275,101.384l25.815,22.51-24.392-10.312a135.91879,135.91879,0,0,0,3.614,16.694c15.846,55.234,46.731,94.835,68.983,88.451s27.446-56.335,11.6-111.569c-4.912-17.123-13.926-33.926-24.023-48.965Z"
                    transform="translate(-227.576 -76.46149)"
                    fill="#f2f2f2"
                  />
                  <path
                    id="b3ac2088-de9b-4f7f-bc99-0ed9705c1a9d-1193"
                    data-name="Path 22"
                    d="M747.327,253.4445h-4.092v-112.1a64.883,64.883,0,0,0-64.883-64.883H440.845a64.883,64.883,0,0,0-64.883,64.883v615a64.883,64.883,0,0,0,64.883,64.883H678.352a64.883,64.883,0,0,0,64.882-64.883v-423.105h4.092Z"
                    transform="translate(-227.576 -76.46149)"
                    fill="#e6e6e6"
                  />
                  <path
                    id="b2715b96-3117-487c-acc0-20904544b5b7-1194"
                    data-name="Path 23"
                    d="M680.97,93.3355h-31a23.02,23.02,0,0,1-21.316,31.714H492.589a23.02,23.02,0,0,1-21.314-31.714H442.319a48.454,48.454,0,0,0-48.454,48.454v614.107a48.454,48.454,0,0,0,48.454,48.454H680.97a48.454,48.454,0,0,0,48.454-48.454h0V141.7885a48.454,48.454,0,0,0-48.454-48.453Z"
                    transform="translate(-227.576 -76.46149)"
                    fill="#fff"
                  />
                  <path
                    id="b06d66ec-6c84-45dd-8c27-1263a6253192-1195"
                    data-name="Path 6"
                    d="M531.234,337.96451a24.437,24.437,0,0,1,12.23-21.174,24.45,24.45,0,1,0,0,42.345A24.43391,24.43391,0,0,1,531.234,337.96451Z"
                    transform="translate(-227.576 -76.46149)"
                    fill="#ccc"
                  />
                  <path
                    id="e73810fe-4cf4-40cc-8c7c-ca544ce30bd4-1196"
                    data-name="Path 7"
                    d="M561.971,337.96451a24.43594,24.43594,0,0,1,12.23-21.174,24.45,24.45,0,1,0,0,42.345A24.43391,24.43391,0,0,1,561.971,337.96451Z"
                    transform="translate(-227.576 -76.46149)"
                    fill="#ccc"
                  />
                  <circle
                    id="a4813fcf-056e-4514-bb8b-e6506f49341f"
                    data-name="Ellipse 1"
                    cx="364.43401"
                    cy="261.50202"
                    r="24.45"
                    fill="#ff695f"
                  />
                  <path
                    id="bbe451c3-febc-41ba-8083-4c8307a2e73e-1197"
                    data-name="Path 8"
                    d="M632.872,414.3305h-142.5a5.123,5.123,0,0,1-5.117-5.117v-142.5a5.123,5.123,0,0,1,5.117-5.117h142.5a5.123,5.123,0,0,1,5.117,5.117v142.5A5.123,5.123,0,0,1,632.872,414.3305Zm-142.5-150.686a3.073,3.073,0,0,0-3.07,3.07v142.5a3.073,3.073,0,0,0,3.07,3.07h142.5a3.073,3.073,0,0,0,3.07-3.07v-142.5a3.073,3.073,0,0,0-3.07-3.07Z"
                    transform="translate(-227.576 -76.46149)"
                    fill="#ccc"
                  />
                  <rect
                    id="bb28937d-932f-4fdf-befe-f406e51091fe"
                    data-name="Rectangle 1"
                    x="218.56201"
                    y="447.10197"
                    width="218.552"
                    height="2.047"
                    fill="#ccc"
                  />
                  <circle
                    id="fcef55fc-4968-45b2-93bb-1a1080c85fc7"
                    data-name="Ellipse 2"
                    cx="225.46401"
                    cy="427.41999"
                    r="6.902"
                    fill="#ff695f"
                  />
                  <rect
                    id="ff33d889-4c74-4b91-85ef-b4882cc8fe76"
                    data-name="Rectangle 2"
                    x="218.56201"
                    y="516.11803"
                    width="218.552"
                    height="2.047"
                    fill="#ccc"
                  />
                  <circle
                    id="e8fa0310-b872-4adf-aedd-0c6eda09f3b8"
                    data-name="Ellipse 3"
                    cx="225.46401"
                    cy="496.43702"
                    r="6.902"
                    fill="#ff695f"
                  />
                  <path
                    d="M660.69043,671.17188H591.62207a4.50493,4.50493,0,0,1-4.5-4.5v-24.208a4.50492,4.50492,0,0,1,4.5-4.5h69.06836a4.50491,4.50491,0,0,1,4.5,4.5v24.208A4.50492,4.50492,0,0,1,660.69043,671.17188Z"
                    transform="translate(-227.576 -76.46149)"
                    fill="#ff695f"
                  />
                  <circle
                    id="e12ee00d-aa4a-4413-a013-11d20b7f97f7"
                    data-name="Ellipse 7"
                    cx="247.97799"
                    cy="427.41999"
                    r="6.902"
                    fill="#ff695f"
                  />
                  <circle
                    id="f58f497e-6949-45c8-be5f-eee2aa0f6586"
                    data-name="Ellipse 8"
                    cx="270.492"
                    cy="427.41999"
                    r="6.902"
                    fill="#ff695f"
                  />
                  <circle
                    id="b4d4939a-c6e6-4f4d-ba6c-e8b05485017d"
                    data-name="Ellipse 9"
                    cx="247.97799"
                    cy="496.43702"
                    r="6.902"
                    fill="#ff695f"
                  />
                  <circle
                    id="aff120b1-519b-4e96-ac87-836aa55663de"
                    data-name="Ellipse 10"
                    cx="270.492"
                    cy="496.43702"
                    r="6.902"
                    fill="#ff695f"
                  />
                  <path
                    id="f1094013-1297-477a-ac57-08eac07c4bd5-1198"
                    data-name="Path 88"
                    d="M969.642,823.53851H251.656c-1.537,0-2.782-.546-2.782-1.218s1.245-1.219,2.782-1.219H969.642c1.536,0,2.782.546,2.782,1.219S971.178,823.53851,969.642,823.53851Z"
                    transform="translate(-227.576 -76.46149)"
                    fill="#3f3d56"
                  />
                  <path
                    d="M792.25256,565.92292a10.09371,10.09371,0,0,1,1.41075.78731l44.8523-19.14319,1.60093-11.81526,17.92157-.10956-1.05873,27.0982-59.19987,15.65584a10.60791,10.60791,0,0,1-.44749,1.20835,10.2346,10.2346,0,1,1-5.07946-13.68169Z"
                    transform="translate(-227.576 -76.46149)"
                    fill="#ffb8b8"
                  />
                  <polygon
                    points="636.98 735.021 624.72 735.021 618.888 687.733 636.982 687.734 636.98 735.021"
                    fill="#ffb8b8"
                  />
                  <path
                    d="M615.96281,731.51778h23.64387a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H601.076a0,0,0,0,1,0,0v0A14.88686,14.88686,0,0,1,615.96281,731.51778Z"
                    fill="#2f2e41"
                  />
                  <polygon
                    points="684.66 731.557 672.459 732.759 662.018 686.271 680.025 684.497 684.66 731.557"
                    fill="#ffb8b8"
                  />
                  <path
                    d="M891.68576,806.12757h23.64387a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H876.7989a0,0,0,0,1,0,0v0A14.88686,14.88686,0,0,1,891.68576,806.12757Z"
                    transform="translate(-303.00873 15.2906) rotate(-5.62529)"
                    fill="#2f2e41"
                  />
                  <circle
                    cx="640.3925"
                    cy="384.57375"
                    r="24.56103"
                    fill="#ffb8b8"
                  />
                  <path
                    d="M849.55636,801.91945a4.47086,4.47086,0,0,1-4.415-3.69726c-6.34571-35.22559-27.08789-150.40528-27.584-153.59571a1.42684,1.42684,0,0,1-.01562-.22168v-8.58789a1.489,1.489,0,0,1,.27929-.87207l2.74024-3.83789a1.47845,1.47845,0,0,1,1.14355-.625c15.62207-.73242,66.78418-2.8789,69.25586.209h0c2.48242,3.10351,1.60547,12.50683,1.4043,14.36035l.00977.19336,22.98535,146.99512a4.51238,4.51238,0,0,1-3.71485,5.13476l-14.35644,2.36524a4.52127,4.52127,0,0,1-5.02539-3.09278c-4.44043-14.18847-19.3291-61.918-24.48926-80.38672a.49922.49922,0,0,0-.98047.13868c.25781,17.60546.88086,62.52343,1.0957,78.0371l.02344,1.6709a4.51811,4.51811,0,0,1-4.09277,4.53614l-13.84375,1.25781C849.83565,801.91359,849.695,801.91945,849.55636,801.91945Z"
                    transform="translate(-227.576 -76.46149)"
                    fill="#2f2e41"
                  />
                  <path
                    id="ae7af94f-88d7-4204-9f07-e3651de85c05-1199"
                    data-name="Path 99"
                    d="M852.38089,495.2538c-4.28634,2.548-6.85116,7.23043-8.32276,11.9951a113.681,113.681,0,0,0-4.88444,27.15943l-1.55553,27.60021-19.25508,73.1699c16.68871,14.1207,26.31542,10.91153,48.78049-.63879s25.03222,3.85117,25.03222,3.85117l4.49236-62.25839,6.41837-68.03232a30.16418,30.16418,0,0,0-4.86143-4.67415,49.65848,49.65848,0,0,0-42.44229-8.99538Z"
                    transform="translate(-227.576 -76.46149)"
                    fill="#ff695f"
                  />
                  <path
                    d="M846.12661,580.70047a10.52561,10.52561,0,0,1,1.50061.70389l44.34832-22.1972.736-12.02551,18.2938-1.26127.98041,27.4126L852.7199,592.93235a10.4958,10.4958,0,1,1-6.59329-12.23188Z"
                    transform="translate(-227.576 -76.46149)"
                    fill="#ffb8b8"
                  />
                  <path
                    id="a6768b0e-63d0-4b31-8462-9b2e0b00f0fd-1200"
                    data-name="Path 101"
                    d="M902.76552,508.41151c10.91151,3.85117,12.83354,45.57369,12.83354,45.57369-12.8367-7.06036-28.24139,4.49318-28.24139,4.49318s-3.20916-10.91154-7.06034-25.03223a24.52987,24.52987,0,0,1,5.13436-23.10625S891.854,504.558,902.76552,508.41151Z"
                    transform="translate(-227.576 -76.46149)"
                    fill="#ff695f"
                  />
                  <path
                    id="bfd7963f-0cf8-4885-9d3a-2c00bccda2e3-1201"
                    data-name="Path 102"
                    d="M889.99122,467.53052c-3.06-2.44837-7.23517,2.00173-7.23517,2.00173l-2.4484-22.03349s-15.30095,1.8329-25.0935-.61161-11.32255,8.87513-11.32255,8.87513a78.57978,78.57978,0,0,1-.30582-13.77092c.61158-5.50838,8.56838-11.01675,22.6451-14.68932S887.6518,439.543,887.6518,439.543C897.44542,444.43877,893.05121,469.97891,889.99122,467.53052Z"
                    transform="translate(-227.576 -76.46149)"
                    fill="#2f2e41"
                  />
                </svg>
              </div>
              <div className="md:w-full w-1/2 py-10 md:px-5 px-10">
                <div className="text-center mb-10">
                  <h1 className="font-bold text-3xl text-gray-900">Welcome</h1>
                  <p>Enter your information to get started</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex -mx-3">
                    <div className="w-1/2 px-3 mb-5">
                      <label className="text-xl font-semibold px-1">
                        First name
                      </label>
                      <div className="flex">
                        <input
                          {...register("first_name", { required: true })}
                          type="text"
                          className="w-full p-2 rounded-lg border border-gray-300 focus:border-secondary focus:outline-none"
                        />
                      </div>
                    </div>
                    <div className="w-1/2 px-3 mb-5">
                      <label className="text-xl font-semibold px-1">
                        Second name
                      </label>
                      <div className="flex">
                        <input
                          {...register("second_name", { required: true })}
                          type="text"
                          className="w-full p-2 rounded-lg border border-gray-300 focus:border-secondary focus:outline-none"
                        />
                      </div>
                    </div>
                    <div className="w-1/2 px-3 mb-5">
                      <label className="text-xl font-semibold px-1">
                        Third name
                      </label>
                      <div className="flex">
                        <input
                          {...register("third_name", { required: true })}
                          type="text"
                          className="w-full p-2 rounded-lg border border-gray-300 focus:border-secondary focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex -mx-3">
                    <div className="w-full px-3 mb-5">
                      <label className="text-xl font-semibold px-1">
                        Username
                      </label>
                      <div className="flex">
                        <input
                          {...register("username", { required: true })}
                          type="text"
                          className="w-full p-2 rounded-lg border border-gray-300 focus:border-secondary focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex -mx-3">
                    <div className="w-full px-3 mb-12">
                      <label className="text-xl font-semibold px-1">
                        Password
                      </label>
                      <div className="flex">
                        <input
                          {...register("password", { required: true })}
                          type="password"
                          className="w-full p-2 rounded-lg border border-gray-300 focus:border-secondary focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex -mx-3">
                    <div className="w-full px-3 mb-12">
                      <label className="text-xl font-semibold px-1">
                        Confirm Password
                      </label>
                      <div className="flex">
                        <input
                          {...register("password_confirmation", {
                            required: true,
                          })}
                          type="password"
                          className="w-full p-2 rounded-lg border border-gray-300 focus:border-secondary focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex -mx-3">
                    <div className="w-full px-3 mb-12">
                      <label className="text-xl font-semibold px-1">
                        School
                      </label>
                      <div className="flex">
                        <input
                          {...register("school", { required: true })}
                          type="text"
                          className="w-full p-2 rounded-lg border border-gray-300 focus:border-secondary focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex -mx-3">
                    <div className="w-full px-3 mb-12">
                      <label className="text-xl font-semibold px-1">
                        Grade
                      </label>
                      <select
                        className="w-full p-2 rounded-lg border border-gray-300 focus:border-secondary focus:outline-none"
                        {...register("stage", { required: true })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          {" "}
                          Select Grade
                        </option>
                        <option value="9">G9</option>
                        <option value="10">G10</option>
                        <option value="11">G11</option>
                        <option value="12">G12</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex -mx-3">
                    <div className="w-full px-3 mb-12">
                      <label className="text-xl font-semibold px-1">
                        Course
                      </label>
                      <select
                        className="w-full p-2 rounded-lg border border-gray-300 focus:border-secondary focus:outline-none"
                        {...register("course_id", { required: true })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          {" "}
                          Select A Course
                        </option>
                        {CoursesData?.data?.map((course) => (
                          <option value={course.id}>{course.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="flex -mx-3">
                    <div className="w-full px-3 mb-12">
                      <label className="text-xl font-semibold px-1">
                        Mobile
                      </label>
                      <div className="flex">
                        <PhoneInput
                          country={"253"} // Use a valid ISO 2-letter country code
                          value={countrySelected}
                          onChange={(phone) => setCountrySelected(phone)}
                          className="w-full "
                          inputProps={{
                            name: "mobile",
                            value: mobile,
                            onChange: (e) => setMobile(e.target.value),
                            placeholder: "100 123 4567",
                            className:
                              "pl-[50px!important] py-2 w-full  rounded-lg border border-gray-300 focus:border-secondary focus:outline-none",
                            required: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex -mx-3">
                    <div className="w-full px-3 mb-12">
                      <label className="text-xl font-semibold px-1">
                        Parent Mobile
                      </label>
                      <div className="flex items-center">
                        <PhoneInput
                          country={"253"} // Use a valid ISO 2-letter country code
                          value={countryParentSelected}
                          onChange={(phone) => setCountryParentSelected(phone)}
                          className="w-full"
                          inputProps={{
                            name: "mobile",
                            value: parentMobile,
                            onChange: (e) => setParentMobile(e.target.value),
                            placeholder: "100 123 4567",
                            className:
                              "pl-[50px!important] py-2  w-full  rounded-lg border border-gray-300 focus:border-secondary focus:outline-none",
                            required: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex -mx-3">
                    <div className="w-full px-3 mb-12">
                      <div className="flex items-center">
                        <p className="text-[#3f3f3]">
                          you have account ?{" "}
                          <Link
                            to="/login"
                            className="border-b border-b-black text-bold text-secondary "
                          >
                            Login
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex -mx-3">
                    <div className="w-full px-3">
                      <button
                        className="block w-full max-w-xs mx-auto bg-secondary border border-secondary  hover:bg-white hover:text-secondary text-white rounded-lg px-3 py-3 font-semibold"
                        type="submit"
                      >
                        Register
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <main className="flex flex-col items-center justify-center gap-0 w-full py-24 bg-[#F2F2F2] dark:bg-dark">
      {studentInfoLoading && <Loader />}

      <div
        className={`md:max-w-3/4 flex flex-col w-3/4 ${
          studentInfoLoading && "hidden"
        }`}
      >
        {edit ||
          (addNew && (
            <HelmetTags
              title="Students | Mr.Ahmed Bayoumymy"
              description="Create an account | Mr.Ahmed Bayoumymy"
              index
            ></HelmetTags>
          ))}

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="mx-auto max-w-[590px] w-full flex flex-col items-start justify-center gap-6 rounded-3xl bg-white p-16 shadow-3xl text-black"
        >
          {!edit && !addNew ? (
            <>
              <HelmetTags
                title="Register | Mr.Ahmed Bayoumy"
                description="Register | Mr.Ahmed Bayoumymy"
                index
              >
                <link
                  rel="canonical"
                  href="https://api.bayoumymath.com/register"
                />
              </HelmetTags>

              <div className="mb-24 flex flex-col items-center  w-full justify-center">
                <Link className=" w-[240px] h-[128px] " to="/">
                  <img
                    className="h-full w-full"
                    src="https://api.bayoumymath.com/img/logo.png"
                    alt="logo"
                  />
                </Link>
                <h1 className="mb-5 mt-1 text-black text-4xl font-semibold leading-[1.2]">
                  create new account
                </h1>
              </div>
            </>
          ) : null}

          {/** Name filed */}
          <div className="flex flex-row-reverse  items-start justify-between gap-6 md:flex-col-reverse ">
            {/** Third Name filed */}
            <div className="flex w-1/2 flex-col items-start justify-center  gap-2   md:w-full">
              <label
                className="text-start flex items-center gap-2 text-[#555555]"
                htmlFor="third_name"
              >
                Third name{" "}
              </label>
              <input
                className="border-2 border-b-4 border-gray-400 border-b-[#adadad] px-2 py-3 focus:border-b-[#F35F23] outline-none w-full pl-4"
                type="text"
                id="third_name"
                name="third_name"
                autoComplete="on"
                {...register("third_name", {
                  required: true,
                  pattern: /^[A-Za-z\s]+$/u,
                  maxLength: 15,
                  minLength: 3,
                })}
              />
              {errors.third_name && (
                <p className="w-full text-start text-[12px] text-blue-900  ">
                  {errors.third_name.type === "required" &&
                    "Please fill out this field"}
                  {errors.third_name.type === "pattern" &&
                    "Only English letters are allowed"}
                  {errors.third_name.type === "maxLength" &&
                    "The maximum number of characters is 15 characters"}
                  {errors.third_name.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.third_name && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.third_name[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.third_name && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.third_name[0]}
                    </p>
                  )
              }
            </div>

            {/** Second Name filed */}
            <div className=" flex w-1/2 flex-col items-start justify-center  gap-2    md:w-full">
              <label
                className="text-start flex items-center gap-2 text-[#555555]"
                htmlFor="second_name"
              >
                Second name
              </label>
              <input
                className="border-2 border-b-4 border-gray-400 border-b-[#adadad] px-2 py-3 focus:border-b-[#F35F23] outline-none  w-full pl-4"
                type="text"
                id="second_name"
                name="second_name"
                autoComplete="on"
                {...register("second_name", {
                  required: true,
                  pattern: /^[A-Za-z\s]+$/u,

                  maxLength: 15,
                  minLength: 3,
                })}
              />
              {errors.second_name && (
                <p className="w-full text-start text-[12px] text-blue-900  ">
                  {errors.second_name.type === "required" &&
                    "Please fill out this field"}
                  {errors.second_name.type === "pattern" &&
                    "Only English letters are allowed"}
                  {errors.second_name.type === "maxLength" &&
                    "The maximum number of characters is 15 characters"}
                  {errors.second_name.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}

              {
                //!-------server errors -----

                state.errors && state.errors.second_name && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.second_name[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.second_name && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.second_name[0]}
                    </p>
                  )
              }
            </div>

            {/** First Name filed */}
            <div className=" flex w-1/2 flex-col items-start justify-center  gap-2  md:w-full relative">
              <label
                className="text-start flex items-center gap-2 text-[#555555]"
                htmlFor="first_name"
              >
                First name
              </label>
              <input
                className=" border-2 border-b-4 border-gray-400 border-b-gray-500 px-2 py-3 focus:border-b-[#F35F23] outline-none  w-full pl-4 "
                type="text"
                id="first_name"
                name="first_name"
                autoComplete="on"
                {...register("first_name", {
                  required: true,
                  pattern: /^[A-Za-z\s]+$/u,
                  maxLength: 15,
                  minLength: 3,
                })}
              />
              {errors.first_name && (
                <p className="w-full text-start text-[12px] text-blue-900  ">
                  {errors.first_name.type === "required" &&
                    "Please fill out this field"}
                  {errors.first_name.type === "pattern" &&
                    "Only English letters are allowed"}
                  {errors.first_name.type === "maxLength" &&
                    "The maximum number of characters is 15 characters"}
                  {errors.first_name.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}

              {
                //!-------server errors -----

                state.errors && state.errors.first_name && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.first_name[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.first_name && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.first_name[0]}
                    </p>
                  )
              }
            </div>
          </div>

          {/**  passwords fields */}
          {!edit && (
            <div className="flex w-full gap-6 flex-col">
              {/** Password  */}
              <div className="w-full">
                <div className="relative flex flex-col items-start justify-center gap-2 ">
                  <label
                    className="text-start flex items-center gap-2 text-[#555555]"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <div
                    //!input password relative
                    className="relative-hide relative h-auto w-full"
                  >
                    <input
                      id="password"
                      className="border-2 border-b-4 border-gray-400 border-b-[#adadad] px-2 py-3 focus:border-b-[#F35F23] outline-none pl-4   w-full"
                      type={`${state.hide ? "text" : "password"}`}
                      name="password"
                      autoComplete="on"
                      {...register("password", {
                        required: true,
                        maxLength: 25,
                        minLength: 6,
                      })}
                    />

                    <div
                      //!eye icons
                      onClick={() =>
                        dispatch({
                          type: "setHide",
                        })
                      }
                      className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                    >
                      {state.hide ? (
                        <FontAwesomeIcon
                          className="h-full w-full"
                          icon={faEye}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="h-full w-full"
                          icon={faEyeSlash}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {errors.password && (
                  <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                    {errors.password.type === "required" &&
                      "Please fill out this field"}
                    {errors.password.type === "maxLength" &&
                      "The maximum number of characters is 25 characters"}
                    {errors.password.type === "minLength" &&
                      "The minimum number of characters is 6 characters"}
                  </p>
                )}

                {
                  //!-------server errors -----

                  state.errors && state.errors.password && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.password[0]}
                    </p>
                  )
                }
              </div>

              {/** Confirm Password  */}
              <div className="w-full">
                <div className="relative flex flex-col items-start justify-center gap-2     ">
                  <label
                    className="text-start flex items-center gap-2 text-[#555555]"
                    htmlFor="password_confirmation"
                  >
                    Confirm the password
                  </label>

                  <div
                    //!input password relative
                    className="relative-hide relative h-auto w-full"
                  >
                    <input
                      id="password_confirmation"
                      className="border-2 border-b-4 border-gray-400 border-b-[#adadad] px-2 py-3 focus:border-b-[#F35F23] outline-none pl-4   w-full"
                      type={`${state.hide ? "text" : "password"}`}
                      name="password_confirmation"
                      autoComplete="on"
                      {...register("password_confirmation", {
                        required: true,
                        maxLength: 25,
                        minLength: 6,
                        validate: (value) => value === getValues("password"),
                      })}
                    />

                    <div
                      //!eye icons
                      onClick={() =>
                        dispatch({
                          type: "setHide",
                        })
                      }
                      className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                    >
                      {state.hide ? (
                        <FontAwesomeIcon
                          className="h-full w-full"
                          icon={faEye}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="h-full w-full"
                          icon={faEyeSlash}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {errors.password_confirmation && (
                  <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                    {errors.password_confirmation.type === "required" &&
                      "Please fill out this field"}
                    {errors.password_confirmation.type === "maxLength" &&
                      "The maximum number of characters is 25 characters"}
                    {errors.password_confirmation.type === "minLength" &&
                      "The minimum number of characters is 6 characters"}
                    {errors.password_confirmation.type === "validate" &&
                      "Passwords do not match"}
                  </p>
                )}

                {
                  //!-------server errors -----

                  state.errors && state.errors.password_confirmation && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.password_confirmation[0]}
                    </p>
                  )
                }
              </div>
            </div>
          )}

          {edit && (
            <div className="flex flex-row-reverse w-full items-start justify-between gap-16 md:flex-col md:gap-6 ">
              {/** student WhatsUp field */}

              <div className="w-full   md:w-full">
                <div className="flex w-full flex-col items-start justify-center gap-2">
                  <label className="text-start" htmlFor="mobile">
                    Student phone number
                  </label>
                  <div className="relative w-full">
                    <input
                      className="border-2 border-b-4 border-gray-400 border-b-[#adadad] px-2 py-3 focus:border-b-[#F35F23] outline-none pl-4   w-full"
                      type="text"
                      id="mobile"
                      placeholder="Student phone number"
                      name="mobile"
                      autoComplete="on"
                      {...register("mobile", {
                        required: true,
                        pattern: /^[\d]{11}/,
                        maxLength: 11,
                      })}
                    />
                    <FontAwesomeIcon
                      className="absolute bottom-1/2 right-4 h-6  w-7 translate-y-1/2"
                      icon={faWhatsapp}
                    />
                  </div>
                </div>
                {errors.mobile && (
                  <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                    {errors.mobile.type === "required" &&
                      "Please fill out this field"}
                    {errors.mobile.type === "pattern" &&
                      "Please enter an 11-digit phone number"}
                    {errors.mobile.type === "maxLength" &&
                      "Please enter an 11-digit phone number"}
                  </p>
                )}

                {
                  //!-------server errors -----

                  state.errors && state.errors.mobile && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.mobile[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.mobile && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {StudentEditErrors?.response?.data.errors.mobile[0]}
                      </p>
                    )
                }
              </div>
            </div>
          )}

          {/** Governorate and City fields */}
          {false && (
            <div className="flex flex-row-reverse w-full items-start justify-between gap-6 md:flex-col-reverse">
              <div className=" flex w-1/2 md:w-full flex-col items-start justify-center gap-2">
                {/* {watchGovernorate ? ( */}
                <>
                  <label
                    className="text-start flex items-center gap-2"
                    htmlFor="area"
                  >
                    Cities
                  </label>
                  <select
                    name="area"
                    id="area"
                    defaultValue="15 مايو"
                    {...register("area", { required: true })}
                  >
                    {state?.cities?.map((city) => (
                      <option
                        key={city.id}
                        id={city.id}
                        value={city.id}
                        selected={
                          StudentInfo?.data[0]?.area
                            ? StudentInfo?.data[0]?.area === city.id
                            : city.id === 1
                        }
                      >
                        {city.city_name_ar}
                      </option>
                    ))}
                  </select>
                  {errors.area && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {errors.area.type === "required" &&
                        "Please fill out this field"}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    state.errors && state.errors.area && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {state.errors.area[0]}
                      </p>
                    )
                  }
                  {
                    //!-------Editing server errors -----

                    StudentEditErrors?.response?.data?.errors &&
                      StudentEditErrors?.response?.data?.errors?.area && (
                        <p className="w-full text-start text-[12px] text-blue-900  ">
                          {StudentEditErrors?.response?.data.errors.area[0]}
                        </p>
                      )
                  }
                </>
                {/* ) : null} */}
              </div>

              {/** City filed */}
              <div className=" flex w-1/2 md:w-full flex-col items-start justify-center gap-2 md:w-full ">
                {/* {watchCountries ? ( */}
                <>
                  <label
                    className="text-start flex items-center gap-2"
                    htmlFor="governorate"
                  >
                    Governorate
                  </label>
                  <select
                    name="governorate"
                    id="governorate"
                    defaultValue="القاهرة"
                    {...register("governorate", { required: true })}
                  >
                    {state?.governorate?.map((city) => (
                      <option
                        key={city.id}
                        value={city.state}
                        selected={
                          StudentInfo?.data[0]?.governorate
                            ? StudentInfo?.data[0]?.governorate === city.state
                            : city.id === 1
                        }
                      >
                        {city.state}
                      </option>
                    ))}
                  </select>
                  {errors.governorate && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {errors.governorate.type === "required" &&
                        "Please fill out this field"}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    state.errors && state.errors.governorate && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {state.errors.governorate[0]}
                      </p>
                    )
                  }
                  {
                    //!-------Editing server errors -----

                    StudentEditErrors?.response?.data?.errors &&
                      StudentEditErrors?.response?.data?.errors
                        ?.governorate && (
                        <p className="w-full text-start text-[12px] text-blue-900  ">
                          {
                            StudentEditErrors?.response?.data.errors
                              .governorate[0]
                          }
                        </p>
                      )
                  }
                </>
                {/* ) : null} */}
              </div>
            </div>
          )}

          {false && (
            <div className="flex flex-row w-full items-start justify-between gap-16 md:flex-col md:gap-6">
              <div className="flex w-1/2 flex-col  items-center    md:w-full">
                <label
                  className="text-start flex items-center gap-2"
                  htmlFor="Type"
                >
                  Type
                </label>
                <div className="flex w-full gap-20 pb-1 sm:flex-col-reverse">
                  {/** ig  */}
                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="ig"
                      value="0"
                      {...register("edu_type", { required: true })}
                    />
                    <label className="cursor-pointer text-start" htmlFor="ig">
                      IG
                    </label>
                  </div>

                  {/** national */}
                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="national"
                      value="1"
                      {...register("edu_type", { required: true })}
                    />
                    <label
                      className="cursor-pointer text-start"
                      htmlFor="national"
                    >
                      National
                    </label>
                  </div>
                </div>
                {errors.section && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.section.edu_type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.edu_type && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.edu_type[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.edu_type && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {StudentEditErrors?.response?.data.errors.edu_type[0]}
                      </p>
                    )
                }
              </div>
              {/** Stage filed */}

              {watchType ? (
                <div className=" flex w-1/2 flex-col items-start justify-center gap-2 md:w-full">
                  <label
                    className="text-start flex items-center gap-2"
                    htmlFor="stage"
                  >
                    Stage
                  </label>
                  <select
                    name="stage"
                    id="stage"
                    placeholder="Select Stage"
                    {...register("stage", { required: true })}
                  >
                    {watchType === "0" ? (
                      <>
                        <option value="9">Nine Year</option>
                        <option value="10">Ten Year</option>
                      </>
                    ) : (
                      <>
                        {/* {edit ||
                        (addNew && (
                          <>
                            <option value="22">Second preparatory</option>
                            <option value="33">Third preparatory </option>
                          </>
                        ))} */}
                        <option value="22">Second preparatory</option>
                        <option value="33">Third preparatory </option>

                        <option value="1">First Secondary</option>
                        <option value="2">Second Secondary</option>
                        <option value="3">Third Secondary</option>
                      </>
                    )}
                  </select>
                  {errors.stage && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {errors.stage.type === "required" &&
                        "Please fill out this field"}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    state.errors && state.errors.stage && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {state.errors.stage[0]}
                      </p>
                    )
                  }
                  {
                    //!-------Editing server errors -----

                    StudentEditErrors?.response?.data?.errors &&
                      StudentEditErrors?.response?.data?.errors?.stage && (
                        <p className="w-full text-start text-[12px] text-blue-900  ">
                          {StudentEditErrors?.response?.data.errors.stage[0]}
                        </p>
                      )
                  }
                </div>
              ) : null}
            </div>
          )}

          {/** sciences or math fields */}

          <div className="flex w-full flex-col  items-center    md:w-full">
            {+watchLevel === 2 || +watchLevel === 3 ? (
              <>
                <div className="flex w-full items-end gap-20 pb-1 sm:flex-col-reverse">
                  {/** scientific  */}

                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="scientific"
                      value="scientific"
                      {...register("section", { required: true })}
                    />
                    <label
                      className="cursor-pointer text-start"
                      htmlFor="scientific"
                    >
                      Scientific
                    </label>
                  </div>

                  {/** arts  */}
                  <div className=" flex  items-center justify-center gap-2 ">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="arts"
                      value="arts"
                      {...register("section", { required: true })}
                    />
                    <label className="cursor-pointer text-start" htmlFor="arts">
                      Arts
                    </label>
                  </div>
                </div>
                {errors.section && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.section.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.section && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.section[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.section && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {StudentEditErrors?.response?.data.errors.section[0]}
                      </p>
                    )
                }
              </>
            ) : (
              ""
            )}
          </div>

          {/** groups and center or group  fields */}
          {false && (
            <div className="flex flex-row w-full items-start justify-between gap-16 md:flex-col md:gap-6">
              {/** center or group */}
              <div className=" flex w-1/2 md:w-full flex-col items-start justify-center gap-2 ">
                <label
                  className="text-start flex items-center gap-2"
                  htmlFor="is_online"
                >
                  Place
                </label>
                <select
                  name="is_online"
                  id="is_online"
                  {...register("is_online", { required: true })}
                >
                  <option value="1">Online</option>
                  <option value="0">Center</option>
                </select>
                {errors.is_online && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.is_online.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.is_online && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.is_online[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.is_online && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {StudentEditErrors?.response?.data.errors.is_online[0]}
                      </p>
                    )
                }
              </div>

              <div className=" flex w-1/2 flex-col items-start justify-center gap-2    md:w-full">
                {watchPlace === "0" || watchPlace === 0 ? (
                  <>
                    <label htmlFor="group_id">Group</label>
                    <select
                      name="group_id"
                      id="group_id"
                      {...register("group_id", {
                        required: edit || addNew ? false : true,
                      })}
                    >
                      {state.groups
                        ?.filter(
                          (group) =>
                            parseInt(group.stage) === parseInt(watchLevel)
                        )
                        .map((group) => (
                          <option key={group.id} value={group.id}>
                            {group.name}
                          </option>
                        ))}
                    </select>

                    {errors.group_id && (
                      <p className="w-full text-end text-[12px] text-red-900  ">
                        {errors.group_id.type === "required" &&
                          "برجاء ملئ هذا الحقل"}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      state.errors && state.errors.group_id && (
                        <p className="w-full text-end text-[12px] text-red-900  ">
                          {state.errors.group_id[0]}
                        </p>
                      )
                    }
                    {
                      //!-------Editing server errors -----

                      StudentEditErrors?.response?.data?.errors &&
                        StudentEditErrors?.response?.data?.errors?.group_id && (
                          <p className="w-full text-end text-[12px] text-red-900  ">
                            {
                              StudentEditErrors?.response?.data.errors
                                .group_id[0]
                            }
                          </p>
                        )
                    }
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}

          {/** active and magnetic_id fields */}
          {edit && (
            <div className="flex flex-row-reverse w-full items-center justify-between gap-16 md:flex-col md:gap-6">
              {/** active filed */}
              <div className=" active flex w-1/2 flex-col items-end justify-center gap-2 md:w-full ">
                <div className="flex w-full items-center justify-end gap-10 md:justify-center">
                  <label className="visibility-switch order-2 text-start">
                    <input
                      className=""
                      id="active"
                      name="active"
                      {...register("active", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate order-1 text-start"
                      htmlFor="active"
                    >
                      Activation
                    </label>
                  </div>
                </div>

                {errors.active && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors.active.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.active && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.active[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.active && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {StudentEditErrors?.response?.data.errors.active[0]}
                      </p>
                    )
                }
              </div>
              {/**magnetic_id */}
              <div className=" flex w-1/2 flex-col items-start justify-center  gap-2    md:w-full">
                <label className="text-start" htmlFor="magnetic_id">
                  Activation card
                </label>
                <input
                  className="border-2 border-b-4 border-gray-400 border-b-[#adadad]px-2 py-3 focus:border-b-[#F35F23] outline-none pl-4    w-full"
                  type="text"
                  id="magnetic_id"
                  placeholder="Activation card"
                  name="magnetic_id"
                  autoComplete="on"
                  {...register("magnetic_id", {
                    pattern: /^[A-Za-z0-9-_]*$/,
                    minLength: 3,
                  })}
                />
                {errors.magnetic_id && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.magnetic_id.type === "required" &&
                      "Please fill out this field"}
                    {errors.magnetic_id.type === "pattern" &&
                      "Only English letters and numbers are allowed"}
                    {errors.magnetic_id.type === "maxLength" &&
                      "The maximum number of characters is 20 characters"}
                    {errors.magnetic_id.type === "minLength" &&
                      "Please write at least 3 characters"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.magnetic_id && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.magnetic_id[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.magnetic_id && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {
                          StudentEditErrors?.response?.data.errors
                            .magnetic_id[0]
                        }
                      </p>
                    )
                }
              </div>
            </div>
          )}

          {/* account */}
          {/*  <div className="flex w-full items-start justify-between gap-12 md:flex-col  md:gap-6">
            <div className="flex w-full flex-col items-start justify-center gap-2">
              <label className="text-start" htmlFor="account">
                Account (Facebook or Instagram)
              </label>
              <input
                className="border-2 border-b-4 border-gray-400 border-b-[#adadad] px-2 py-3 focus:border-b-[#F35F23] outline-none pl-4    w-full"
                type="url"
                id="account"
                placeholder="Account"
                name="account"
                autoComplete="on"
                {...register("account", {
                  required: true,
                  minLength: 4,
                  pattern: {
                    value:
                      /^(https?:\/\/)?([\w\d-]+\.)+[\w-]+(\/[\w\d-./?%&=]*)?$/,
                    message: "Please enter a valid URL",
                  },
                })}
              />
              {errors.account && (
                <p className="w-full text-start text-[12px] text-blue-900  ">
                  {errors.account.type === "required" &&
                    "Please fill out this field"}

                  {errors.account.type === "minLength" &&
                    "Please write at least 4 characters"}
                  {errors.account.type === "pattern" && errors.account.message}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.account && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.account[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.school && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.school[0]}
                    </p>
                  )
              }
            </div>
          </div> */}

          {edit && (
            <div className="flex w-full items-start justify-between gap-12 md:flex-col  md:gap-6">
              <div className="flex w-full flex-col items-start justify-center gap-2">
                <label className="text-start" htmlFor="std_id">
                  Student ID
                </label>
                <input
                  className="border-2 border-b-4 border-gray-400 border-b-[#adadad] px-2 py-3 focus:border-b-[#F35F23] outline-none pl-4 w-full"
                  type="text"
                  id="std_id"
                  placeholder="Student ID"
                  name="std_id"
                  autoComplete="on"
                  {...register("std_id", {
                    required: false,
                  })}
                />
                {errors.std_id && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {errors.std_id.type === "required" &&
                      "Please fill out this field"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.std_id && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {state.errors.std_id[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.school && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {StudentEditErrors?.response?.data.errors.school[0]}
                      </p>
                    )
                }
              </div>
            </div>
          )}
          <div className="flex w-full items-start justify-between gap-12 md:flex-col  md:gap-6">
            {/** School  */}
            <div className="flex w-full flex-col items-start justify-center gap-2">
              <label className="text-start text-[#555555]" htmlFor="school">
                School{" "}
              </label>
              <input
                className="border-2 border-b-4 border-gray-400 border-b-[#adadad] px-2 py-3 focus:border-b-[#F35F23] outline-none pl-4    w-full"
                type="text"
                id="school"
                name="school"
                autoComplete="on"
                {...register("school", {
                  required: true,
                  maxLength: 35,
                  minLength: 4,
                })}
              />
              {errors.school && (
                <p className="w-full text-start text-[12px] text-blue-900  ">
                  {errors.school.type === "required" &&
                    "Please fill out this field"}

                  {errors.school.type === "maxLength" &&
                    "The maximum number of characters is 35 characters"}
                  {errors.school.type === "minLength" &&
                    "Please write at least 4 characters"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.school && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.school[0]}
                  </p>
                )
              }
              {
                //!-------Editing server errors -----

                StudentEditErrors?.response?.data?.errors &&
                  StudentEditErrors?.response?.data?.errors?.school && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {StudentEditErrors?.response?.data.errors.school[0]}
                    </p>
                  )
              }
            </div>
          </div>
          <div className="flex w-full items-start justify-between gap-12 flex-col  md:gap-6 mt-5">
            <div className=" space-y-5 border-b-4 border-b-[#adadad] w-full ">
              <label htmlFor="" className="w-fit">
                Mobile
              </label>
              <input
                id="phone"
                type="tel"
                value=""
                required=""
                autocomplete="mobile"
                autofocus=""
                data-intl-tel-input-id="0"
                placeholder="0100 123 4567"
                className="border-2 outline-none border-gray-400 py-2 px-2 w-full"
              />
            </div>
            <div className=" space-y-5  border-b-4 border-b-[#adadad] w-full">
              <label htmlFor="" className="w-fit">
                Parent Mobile
              </label>
              <input
                id="phone2"
                type="tel"
                value=""
                required=""
                autocomplete="mobile"
                autofocus=""
                data-intl-tel-input-id="1"
                placeholder="0100 123 4567"
                className="border-2 outline-none border-gray-400 py-2 px-2 w-full"
              />
            </div>
          </div>

          {/** User Image  */}
          {false && (
            <div className="flex w-full flex-col items-end gap-2">
              <label
                className="text-start flex items-center gap-2"
                htmlFor="profile_pic"
              >
                Upload a personal photo (Max Size 50 MB only Jpg/Jpeg/Png/Webp )
              </label>

              <input
                id="profile_pic"
                className="border-2 border-b-4 border-gray-400 border-b-gray-600 px-2 py-3 focus:border-b-[#F35F23] outline-none pl-4    w-full"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/webp"
                name="profile_pic"
                {...register("profile_pic", {
                  required: false,
                  validate: (value) => !(value[0]?.size > 50000000),
                })}
              />

              {errors.profile_pic && (
                <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                  {errors.profile_pic.type === "required" &&
                    "Please add a personal photo"}
                  {errors.profile_pic.type === "validate" &&
                    "Maximum image size is 50MB"}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.profile_pic && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {state.errors.profile_pic[0]}
                  </p>
                )
              }
            </div>
          )}
          {/** Submit Button */}
          <button
            disabled={!isValid || state.submitLoading}
            className="mt-6  text-white bg-[#F35F23] px-[12px] py-4 mx-auto"
            type="submit"
          >
            {state.submitLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : edit ? (
              "Modify data"
            ) : (
              "Register"
            )}
          </button>
          {!edit && (
            <div className="pt-20 pb-6 mx-auto w-full flex items-center justify-center gap-2">
              <p>you have account ?</p>
              <a href="/login">Login</a>
            </div>
          )}
        </form>
        {!edit ||
          (addNew && (
            <div className="my-10 w-full text-center text-[14px]  ">
              Already have an account?
              <Link className=" font-bold" to="/login">
                Sign in now
              </Link>
            </div>
          ))}
      </div>
    </main>
  );
}

export default Register;

import { Link } from "react-router-dom";
import HelmetTags from "../../../MainComponents/HelmetTags";

export default function History() {
  return (
    <section className="px-10 pt-20  w-full min-h-screen space-y-5 flex flex-col items-center justify-center gap-40">
      <HelmetTags title={" History | Mr Bayoumy"}></HelmetTags>
      <h1 className="text-5xl font-semibold text-center">Mistakes History</h1>
      <div className=" flex md:flex-col w-full md:justify-center md:gap-6 md:items-center justify-between items-center h-full md:max-w-none max-w-[500px] mx-auto scale-150">
        <Link
          className="text-white bg-blue-500 hover:bg-secondary rounded-3xl px-4 py-3 duration-200 "
          to={"/mistakehistory/view/assignments"}
        >
          Assignemnts
        </Link>
        <Link
          className="text-white bg-blue-500 hover:bg-secondary rounded-3xl px-4 py-3 duration-200"
          to={"/mistakehistory/view/skills"}
        >
          Skills
        </Link>
        <Link
          className="text-white bg-blue-500 hover:bg-secondary rounded-3xl px-4 py-3 duration-200"
          to={"/mistakehistory/view/weeklycheckpoint"}
        >
          Weekly Check Point
        </Link>
      </div>
    </section>
  );
}

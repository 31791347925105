import { motion } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "./GlobalContext.jsx";
import { Popconfirm } from "antd";
import { useEffect, useState } from "react";
import useAxios from "./Hooks/useAxios.jsx";
import img from "./7.jpg";
import YoutubeThumb from "../../src/assets/youtube-thumb.png";

function VideoCard({ video, admin, reFetch, setReFetch, setShowVideo, type }) {
  const AuthState = useAuthState();
  const navigate = useNavigate();
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!--------- delete Video -------

  const [deleteVideoSuccess, deleteVideoErrors, deleteloading] = useAxios(
    process.env.REACT_APP_SKILLS_VIDEOS_DELETE_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  function handleDeleteVideo(id) {
    setSubmitDelete({
      flag: "deleteVideo",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  useEffect(() => {
    //! refetch the data on success delete or accept request

    if (deleteVideoSuccess) {
      setReFetch(!reFetch);
    }
  }, [deleteVideoSuccess]);
  let src =
    video.is_youtube === 1
      ? `https://www.youtube.com/embed/${video.vdo_id}`
      : video.platform === "bunny"
        ? `https://iframe.mediadelivery.net/embed/234526/${video.vdo_id}`
        : video.platform === "vimeo"
          ? `https://player.vimeo.com/video/${video.vdo_id}`
          : `https://player.vdocipher.com/v2/?otp=${video.otp}&playbackInfo=${video.video}`;
  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="course flex  w-[400px]  flex-col justify-between rounded-[25px] border-2 border-secondary bg-white p-8 shadow-lg sm:h-[375px] sm:w-[300px]"
    >
      <div className="video relative h-[225px] w-full cursor-pointer overflow-hidden rounded-[20px] border-2 border-secondary sm:h-[160px]">
        <div className="video-wrapper h-full w-full  relative ">
          <img
            onClick={() => {
              setShowVideo({
                show: true,
                src: video.is_youtube === 1 ? "" : src,
                iframe: video.is_youtube === 1 ? video.iframe : "",
              });
            }}
            className="h-full w-full object-cover  duration-300 hover:scale-125"
            src={video.is_youtube === 1 ? YoutubeThumb : video.poster}
            alt={video.name}
          />
          {video.is_youtube === 0 && (
            <div className="text-white bg-secondary px-3 py-2 rounded-xl absolute top-5 right-0">
              {video?.duration}
            </div>
          )}
        </div>

        <FontAwesomeIcon
          className="pointer-events-none absolute  bottom-1/2 right-1/2 z-40 h-20 w-20 translate-x-1/2 translate-y-1/2 text-light duration-300 hover:scale-110 active:scale-90"
          icon={faCirclePlay}
          fade
        />
        <div className="stage pointer-events-none absolute left-4 top-4 flex h-10 w-fit items-center  justify-center rounded-xl bg-light px-3 text-xl  font-semibold">
          {video.is_youtube === 0 ? "vdocipher" : "youtube"}
        </div>

        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("video_show_creator")
          )) &&
          admin && (
            <div className="creator pointer-events-none absolute right-4 top-4 flex h-10 w-fit items-center justify-center rounded-xl bg-light px-3 text-xl font-semibold ">
              Added on {video.created_at}
            </div>
          )}
      </div>
      {/* <div className="course-details  hf mt-7 flex flex-col items-end justify-between"> */}
      {/* <h3
          className={`title mb-7 w-fit cursor-pointer text-secondary ${
            admin ? "text-end" : "text-center"
          }   text-2xl  font-bold`}
        >
          {video?.name?.substring(0, 33)}
        </h3>
        <p className="description text-end text-2xl font-semibold leading-relaxed text-secondary/70">
          {video?.description?.substring(0, 78)}
        </p> */}
      {/* </div> */}

      {admin && (
        <div
          className={`visibility-cta bg-red-0 mt-5 flex w-full border-t-2 border-secondary  ${
            admin ? "justify-between" : "justify-center"
          } items-center pt-8`}
        >
          <div className="admin-options  flex w-full justify-end gap-14">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("lecture_addvideos")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="red"
                cancelText="Cancel"
                color="#b1e8f4"
                //okButtonProps={{ loading: loading }}
                onConfirm={() => handleDeleteVideo(video.id)}
                title="Do you really want to delete the lecture?"
              >
                <button className=" rounded-2xl border-2 border-red-500 bg-red-500 px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                  Delete
                </button>
              </Popconfirm>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("lecture_addvideos")
              )) &&
              admin && (
                <Link
                  to={`/admin/${
                    !type ? "assignments" : "skills"
                  }/videos/edit-video/${video.id}`}
                  className="w-24 rounded-2xl border-2 border-blue-500 bg-blue-500 px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                >
                  Edit
                </Link>
              )}
          </div>
        </div>
      )}
    </motion.div>
  );
}

export default VideoCard;

import Navbar from "./Navbar";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";

function Layout() {
  const location = useLocation();
  return (
    <div className="watermark ">
      <div id="top"></div>
      <Navbar />
      
      <AnimatePresence mode="wait" className="min-h-screen">
        <Outlet key={location.pathname}/>
      </AnimatePresence>
      <Footer />
    </div>
  );
}

export default Layout;

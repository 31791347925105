import "../../src/SASS/_loader.scss";
function Loader() {
  return (
    <div className="w-full flex gap-5 justify-center items-center h-screen">
      <div className="loader"></div>
    </div>
  );
}

export default Loader;

import { useEffect, useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useForm } from "react-hook-form";
import { Loader } from "../../../../MainComponents";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";

export default function AdminAssignments() {
  const [SelectedRows, setSelectedRows] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onTouched" });
  const [submitCopy, setSubmitCopy] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  // Axios hook for copying assignment
  const [SuccessAssignmentCopy, ErrorAssignmentCopy, LoadingAssignmentCopy] =
    useAxios(
      "https://api.bayoumymath.com/api/admin/assignments/copy",
      "POST",
      submitCopy.flag,
      submitCopy.dependency,
      submitCopy.data,
      true
    );

  // Axios hook for fetching courses
  const [dataCourses, errorCourses, loadingCourses] = useAxios(
    process.env.REACT_APP_ADMIN_GET_COURSES_API,
    "GET",
    "GET",
    ""
  );

  // Form submission handler
  const onSubmit = (data) => {
    if (SelectedRows.length === 0) return;
    if (SelectedRows.length > 1) {
      toast.error("please select only one Assignment");
      return;
    }

    const finalData = {
      course: data.course,
      newName: data.newName,
      id: SelectedRows[0]?.key, // Optional chaining for safety
    };
    setSubmitCopy({
      flag: "Copy",
      dependency: !submitCopy.dependency, // Toggle dependency
      data: finalData,
    });
  };

  // Table hook
  const table = useTable(
    "assigments",
    "",
    "",
    submitCopy.dependency,
    "",
    "",
    "",
    "",
    "",
    "",
    setSelectedRows
  );

  // Error state for courses
  if (errorCourses) {
    return (
      <p className="text-red-500">
        Failed to load courses. Please try again later.
      </p>
    );
  }

  return (
    <>
      <HelmetTags title="Assignments | Mr. Ahmed Bayoumy" />
      <section className=" h-auto w-full dark:bg-dark dark:text-light px-10 py-10">
        <Link
          className="bg-secondary px-4 py-3 border border-secondary text-white hover:text-secondary hover:bg-white duration-200 rounded-3xl"
          to="/admin/assignment/add"
        >
          <FontAwesomeIcon icon={faPlus} /> Add Assignment
        </Link>

        {SelectedRows.length > 0 && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="form shadow-3xl !gap-10 mt-5 max-w-[600px]"
          >
            <select
              className="signin-inputs"
              {...register("course", { required: "Course is required" })}
            >
              {dataCourses?.data?.map((item) => (
                <option value={item.key} key={item.key}>
                  {item.name}
                </option>
              ))}
            </select>
            {errors.course && (
              <p className="text-red-500">{errors.course.message}</p>
            )}

            <input
              type="text"
              placeholder="New Name"
              className="signin-inputs"
              {...register("newName", { required: "Name is required" })}
            />
            {errors.newName && (
              <p className="text-red-500">{errors.newName.message}</p>
            )}

            <button type="submit" className="submit">
              {LoadingAssignmentCopy ? "Copying..." : "Copy"}
            </button>

            {ErrorAssignmentCopy && (
              <p className="text-red-500 mt-2">
                Failed to copy assignment. Please try again.
              </p>
            )}
            {SuccessAssignmentCopy && (
              <p className="text-green-500 mt-2">
                Assignment copied successfully!
              </p>
            )}
          </form>
        )}

        <div className="h-fit w-full py-3 mt-5">{table}</div>
      </section>
    </>
  );
}

import { useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useForm } from "react-hook-form";
export default function AdminWeekly() {
  const table = useTable("weekly");
  return (
    <>
      <HelmetTags title="Weekly | Mr.Ahmed Bayoumy"></HelmetTags>
      <section
        className={`flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light py-10 px-10`}
      >
        <div className="h-fit  w-full py-20">{table}</div>
      </section>
    </>
  );
}

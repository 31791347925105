import { useParams } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { useState, useEffect } from "react";
import QuestionModalAnswer from "../../Admin/Dashboard/Exams/QuestionModalAnswer";
import { Loader } from "../../../MainComponents";
import { useAuthState } from "../../../MainComponents/GlobalContext";
export default function HistoryModalAnswer() {
  const [collapse, setCollapse] = useState(false);
  const [activeQuestionId, setActiveQuestionId] = useState(0);
  const AuthState = useAuthState();
  const [refetch, setReFetch] = useState(false);
  const [questionID, setQuestionID] = useState(3092);
  const { Type } = useParams();
  const [hasLoaded, setHasLoaded] = useState(false);

  const [modalAnswerData, modalAnswerErrors, modalAnswerLoading] = useAxios(
    `${process.env.REACT_APP_STUDENT_HISTORY_VIEW_GET_API}${Type}`,
    "GET",
    Type,
    refetch
  );
  const [questionAnswerData, questionAnswerErrors, questionAnswerLoading] =
    useAxios(
      `${process.env.REACT_APP_STUDENT_HISTORY_VIEW_GET_API}${Type}/${questionID}`,
      "GET",
      questionID,
      questionID
    );

  const handleQuestionClick = (id, questionID) => {
    setCollapse(true);
    setActiveQuestionId(id);
    setQuestionID(questionID);
  };

  useEffect(() => {
    if (!modalAnswerLoading && !hasLoaded) {
      setHasLoaded(true);
    }
  }, [modalAnswerLoading, hasLoaded]);

  if (!hasLoaded && modalAnswerLoading) return <Loader />;

  return (
    <section className="left-response-container flex items-start md:w-full">
      <HelmetTags title="History Mistaker | Modal Answer" />
      <QuestionModalAnswer
        collapse={collapse}
        onCloseCollapse={() => setCollapse(false)}
        activeQuestionId={activeQuestionId}
        question={questionAnswerData?.data}
        view={true}
        hidden={false}
        loading={questionAnswerLoading}
        asked={0}
        essay={0}
      />
      <div className="all-questions flex h-full flex-col items-center overflow-y-auto  p-[40px] transition-all  duration-300 ease-in-out w-full md:order-2 md:w-full">
        <h1 className="text-2xl font-bold text-black dark:text-white ">
          Modal Answer
        </h1>
        <p className="my-[20px] text-center text-secondary dark:text-white">
          Click To The Number To Show The Modal
        </p>

        <div className="flex gap-5 flex-wrap">
          {modalAnswerData?.data?.map((question, index) => (
            <div
              key={index}
              className={`w-14 h-14 text-white flex items-center justify-center cursor-pointer ${
                question.visited === 1
                  ? "bg-gray-700"
                  : question.student_answer === null ||
                      question.student_answer === "Unanswered"
                    ? "bg-red-500"
                    : question.correct_answer === question.student_answer
                      ? "bg-green-500"
                      : "bg-yellow-500"
              }`}
              onClick={() => {
                handleQuestionClick(index, question.key);
                if (Type === "weeklycheckpoint") {
                  fetch(
                    "https://api.bayoumymath.com/api/weeklycheckpoint/visited",
                    {
                      method: "POST",
                      headers: {
                        Authorization: `Bearer ${AuthState?.userData?.student_token}`,
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        question_id: question?.visited_id,
                      }),
                    }
                  ).then(() => setReFetch((prev) => !prev));
                } else {
                  fetch(
                    "https://api.bayoumymath.com/api/assignment/questions/visited",
                    {
                      method: "POST",
                      headers: {
                        Authorization: `Bearer ${AuthState?.userData?.student_token}`,
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        question_id: question.key,
                        type: Type === "assignments" ? "assignment" : "skill",
                        is_essay: question.is_essay,
                      }),
                    }
                  ).then(() => setReFetch((prev) => !prev));
                }
              }}
            >
              {index + 1}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

import HelmetTags from "../../../MainComponents/HelmetTags.jsx";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import logoLight from "../../../assets/logo-light.png";
import useAxios from "../../../MainComponents/Hooks/useAxios.jsx";

function ForgetPassword() {
  const navigate = useNavigate();
  const [currentResetStep, setCurrentResetStep] = useState(1);
  const [seconds, setSeconds] = useState(900); // Initial countdown time in seconds
  const [hide, setHide] = useState(false);

  const [forgetPassword, setForgetPassword] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const mobile = watch("mobile");

  let api =
    currentResetStep === 1
      ? process.env.REACT_APP_STUDENT_FORGET_PASSWORD_API
      : currentResetStep === 2
        ? process.env.REACT_APP_STUDENT_FORGET_PASSWORD_SEND_OTP_API
        : currentResetStep === 3
          ? process.env.REACT_APP_STUDENT_CHANGE_PASSWORD_SEND_OTP_API
          : null;
  const [ForgetPasswordSuccess, ForgetPasswordErrors, ForgetPasswordLoading] =
    useAxios(
      api,
      "POST",
      forgetPassword.flag,
      forgetPassword.dependency,
      forgetPassword.data,
      true
    );

  const handleForgetPassword = (data) => {
    setForgetPassword({
      flag: "forgetPassword",
      dependency: !forgetPassword.dependency,
      data: data,
    });
  };

  // resend otp
  const [resendOtp, setResendOtp] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [ResendOtpSuccess, ResendOtpErrors, ResendOtpLoading] = useAxios(
    process.env.REACT_APP_STUDENT_FORGET_PASSWORD_API,
    "POST",
    resendOtp.flag,
    resendOtp.dependency,
    resendOtp.data,
    true
  );

  const handleResend = (data) => {
    setResendOtp({
      flag: "resendOtp",
      dependency: !resendOtp.dependency,
      data: { mobile },
    });
  };

  useEffect(() => {
    let interval;
    if (currentResetStep === 2 && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [currentResetStep, seconds]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    if (ForgetPasswordSuccess) {
      if (currentResetStep === 3) {
        navigate("/login");
      } else {
        setCurrentResetStep((current) => current + 1);
      }
    }
  }, [ForgetPasswordSuccess, navigate]);

  return (
    <section className="flex h-fit min-h-screen flex-col items-center justify-center gap-24 py-10 dark:bg-dark sm:py-14">
      <HelmetTags
        title="Reset password | Mr.Ahmed Bayoumy"
        description="Reset password | Mr.Ahmed Bayoumymy"
        index
      >
        <link rel="canonical" href="https://api.bayoumymath.com/login" />
      </HelmetTags>

      <div
        //!welcome-message
        className="welcome-message  flex flex-col items-center  justify-center"
      >
        <Link className="h-60 w-auto text-4xl " to="/">
          <img className="h-full w-full " src={logoLight} alt="logo" />
        </Link>
        <h1 className="mb-5 mt-10 font-bold ">
          Reset password | Mr.Ahmed Bayoumymy
        </h1>
        <h2 className="opacity-80">
          Please enter your mobile number and we will send you a password reset
          code
        </h2>
      </div>

      <div className="sign-in-form flex w-full flex-col items-center ">
        <form
          //!form forgot password
          onSubmit={handleSubmit(handleForgetPassword)}
          method="post"
          className="sm:max-w-3/4 flex w-[450px] max-w-[450px]  flex-col items-start  justify-center rounded-3xl bg-white  p-16 shadow-3xl sm:w-3/4"
        >
          {/**
           * //!Username filed
           *
           */}

          {currentResetStep === 1 && (
            <div
              className={`flex w-full flex-col items-end justify-center gap-2 `}
            >
              <label htmlFor="username">Phone number</label>
              <input
                placeholder="Phone number"
                className="signin-inputs w-full "
                type="text"
                id="mobile"
                name="mobile"
                autoComplete="on"
                {...register("mobile", {
                  required: true,
                  pattern: /^[\d]{11}/,
                  maxLength: 11,
                })}
              />
              {errors.mobile && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.mobile.type === "required" &&
                    "Please enter phone number"}
                  {errors.mobile.type === "pattern" &&
                    "This phone number is incorrect"}
                  {errors.mobile.type === "maxLength" &&
                    "This phone number is incorrect"}
                </p>
              )}
              {/* {
                //!--- server errors --------
                serverErrors && (
                  <p className="text-[12px] text-blue-900 ">{serverErrors}</p>
                )
              } */}
            </div>
          )}

          {currentResetStep === 2 && (
            <>
              <div className=" flex w-full flex-col items-end justify-center gap-2">
                <label htmlFor="otp">OTP</label>
                <input
                  placeholder="OTP"
                  className="signin-inputs w-full "
                  type="text"
                  id="otp"
                  name="otp"
                  autoComplete="on"
                  {...register("otp", {
                    required: true,
                  })}
                />
                {errors.otp && (
                  <p className="text-[12px] text-blue-900 ">
                    {errors.otp.type === "required" && "Please enter OTP"}
                  </p>
                )}
              </div>
              <button
                disabled={seconds > 0}
                className="w-full flex justify-end  mt-4 cursor-pointer text-[13px] underline"
                type="button"
                onClick={() => handleResend()}
              >
                {seconds === 0 ? "Resend" : formatTime(seconds)}
              </button>
            </>
          )}

          {currentResetStep === 3 && (
            <>
              <div className="w-full md:order-2 md:w-full">
                <div className="relative flex flex-col items-start justify-center gap-2 ">
                  <label
                    className="text-start flex items-center gap-2"
                    htmlFor="password"
                  >
                    Password (Min 6 characters and Max 25 characters)
                    <span className="text-red-700 text-3xl font-bold">*</span>
                  </label>
                  <div
                    //!input password relative
                    className="relative-hide relative h-auto w-full"
                  >
                    <input
                      id="password"
                      className="signin-inputs pl-4   w-full"
                      type={`${hide ? "text" : "password"}`}
                      placeholder="Password"
                      name="password"
                      autoComplete="on"
                      {...register("password", {
                        required: true,
                        maxLength: 25,
                        minLength: 6,
                      })}
                    />

                    <div
                      //!eye icons
                      onClick={() => setHide((hide) => !hide)}
                      className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                    >
                      {hide ? (
                        <FontAwesomeIcon
                          className="h-full w-full"
                          icon={faEye}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="h-full w-full"
                          icon={faEyeSlash}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {errors.password && (
                  <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                    {errors.password.type === "required" &&
                      "Please fill out this field"}
                    {errors.password.type === "maxLength" &&
                      "The maximum number of characters is 25 characters"}
                    {errors.password.type === "minLength" &&
                      "The minimum number of characters is 6 characters"}
                  </p>
                )}
              </div>

              {/** Confirm Password  */}
              <div className="w-full md:order-3 md:w-full">
                <div className="relative flex flex-col items-start justify-center gap-2 md:order-3 ">
                  <label
                    className="text-start flex items-center gap-2"
                    htmlFor="password_confirmation"
                  >
                    Confirm the password
                    <span className="text-red-700 text-3xl font-bold">*</span>
                  </label>

                  <div
                    //!input password relative
                    className="relative-hide relative h-auto w-full"
                  >
                    <input
                      id="password_confirmation"
                      className="signin-inputs pl-4   w-full"
                      type={`${hide ? "text" : "password"}`}
                      placeholder="Confirm the password"
                      name="password_confirmation"
                      autoComplete="on"
                      {...register("password_confirmation", {
                        required: true,
                        maxLength: 25,
                        minLength: 6,
                        validate: (value) => value === getValues("password"),
                      })}
                    />

                    <div
                      //!eye icons
                      onClick={() => setHide((hide) => !hide)}
                      className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                    >
                      {hide ? (
                        <FontAwesomeIcon
                          className="h-full w-full"
                          icon={faEye}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="h-full w-full"
                          icon={faEyeSlash}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {errors.password_confirmation && (
                  <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                    {errors.password_confirmation.type === "required" &&
                      "Please fill out this field"}
                    {errors.password_confirmation.type === "maxLength" &&
                      "The maximum number of characters is 25 characters"}
                    {errors.password_confirmation.type === "minLength" &&
                      "The minimum number of characters is 6 characters"}
                    {errors.password_confirmation.type === "validate" &&
                      "Passwords do not match"}
                  </p>
                )}
              </div>
            </>
          )}

          <button
            disabled={!isValid || ForgetPasswordLoading}
            className=" submit max-w-60 mt-10 "
            type="submit"
          >
            {ForgetPasswordLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Send"
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default ForgetPassword;

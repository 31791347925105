import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../../../../MainComponents";

export default function SubscribedVideos() {
  const { studentID, CourseNumber } = useParams();
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [successChangeView, errorChangeView, loadingChangeView] = useAxios(
    `${process.env.REACT_APP_STUDENTS_VIEWGROUP_VIDEOS_UPDATE_API}`,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );

  const [getSubsriber, errorSubsriber, loadingSubsriber] = useAxios(
    `${process.env.REACT_APP_STUDENTS_VIEWGROUP_VIDEOS_API}/${studentID}`,
    "GET",
    "GET",
    ""
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    const arrayOfVideos = getSubsriber.data.videos.map((video) => ({
      id: video.id,
      views: data[`views-${video.id}`], // Use a unique key for each input
    }));

    const finalData = {
      videos: arrayOfVideos,
    };

    setSubmitAdd({
      flag: "Add Assignment",
      dependency: !submitAdd.dependency,
      data: finalData,
    });
  };

  if (loadingSubsriber) return <Loader />;
  if (!getSubsriber)
    return (
      <div className="w-full h-screen flex justify-center items-center text-7xl">
        No Subscribed Videos
      </div>
    );
  if (getSubsriber) {
    return (
      <main className="py-20 px-10 flex flex-col items-center    w-full h-screen  gap-10 ">
        <div className="flex items-center  justify-center w-full">
          {!loadingSubsriber && (
            <div className="flex gap-10 flex-col ">
              <h1 className="text-4xl font-semibold text-start flex items-start">
                Student Name: {getSubsriber?.data?.student_name}
              </h1>
            </div>
          )}
        </div>
        {getSubsriber?.data?.videos?.length > 0 && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col justify-center items-start bg-white p-16  rounded-3xl shadow-3xl w-3/4 my-6 gap-10"
          >
            {getSubsriber.data.videos.map((video) => (
              <div key={video.id} className="w-full">
                <div className="relative flex flex-col justify-center items-start gap-2 ">
                  <label>{video.video_name}</label>
                  <input
                    defaultValue={video.views}
                    type="number"
                    autoComplete="on"
                    {...register(`views-${video.id}`)} // Register input with a unique name
                    className="signin-inputs w-full"
                  />
                </div>
              </div>
            ))}
            {
              <button
                disable={!isValid || loadingChangeView}
                type="submit" // Fix the typo here from "sumbit" to "submit"
                className="submit w-full"
              >
                Edit
              </button>
            }
          </form>
        )}
      </main>
    );
  }
}

import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AddEditVideo({ edit, type }) {
  const [values, setValues] = useState(null);
  const { VideoID, lectureID, ID } = useParams();
  const navigate = useNavigate();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditVideo": {
        return {
          ...state,
          submitAddEditVideo: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditVideo: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_SKILLS_VIDEOS_UPDATE_API
    : type === "assignments"
      ? process.env.REACT_APP_ADMIN_ADD_VIEDO_ASSIGMNETS_API
      : process.env.REACT_APP_SKILLS_VIDEOS_ADD_API;

  //!--------- add edit Video -------

  const [VideoAddEditSuccess, VideoAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditVideo.flag,
    state.submitAddEditVideo.dependency,
    state.submitAddEditVideo.data,
    true
  );

  //!--------- get the Video info for editing -------

  const [VideoInfo, VideoInfoErrors] = useAxios(
    `${process.env.REACT_APP_SKILLS_VIDEOS_EDIT_GET_API}/${ID}`,
    "GET",
    "GET",
    ""
  );
  useEffect(() => {
    if (VideoInfo) {
      setValues({
        ...VideoInfo.data,
        /*      is_public: VideoInfo.videos.is_public === 1 ? true : false, */
      });
    }
  }, [VideoInfo]);

  useEffect(() => {
    //!---add actions ----

    if (VideoAddEditSuccess) {
      navigate(-1);
      reset();
    }
  }, [VideoAddEditSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  const onSubmit = (data) => {
    const finalData = lectureID
      ? {
          ...data,
          lecture_id: lectureID,
          is_public: data?.is_public === true ? 1 : 0,
        }
      : VideoID
        ? {
            ...data,
            id: VideoID,
            is_public: data?.is_public === true ? 1 : 0,
          }
        : {
            ...data,
            id: ID,
          };
    dispatch({
      type: "setSubmitAddEditVideo",
      payload: {
        flag: "AddEditVideo",
        dependency: !state.submitAddEditVideo.dependency,
        data: finalData,
      },
    });
  };

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Videos | Mr.Ahmed Sabry"></HelmetTags>
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          {edit
            ? "Edit video data"
            : "Please fill in the information to add the video"}
        </h2>

        <div className="w-full flex flex-col justify-center gap-10">
          {/* Add New Video */}
          <div className="h-auto width flex flex-col items-center mt-6">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" flex flex-col justify-center items-start bg-white p-16  rounded-3xl shadow-3xl w-3/4 my-6 gap-6"
            >
              <h4 className="text-3xl font-bold text-center w-full">
                {edit ? "Edit" : "Add"} New Video
              </h4>
              <div className="w-full">
                <div className="relative flex flex-col justify-center items-start gap-2 ">
                  <label>Embed</label>
                  <textarea
                    className="signin-inputs w-full py-3"
                    {...register("embed", { required: true })}
                  ></textarea>
                  {errors.embed && (
                    <p className="text-red-500 text-xl pt-4">
                      {errors.embed.type === "required" &&
                        "This field is required."}
                    </p>
                  )}
                </div>
              </div>
              <div className="w-full">
                <div className="relative flex flex-col justify-center items-start gap-2 ">
                  <label>Platform</label>
                  <select
                    className="signin-inputs w-full"
                    {...register("platform", { required: true })}
                  >
                    <option disabled>Please Select The Platform</option>
                    <option value={0}>Vdochiper</option>
                    <option value={1}>Youtube</option>
                  </select>
                  {errors.platform && (
                    <p className="text-red-500 text-xl pt-4">
                      {errors.platform.type === "required" &&
                        "This field is required."}
                    </p>
                  )}
                </div>
              </div>
              <div className="w-full">
                <div className="relative flex flex-col justify-center items-start gap-2 ">
                  <label>Count view after (minutes)</label>
                  <input
                    placeholder="000"
                    min={0}
                    className="signin-inputs w-full"
                    type="number"
                    {...register("countview", { required: true })}
                  />
                  {errors.countview && (
                    <p className="text-red-500 text-xl pt-4">
                      {errors.countview.type === "required" &&
                        "This field is required."}
                    </p>
                  )}
                </div>
              </div>
              <div className="w-full">
                <div className="flex flex-col gap-4 pt-5 w-full">
                  <label>Views</label>
                  <input
                    min={0}
                    placeholder="000"
                    className="signin-inputs w-full"
                    type="number"
                    {...register("views", { required: true })}
                  />
                  {errors.views && (
                    <p className="text-red-500 text-xl pt-4">
                      {errors.views.type === "required" &&
                        "This field is required."}
                    </p>
                  )}
                </div>
              </div>

              <button className=" submit max-w-60 mt-10 " type="submit">
                {edit ? "Edit" : "Add"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AddEditVideo;

import React from "react";
import { useParams } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { FaFilePdf } from "react-icons/fa6";

export default function AttachmentsPDF() {
  const { assignmentID, skillID } = useParams();
  const [PdfInfo, PdfError, PdfLoading] = useAxios(
    `https://api.bayoumymath.com/api/pdf/${
      assignmentID ? "assignment" : "skill"
    }/${assignmentID || skillID}`,
    "GET",
    "GET",
    ""
  );
  return (
    <section className="w-full flex flex-wrap justify-center items-center gap-10 min-h-screen">
      {PdfInfo?.data?.map((item, index) => (
        <div key={index} className="form shadow-3xl">
          <p className="text-4xl text-black">{item.name}</p>

          <a
            href={item.address}
            download
            target="_blank"
            className=" px-4 py-3 text-white rounded-3xl bg-blue-500 font-semibold flex w-full justify-center items gap-5 hover:bg-blue-700 duration-200"
          >
            Open
          </a>
        </div>
      ))}
    </section>
  );
}
